<template>
    <v-text-field
        label="Email"
        type="email"
        name="username"
        autocomplete="username"
        outlined
        dark
        prepend-inner-icon="mdi-email-outline"
        v-model="email"
        @input="emitEmail"
        @keyup.enter="emitNext"
        :error-messages="errors"
        required
        autofocus
    ></v-text-field>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'EmailField',
    props: {
        initial_email: String,
        errors: Array
    },

    components: {},

    methods: {
        emitEmail() {
            this.$emit('emailUpdate', this.email);
        },

        emitNext() {
            this.$emit('toPassword');
        }
    },

    data: function () {
        return {
            email: this.initial_email
        };
    }
});
</script>

<style></style>
