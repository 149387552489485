<template>
    <div
        class="d-flex justify-center"
        style="background: rgb(21, 22, 25); color: white"
    >
        <div
            style="
                width: 70%;
                align-self: center;
                padding-top: 20%;
                padding-bottom: 10px;
            "
            :style="$vuetify.breakpoint.mobile ? { width: '100%' } : {}"
        >
            <div style="font-size: 35px; padding-bottom: 4%">Sign Up</div>
            <v-form
                ref="signupForm"
                :value="formValid"
                @submit.prevent="checkAndSubmit"
                lazy-validation
            >
                <v-text-field
                    v-model="signupEmail"
                    label="Email"
                    readonly
                    disabled
                    outlined
                    dark
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="
                        [rules.required, rules.email]
                    "
                    @focus="hadFocus['email'] = true"
                    @blur="triggerValidation"
                ></v-text-field>
                <v-text-field
                    v-model="signupName"
                    label="Full Name *"
                    ref="nameField"
                    name="fullname"
                    id="fullname"
                    type="text"
                    outlined
                    dark
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="[rules.required]"
                    autofocus
                    @focus="hadFocus['name'] = true"
                    @blur="triggerValidation"
                ></v-text-field>
                <v-text-field
                    v-model="signupPassword"
                    label="Password *"
                    outlined
                    dark
                    validate-on-blur
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="
                              [
                                  rules.required,
                                  rules.min,
                                  rules.max,
                                  rules.pwdMatch
                              ]
                    "
                    :type="showPwd ? 'text' : 'password'"
                    @focus="hadFocus['pwd'] = true"
                    @blur="triggerValidation"
                >
                    <template #append>
                        <v-icon
                            tabindex="-1"
                            icon
                            @click="showPwd = !showPwd"
                            >{{ showPwd ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
                        >
                    </template>
                </v-text-field>
                <v-text-field
                    v-model="confirmPassword"
                    label="Confirm Password *"
                    outlined
                    dark
                    validate-on-blur
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="
                              [
                                  rules.required,
                                  rules.min,
                                  rules.max,
                                  rules.pwdMatch
                              ]
                    "
                    :type="showPwd ? 'text' : 'password'"
                    @focus="hadFocus['conf'] = true"
                    @blur="triggerValidation"
                >
                    <template #append>
                        <v-icon
                            tabindex="-1"
                            icon
                            @click="showPwd = !showPwd"
                            >{{ showPwd ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
                        >
                    </template>
                </v-text-field>
                <v-row>
                    <v-col class="pb-0">
                        <v-text-field
                            v-model="signupCompany"
                            label="Company"
                            outlined
                            dark
                            :dense="$vuetify.breakpoint.mobile"
                        ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                        <v-select
                            v-model="signupRole"
                            :items="roleItems"
                            label="Role"
                            outlined
                            :dense="$vuetify.breakpoint.mobile"
                            :menu-props="{ contentClass: 'roleSelect' }"
                            dark
                        >
                            <template #item="{ item, on, attrs }">
                                <v-list-item
                                    v-bind="attrs"
                                    dark
                                    @click="handleRoleClick(item)"
                                    v-on="on"
                                >
                                    <v-list-item-content class="roleSelect">
                                        <v-list-item-title>
                                            {{ item }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pt-0" cols="12">
                        <v-text-field
                        v-if="signupRole === 'Carrier'"
                        v-model="mcNumber"
                        label="Number *"
                        type="number"
                        outlined
                        dark
                        :rules="[rules.carrierSixDigits, rules.carrierRequired]"
                        @focus="triggerValidation"
                        @blur="triggerValidation"
                        @input="triggerValidation"
                        >
                        <template v-slot:prepend-inner>
                            <span style="padding-top:3px;">MC-</span>
                        </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-btn
                    color="#348EFF"
                    dark
                    type="submit"
                    :disabled="!formValid"
                    class="text-capitalize"
                    style="width: 100%"
                >
                    <div>Sign Up</div>
                </v-btn>
            </v-form>
        </div>
        <v-dialog v-model="showConfirm" width="28rem">
            <v-card dark>
                <v-card-title class="text-h5 grey darken-2"
                    >Account Created
                </v-card-title>
                <v-card-text class="text-s1 pt-2 pb-2">
                    Enter the six digit code sent to {{ this.signupEmail }}. It
                    may be in your spam folder.
                </v-card-text>
                <v-card-actions style="flex-direction: column">
                    <div>
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit1"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit2"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit3"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit4"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit5"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            class="confirmDigit"
                            v-model="digit6"
                            maxlength="1"
                        />
                    </div>
                    <div v-if="confirmError" style="color: red">
                        {{ confirmError }}
                    </div>
                    <v-btn
                        @click="confirmUser"
                        color="#1245A8"
                        style="
                            align-self: flex-end;
                            margin-top: 1rem;
                            margin-bottom: 0.4rem;
                        "
                    >
                        Verify
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" width="28rem">
            <v-card dark :color="dialogColor">
                <v-card-title class="headline pb-0"
                    >{{ dialogTitle }}
                </v-card-title>
                <v-card-text class="text-s1 pt-2 pb-2">
                    {{ dialogMessage }}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import * as auth from '../authentication/amplify-auth';
export default {
    name: 'SignUp',
    props: ['email'],

    components: {},

    methods: {
        triggerValidation() {
            this.formValid = this.$refs.signupForm.validate();
        },

        checkAndSubmit() {
            this.formValid = this.$refs.signupForm.validate();
            if (this.formValid) {
                this.submitSignupForm();
            }
        },

        async submitSignupForm() {
            this.$emit('showLoading', true);
            let formFields = {
                username: this.signupEmail.trim(), // Required - the username to sign up
                password: this.signupPassword, // Required - the user's password
                attributes: {
                    name: this.signupName.trim(),
                    email: this.signupEmail.trim(),
                    ...(this.signupCompany && {
                        'custom:company': this.signupCompany.trim()
                    }),
                    ...(this.signupRole && {
                        'custom:customer_type': this.signupRole,
                        'custom:customer_type_int': this.roleItems
                            .indexOf(this.signupRole)
                            .toString()
                    }),
                    ...(this.mcNumber !== null && {
                        'custom:mc_number': this.mcNumber
                    })
                }
            };
            try {
                await auth.signUp(formFields);
                this.$emit('showLoading', false);
                this.showConfirm = true;
            } catch (error) {
                this.$emit('showLoading', false);
                if (error.code === 'UsernameExistsException') {
                    try {
                        await auth.resendSignUp(this.signupEmail.trim());
                        this.showConfirm = true;
                    } catch (error) {
                        if (
                            error.code === 'InvalidParameterException' &&
                            error.message.includes('User is already confirmed.')
                        ) {
                            this.showDialog(
                                'Your account is already confirmed, please log in',
                                'Notice',
                                'indigo darken-2'
                            );
                        } else {
                            this.showDialog(
                                `Failed to confirm email. Please contact us at ${this.contactEmail}`,
                                'Error',
                                'red darken-3'
                            );
                        }
                    }
                } else if (
                    error.code === 'UserLambdaValidationException' &&
                    error.message ===
                        'PreSignUp failed with error Email is not on the waitlist.'
                ) {
                    this.showDialog(
                        `Your email could not be validated. Please contact us at ${this.contactEmail}`,
                        'Error',
                        'red darken-3'
                    );
                } else {
                    this.showDialog(
                        `Signin failed. Please contact us at ${this.contactEmail}`,
                        'Error',
                        'red darken-3'
                    );
                    console.log('SignIn failed or error occurred.', error);
                }
            }
        },

        async confirmUser() {
            try {
                await auth.confirmSignUp(
                    this.signupEmail,
                    this.confirmationCode
                );
                let user = null;
                try {
                    user = await auth.signIn(
                        this.signupEmail,
                        this.signupPassword
                    );
                    this.navigate(user);
                } catch (error) {
                    console.log(
                        'An error occurred during automatic sign in.',
                        error
                    );
                }
                if (user) {
                    this.$router.push({
                        name: 'chat',
                        user_info: user
                    });
                } else {
                    console.log('SignIn failed or error occurred.');
                }
            } catch (error) {
                if (error.code === 'CodeMismatchException') {
                    this.confirmError =
                        'Confirmation code does not match, please confirm and try again';
                } else {
                    this.showDialog(
                        `There was an error confirming your account. Please contact us at ${this.contactEmail}`,
                        'Error',
                        'red darken-3'
                    );
                }
            }
        },

        showDialog(message, title = 'Notice', color = 'white') {
            this.dialogMessage = message;
            this.dialogTitle = title;
            this.dialogColor = color;
            this.dialog = true;
        },

        fillConfirmationDigits(pasteString) {
            const toFill = pasteString.trim().slice(0, 6);
            this.digit1 = toFill[0];
            this.digit2 = toFill[1];
            this.digit3 = toFill[2];
            this.digit4 = toFill[3];
            this.digit5 = toFill[4];
            this.digit6 = toFill[5];
        },

        handleRoleClick(item) {
            if (item === this.signupRole) {this.signupRole = ''}
            else {
                this.signupRole = item
            }

            if (item !== "Carrier") {
                this.mcNumber = null;
            }
            this.triggerValidation();
        }
    },

    computed: {
        confirmationCode() {
            return (
                this.digit1 +
                this.digit2 +
                this.digit3 +
                this.digit4 +
                this.digit5 +
                this.digit6
            );
        }
    },

    created() {},

    mounted() {
        if ('email' in this.$store.state) {
            this.signupEmail = this.$store.state['email'];
        }
    },

    watch: {
        showConfirm(val) {
            if (val === true) {
                this.$nextTick(() => {
                    document
                        .querySelectorAll('.confirmDigit')
                        .forEach((input, index, array) => {
                            input.addEventListener('keyup', () => {
                                if (index < array.length - 1 && input.value) {
                                    array[index + 1].focus();
                                }
                                this.confirmError = '';
                            });
                            input.addEventListener('paste', (event) => {
                                const pasteData =
                                    event.clipboardData.getData('text');
                                this.fillConfirmationDigits(pasteData);
                            });
                        });
                });
            }
        }
    },

    data: function () {
        return {
            // Signup form fields
            formValid: false,
            signupName: '',
            signupEmail: '',
            signupPassword: '',
            confirmPassword: '',
            signupCompany: '',
            signupRole: '',
            roleItems: ['Shipper', 'Broker', 'Carrier', 'Other'],
            showPwd: false,
            hadFocus: {
                name: false,
                email: false,
                pwd: false,
                conf: false
            },
            rules: {
                required: (v) => {
                    return !!v || 'This field cannot be empty';
                },
                min: (v) => v.length >= 8 || 'Min 8 characters',
                max: (v) => v.length <= 20 || 'Max 20 characters',
                pwdMatch: () =>
                    this.confirmPassword === '' ||
                    this.signupPassword === this.confirmPassword ||
                    `The passwords you entered don't match`,
                email: (v) => {
                    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                    if (regex.test(v)) {
                        return true;
                    } else {
                        return 'E-mail is not valid';
                    }
                },
                sixDigits: (v) => (v !== null && v !== undefined && v.toString().length === 6) || 'Must be 6 characters long',
                carrierSixDigits: (v) => {
                if (this.signupRole === 'Carrier') {
                    return (v !== null && v !== undefined && v.toString().length === 6) || 'Must be 6 characters long';
                }
                return true; // if role is not 'Carrier', validation passes
                },
                carrierRequired: (v) => {
                if (this.signupRole === 'Carrier') {
                    return !!v || 'This field is required for Carriers';
                }
                return true; // if role is not 'Carrier', validation passes
                }
            },

            showConfirm: false,
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            confirmError: '',
            contactEmail: 'contact@truce.io',

            dialog: false,
            dialogMessage: '',
            dialogTitle: '',
            dialogColor: 'white',
            mcNumber: null
        };
    }
};
</script>
<style>
.roleSelect .v-list {
    background: rgb(32, 31, 31) !important;
}
.roleSelect .v-list-item__title {
    color: rgb(240, 240, 240) !important;
}
.confirmDigit {
    width: 40px;
    height: 40px;
    height: 3rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    text-align: center;
    margin: 5px;
    font-size: 1.2rem;
    font-weight: 300;
    border: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 0.4rem;
    color: white;
    caret-color: white;
}
@media (max-width: 450px) {
    .confirmDigit {
        width: 2rem;
        height: 2rem;
    }
}
</style>
