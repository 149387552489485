import polyline from '@mapbox/polyline';

// Formats lat lng into google polygon and computes center.
export function formatGooglePolygonArray(latlngArray, google) {
    let centerLat = 0;
    let centerLng = 0;
    const len = latlngArray.length;
    var formattedArray = [];
    latlngArray.forEach((point) => {
        centerLat += point[0];
        centerLng += point[1];
        formattedArray.push(new google.maps.LatLng(point[0], point[1]));
    });

    return [
        formattedArray,
        new google.maps.LatLng(centerLat / len, centerLng / len)
    ];
}

// Maps list of lat lngs to dictionary.
export function formatLatLngArray(latlngArray) {
    var mappedlatlngarray = latlngArray.map(([lat, lng]) => ({
        lat: lat,
        lng: lng
    }));
    return mappedlatlngarray;
}

// Decodes polyline
export function decodePolyline(encodedPolyline) {
    // Decode the polyline
    const decodedPolyline = polyline.decode(encodedPolyline);
    return formatLatLngArray(decodedPolyline);
}

// Computes global northeast and southwest bounds off a list of coordinates.
export function compute_bounds_from_list(coordinates) {
    let lats = coordinates.map((coord) => coord[0]);
    let lons = coordinates.map((coord) => coord[1]);
    let minLat = Math.min(...lats);
    let maxLat = Math.max(...lats);
    let minLon = Math.min(...lons);
    let maxLon = Math.max(...lons);

    return { sw: [minLat, minLon], ne: [maxLat, maxLon] };
}

// Gets coordinates and polylines from list of events.
export function coordinates_and_polylines_from_events(events) {
    let coordinates = [];
    let polylines = [];
    let start_city = [];
    let end_city = [];
    let distanceArray = [];
    let displayInfo = [];
    var northeast, southwest;
    for (let event of events) {
        northeast = event.data.bounds.northeast;
        southwest = event.data.bounds.southwest;
        coordinates.push([northeast['lat'], northeast['lng']]);
        coordinates.push([southwest['lat'], southwest['lng']]);
        polylines.push(event.data.polyline);
        start_city.push(event.data.origin_city.split(',')[0]);
        end_city.push(event.data.destination_city.split(',')[0]);
        distanceArray.push(event.data.total_distance);
        displayInfo.push({
            total_distance: event.data.total_distance,
            all_in_rate: event.data.all_in_rate,
            equipment_type: event.data.equipment_type,
            fuel_cost: event.data.fuel_cost,
            origin_city: event.data.origin_city,
            destination_city: event.data.destination_city,
            historical_price_data: event.data.batch_price_historical_data
        });
    }

    return [
        coordinates,
        polylines,
        start_city,
        end_city,
        distanceArray,
        displayInfo
    ];
}

export function getAdjustedPoints(google, global_bounds, adjustPercentage) {
    // Original bounds
    const originalNE = new google.maps.LatLng(
        global_bounds.ne[0],
        global_bounds.ne[1]
    );
    const originalSW = new google.maps.LatLng(
        global_bounds.sw[0],
        global_bounds.sw[1]
    );

    // Calculate differences
    const latDiff = originalNE.lat() - originalSW.lat();
    const lngDiff = originalNE.lng() - originalSW.lng();

    // Calculate adjustments
    const adjustLat = latDiff * adjustPercentage;
    const adjustLng = lngDiff * adjustPercentage;

    // Adjust bounds inward
    const adjustedNE = new google.maps.LatLng(
        originalNE.lat() - adjustLat,
        originalNE.lng() - adjustLng
    );
    const adjustedSW = new google.maps.LatLng(
        originalSW.lat() + adjustLat,
        originalSW.lng() + adjustLng
    );

    // Return the adjusted NE and SW points separately
    return {
        adjustedNE: adjustedNE,
        adjustedSW: adjustedSW
    };
}
