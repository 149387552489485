<template>
    <section class="px-10">
        <h2 class="landing-sub-header">Frequently Asked Questions</h2>
        <div
            v-for="item in content"
            :key="item.id"
            style="padding-bottom: 1rem">
            <div class="landing-bold-text">{{ item.question }}</div>
            <div class="landing-light-text">{{ item.answer }}</div>
        </div>
    </section>
</template>

<script>
import '../containers/landing/landing-page-style.css';

export default {
    name: 'FAQ',
    props: {
        content: {
            // This array should contain objects with keys 'question' and 'answer'
            type: Array,
            required: true
        }
    }
};
</script>

<style scoped></style>
