<template>
    <v-app class="app">
        <AppBar :show_login_button="false"></AppBar>
        <v-main class="white--text">
            <v-container fluid class="page-container">
                <h1 class="text-h2 text-center pt-10 pb-10">
                    What is a DOT number and how do I look up a DOT number?
                </h1>
                <h2 class="text-h3 pt-10 pb-6">What is a DOT number?</h2>
                <p class="pb-4">
                    A USDOT (or DOT) number is a unique identifier assigned by
                    the Federal Motor Carrier Safety Administration (FMCSA).
                    Registering with the FMCSA will allow you to acquire a free
                    DOT number. The FMCSA uses this number to track and monitor
                    safety and compliance information for transportation
                    companies. They collect this information during:
                </p>
                <v-row>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Audits
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Detailed look into trucking company practices
                                and events to confirm compliance
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Compliance Reviews
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                General reviews to ensure business standards are
                                being met
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Crash Investigations
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Investigations for crashes will also result in
                                information being tied to a DOT number
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Inspections
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                General inspections to ensure trucking practices
                                are up to date
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <p class="py-4">
                    You are able to view this information by looking up the
                    number, but how is this done?
                </p>
                <h2 class="text-h3 pb-8">How do I look it up?</h2>

                <p>
                    To perform a USDOT number lookup for a particular
                    transportation company, you can use FreightGPT. FreightGPT
                    is an AI powered assistant that allows you to look up any
                    freight related question. A simple query to search a DOT
                    number, will return a variety of information about the
                    company.
                </p>
                <v-row justify="end" class="py-4 pr-8">
                    <div style="border: 2px solid #0366f9" class="rounded pa-4">
                        Look up dot number 54283
                    </div>
                </v-row>
                <v-row class="py-4 px-16">
                    <div style="border: 2px solid #ad00ff" class="rounded pa-4">
                        The DOT number 54283 belongs to Swift Transportation Co
                        of Arizona LLC. Here are some details about the company:
                        <br />
                        <b>Company Name:</b> Swift Transportation Co of Arizona
                        LLC <br />
                        <b>Email:</b>
                        marilyn_bellamy@swifttrans.com <br />
                        <b>Contact Name:</b> Julie Miller <br />
                        <b>Alternate Contact Name:</b> Adam Miller
                        <br />
                        <b>Phone Number:</b> 800-800-2200 <br />
                        <b>Located In:</b> Phoenix, <br /><b>AZ Rating:</b>
                        Satisfactory <br /><b>In Business For:</b> 49 years, 11
                        months, 25 days <br /><b> Total Trucks:</b> 13,875
                        <br /><b>Company Type:</b> Carrier, Broker <br /><b
                            >MCS Form Update Date:</b
                        >
                        November 14, 2023 <br /><b
                            >Types of Cargo Transported:</b
                        >
                        <ul>
                            <li>Metal: Sheets, Coils, Rolls</li>
                            <li>Motor Vehicles</li>
                            <li>Logs, Poles, Beams, Lumber</li>
                            <li>Building Materials</li>
                            <li>Machinery, Large Objects</li>
                            <li>Fresh Produce</li>
                            <li>Liquids/Gases</li>
                            <li>Intermodal Containers</li>
                            <li>Passengers</li>
                            <li>Grain, Feed, Hay</li>
                            <li>Meat</li>
                            <li>Chemicals</li>
                            <li>Commodities Dry Bulk</li>
                            <li>Refrigerated Food</li>
                            <li>Beverages</li>
                            <li>Paper Products</li>
                        </ul>
                        <b>Inspections in Last Two Years:</b> 16,251
                        <br />
                        <b>Crashes in Last Two Years:</b> 6
                    </div>
                </v-row>

                <p class="pt-8 pb-6">
                    You can also ask for specific details about the company like
                    what their motor carrier number is.
                </p>

                <v-row justify="end" class="py-4 pr-8">
                    <div style="border: 2px solid #0366f9" class="rounded pa-4">
                        Look up motor carrier number for dot number 1562978
                    </div>
                </v-row>
                <v-row class="py-4 px-16">
                    <div style="border: 2px solid #ad00ff" class="rounded pa-4">
                        DOT number 1562978 has a Motor Carrier (MC) number of
                        MC579500.
                    </div>
                </v-row>

                <p class="pt-8 pb-6">
                    Additionally, you can search other dot related questions
                    like exemption
                </p>
                <v-row justify="end" class="py-4 pr-8">
                    <div style="border: 2px solid #0366f9" class="rounded pa-4">
                        Who is exempt from a dot number?
                    </div>
                </v-row>
                <v-row class="py-4 px-16">
                    <div style="border: 2px solid #ad00ff" class="rounded pa-4">
                        Exemptions from a DOT number can vary based on the type
                        of operation and specific regulations. Here are some
                        common exemptions from certain DOT regulations:<br />
                        <ol>
                            <li>
                                <b>Intrastate Operations:</b>
                                Carriers that operate only within the state and
                                do not engage in interstate commerce. Only
                                certain states allow this.
                            </li>
                            <li>
                                <b>Small Vehicles:</b> Vehicles under a certain
                                weight limit or passenger capacity may be exempt
                                from certain DOT regulations.
                            </li>
                            <li>
                                <b>Agricultural Operations:</b>
                                Certain agricultural vehicles and operations may
                                be exempt from certain DOT regulations.
                            </li>
                            <li>
                                <b>Government Vehicles:</b> Vehicles operated by
                                government agencies for official purposes may be
                                exempt from certain DOT regulations.
                            </li>
                            <li>
                                <b>Personal Use Vehicles:</b>
                                Vehicles used for personal, non-commercial
                                purposes may be exempt from certain DOT
                                regulations.
                            </li>
                        </ol>
                    </div>
                </v-row>

                <h2 class="text-h3 py-8">Do I need a DOT number?</h2>

                <p class="pb-4">
                    If you are in the transportation industry and you transport
                    people or haul cargo, you might need to obtain a USDOT
                    number. This number is a federal regulation for interstate
                    (across state lines) transportation. More specifically,
                    FMCSA regulations state you will require a USDOT number if
                    any of the following apply:
                </p>

                <v-row>
                    <v-col col="12">
                        <v-card
                            class="transparent-card full-height rounded py-6 pl-3">
                            Transport hazardous materials, which require a
                            safety permit
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-card
                            class="transparent-card full-height rounded py-6 pl-3">
                            Operate a commercial vehicle of at least 10,001
                            pounds (4,536 kg)*
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-card
                            class="transparent-card full-height rounded py-6 pl-3">
                            Operate a commercial vehicle for transporting 8 or
                            more passengers (including the driver)
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col col="12">
                        <v-card
                            class="transparent-card full-height rounded py-6 pl-3">
                            Operate a non-commercial vehicle for transporting 15
                            or more passengers (including the driver)
                        </v-card>
                    </v-col>
                </v-row>

                <p class="py-4">
                    Some examples of businesses that require a DOT number
                    include moving companies, buses, and trucking companies.
                </p>

                <i>
                    *The maximum vehicle weight used is the greater of the gross
                    vehicle weight rating, gross combination weight rating,
                    gross vehicle weight, gross combination weight
                </i>

                <h3 class="text-h4 py-8">State Requirements</h3>
                <p class="pb-1">
                    In addition to federal regulation, the following states also
                    require USDOT numbers for intrastate (within state lines)
                    transport.
                </p>

                <v-row align="center" justify="center">
                    <vuevectormap
                        :key="mapKey"
                        map="us_lcc_en"
                        ref="map"
                        :width="mapWidth"
                        :height="mapHeight"
                        :showTooltip="false"
                        :regionsSelectable="false"
                        :zoomButtons="false"
                        :zoomOnScroll="false"
                        :series="series"
                        :regionStyle="regionStyle">
                    </vuevectormap>
                </v-row>
                <v-row class="py-3" align="center" justify="center">
                    <SignUpPanel :panelTitle="'Try it out for Free! Sign Up Today'" ></SignUpPanel>
                </v-row>
            </v-container>
        </v-main>
        <FooterBar></FooterBar>
    </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppBar from '../components/AppBar.vue';
import FooterBar from '../components/Footer.vue';
import * as analytics from '../analytics/pinpoint';
import SignUpPanel from '../components/SignUpPanel.vue';

/*
This handler is needed to suppress vuevectormap's incessant bitching when fed a showTooltip prop of false,
which functions properly but results in a stream of console errors when mousing over the map.
It's hardcoded to stop propagation of this event and this event only.
*/
window.onerror = (msg, url) => {
    if (
        msg ===
            "Uncaught TypeError: Cannot read properties of undefined (reading 'text')" &&
        url ===
            'webpack-internal:///./node_modules/jsvectormap/dist/js/jsvectormap.min.js'
    ) {
        // For some reason we need to pass true instead of false to stop error propagation here
        return true;
    }
};

export default defineComponent({
    name: 'DotNumber',
    metaInfo: {
        title: 'FreightGPT - What is a DOT number and how do I look it up?',
        link: [
            {
                rel: 'canonical',
                href: 'https://www.freightgpt.com/what-is-a-dot-number'
            }
        ],
        meta: window.location.href.includes('freightgpt.com')
            ? []
            : [{ name: 'robots', content: 'noindex' }]
    },
    components: {
        AppBar,
        FooterBar,
        SignUpPanel
    },
    beforeMount() {
        // Force scroll to top when page is loaded
        window.scrollTo(0, 0);
        analytics.sendEvent("dot_number_seo_page_visit");
        this.$store.commit('setStateProperty', {
                property: 'sourcePage',
                value: "seo_page_dot_number"
            });
    },
    mounted() {
        window.addEventListener('resize', this.getMapSize);
    },
    unmounted() {
        window.removeEventListener('resize', this.getMapSize);
    },
    methods: {
        getMapSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                case 'sm':
                    this.mapWidth = '365px';
                    this.mapHeight = '365px';
                    this.mapKey += 1;
                    break;
                case 'md':
                    this.mapWidth = '600px';
                    this.mapHeight = '600px';
                    this.mapKey += 1;
                    break;
                case 'lg':
                case 'xl':
                    this.mapWidth = '800px';
                    this.mapHeight = '800px';
                    this.mapKey += 1;
                    break;
                default:
                    this.mapWidth = '400px';
                    this.mapHeight = '400px';
                    this.mapKey += 1;
                    break;
            }
        }
    },
    data: () => {
        return {
            year: new Date().getFullYear(),
            mapKey: 1,
            mapWidth: '800px',
            mapHeight: '800px',
            regionStyle: {
                hover: { 'fill-opacity': 1 }
            },
            series: {
                regions: [
                    {
                        attribute: 'fill',
                        scale: {
                            selected: '#fc4949'
                        },
                        values: {
                            'US-AL': 'selected',
                            'US-AK': 'selected',
                            'US-AZ': 'selected',
                            'US-CA': 'selected',
                            'US-CO': 'selected',
                            'US-CT': 'selected',
                            'US-DE': 'selected',
                            'US-FL': 'selected',
                            'US-GA': 'selected',
                            'US-HI': 'selected',
                            'US-ID': 'selected',
                            'US-IN': 'selected',
                            'US-IA': 'selected',
                            'US-KS': 'selected',
                            'US-ME': 'selected',
                            'US-MD': 'selected',
                            'US-MA': 'selected',
                            'US-MI': 'selected',
                            'US-MN': 'selected',
                            'US-MO': 'selected',
                            'US-MT': 'selected',
                            'US-NJ': 'selected',
                            'US-NY': 'selected',
                            'US-NE': 'selected',
                            'US-NV': 'selected',
                            'US-NC': 'selected',
                            'US-OH': 'selected',
                            'US-OK': 'selected',
                            'US-OR': 'selected',
                            'US-PA': 'selected',
                            'US-PR': 'selected',
                            'US-SC': 'selected',
                            'US-TX': 'selected',
                            'US-UT': 'selected',
                            'US-WA': 'selected',
                            'US-WV': 'selected',
                            'US-WI': 'selected',
                            'US-WY': 'selected'
                        }
                    }
                ]
            }
        };
    }
});
</script>

<style scoped>
html,
body {
    overflow-y: auto !important;
    background: #07090e !important;
}

.app {
    background: #07090e !important;
    color: whitesmoke !important;
}

.appBar {
    background: rgb(48, 49, 52);
}

.transparent-card {
    background: rgba(255, 255, 255, 0.05) !important;
    color: white !important;
}

.full-height {
    height: 100%;
}

.page-container {
    height: 100%;
    max-width: 50%;
}

/* For mobile devices */
@media screen and (max-width: 500px) {
    .page-container {
        height: 100%;
        max-width: 90% !important;
    }
}
</style>
