<template>
  <div style="z-index: 999; max-height: 100%; position: relative;">
    <div class="freight-header">
      <img src="../../public/favicon.svg" class="custom-icon" />
      <span style="padding-left: 10px;">
        <div style="font-weight: 700;">FreightGPT</div>
      </span>
    </div>
    <v-container
      class="container"
      ref="container"
      v-if="!isMobile || (isMobile && showFourCards)"
    >
      <v-row class="d-flex align-stretch">
        <v-col
          v-for="(step, index) in steps"
          :key="step.label"
          cols="12"
          md="6"
          lg="3"
          class="d-flex"
          style="padding: 10px !important;"
        >
          <v-card
            class="query-section-card"
            @click="toggleExpand(index)"
            :ripple="false"
            elevation="0"
            ref="cards"
            :style="{ height: '100%',cursor:'pointer' }"
          >
            <v-card-title
              class="query-section-card-title"
              :style="titleStyle"
            >
              <div class="title-action-row">
                <v-icon
                  class="mr-3"
                  :color="step.icon_color"
                  style="font-size: 30px"
                >{{ step.icon }}</v-icon>
                <div :style="titleStyle" v-if="isMobile">{{ step.label }}</div>
                <v-spacer></v-spacer>
                <v-icon
                  color="grey"
                  :class="{ 'rotated': expanded === index }"
                >
                  {{ expanded === index ? 'mdi-close' : 'mdi-chevron-down' }}
                </v-icon>
              </div>
              <span v-if="!isMobile" :style="titleStyle">{{ step.label }}</span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-scroll-y-transition @after-leave="handleAfterLeave">
      <v-container
        v-if="expanded !== null"
        class="container"
        ref="container"
        style="display: block !important;"
      >
        <span v-for="(step, index) in steps" :key="step.label + index">
          <span v-if="expanded === index" style="width: 100%; display: block;">
            <div>
              <v-card
                class="query-section-card"
                @click="isMobile && toggleExpand(index)"
                :ripple="false"
                elevation="0"
                ref="cards"
                :style="{ height: 'fit-content' }"
              >
                <v-card-title
                  class="query-section-card-title"
                  :style="titleStyle"
                >
                  <div class="title-action-row">
                    <v-icon
                      class="mr-3"
                      :color="step.icon_color"
                      style="font-size: 30px"
                    >{{ step.icon }}</v-icon>
                    <div :style="titleStyle" v-if="isMobile">{{ step.label }}</div>
                    <v-spacer></v-spacer>
                    <v-icon
                      v-if="isMobile"
                      color="grey"
                      :class="{ 'rotated': expanded === index }"
                    >
                      {{ expanded === index ? 'mdi-close' : 'mdi-chevron-down' }}
                    </v-icon>
                  </div>
                  <span v-if="!isMobile" :style="titleStyle">{{ step.label }}</span>
                </v-card-title>
                <v-card-text
                  v-if="expanded === index"
                  class="query-section-card-text"
                >
                  <div class="extra-content">
                    <div class="mb-3" :style="textStyle">
                      {{ step.description }}
                    </div>
                    <v-row class="pb-3">
                      <v-col
                        class="pb-0"
                        cols="12"
                        v-for="(example, exIndex) in step.examples"
                        :key="exIndex"
                      >
                        <v-card
                          class="outlined-card hoverable-card"
                          elevation="0"
                          @click="emitExample(example)"
                        >
                          <v-card-text
                            class="pa-2 d-flex align-items-center"
                            :style="textStyle"
                          >
                            {{ example }}
                            <v-spacer />
                            <v-icon color="white">
                              mdi-arrow-right-thin
                            </v-icon>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </span>
        </span>
      </v-container>
    </v-scroll-y-transition>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { responsiveTextSize } from './formatUtils';

export default Vue.extend({
  name: 'NewConversationDiagram',
  computed: {
    isMobile() {
      if (window.innerWidth < 1080) {
          return true
        } else {
          return false
        }
    },
  },
  watch: {
    '$vuetify.breakpoint': {
      handler() {
        this.textStyle = this.responsiveTextSize(11, 12, 12, 14, 14);
        this.titleStyle = this.responsiveTextSize(14, 14, 16, 16, 18);
      },
      deep: true,
    },
  },
  mounted() {
    this.populateExamples();
  },
  data() {
    return {
      steps: [
        {
          label: 'Price Lanes',
          icon: 'mdi-currency-usd',
          icon_color: '#00E49F',
          description:
            "Get today's rate for a Dry Van or Reefer for any lane within the US.",
          examples: [] as string[],
        },
        {
          label: 'Find Carriers',
          icon: 'mdi-truck',
          icon_color: '#1DD3C0',
          description:
            'Discover carriers that meet your requirements, whether headquartered near a specific city or operating along a particular lane.',
          examples: [] as string[],
        },
        {
          label: 'Estimate Transit Time',
          icon: 'mdi-timer',
          icon_color: '#00D1FF',
          description:
            'Calculate the transit time for shipments while considering current traffic conditions and complying with FMCSA regulations.',
          examples: [] as string[],
        },
        {
          label: 'Prepare for Weather',
          icon: 'mdi-weather-partly-cloudy',
          icon_color: '#3181B6',
          description:
            'Access route-based weather forecasts timed to your estimated arrival at each point along the route.',
          examples: [] as string[],
        },
      ],
      examples: [
        [
          'What is the Dry Van rate from NYC to Chicago?',
          'What is the CPM of a Dry Van from Chicago, IL to St. Louis, MO?',
          'Atlanta to Chattanooga Dry Van rate with a 15% margin.',
          'Reefer rates Kenosha to Rockford, IL and NYC to Fort Wayne, IN.',
          'Dry Van rate for Huntley, IL to Huntington, IN',
          'Reefer rate for Rockford, IL to Joliet, IL',
          '90 day historical rates for Chicago to Miami.',
          '30 day historical rates for Chattanooga to Austin, TX.',
        ],
        [
          'Chicago to Dallas Dry Van carriers',
          'Find me LA to Phoenix Reefer carriers',
          'Find New York to Boston Reefer carriers with a max fleet size of 60',
          'Search for carriers near Chicago that haul meat with a max fleet size of 15.',
          'Find me carriers with Flatbeds that haul Nashville, TN to Detroit, MI.',
          'Dry Van carriers that haul from San Francisco, CA to Los Angeles, CA?',
          'Can I see inspection data for MC1047928?',
          'Can I see crash data for MC579500?',
          'Is phone number 412-747-8482 associated with a carrier?',
          'Is email info@ninikinc.com associated with a carrier?',
          'Tell me about MC579500.',
        ],
        [
          'Transit time from Denver to New York.',
          'Transit time from Dallas, TX to Miami, FL with 4 hours left of drive time.',
        ],
        [
          'What is weather en route from Grand Rapids, MI to Springfield, IL?',
          'Huntington, IN to Ann Arbor, MI weather en route.',
          'What is the weather in Fruitland, ID?',
        ],
      ],
      expanded: null as number | null,
      showFourCards: true,
      textStyle: {},
      titleStyle: {},
    };
  },
  methods: {
    toggleExpand(index: number) {
      if (this.expanded === index) {
        this.expanded = null;
      } else {
        if(this.expanded!=null){
          this.expanded = null
          setTimeout(()=>{
            this.expanded = index;
          },100)
        }else{
          this.expanded = index;
        }
      }
      this.showFourCards = false;
    },
    emitExample(example: string) {
      this.$emit('example-clicked', example);
    },
    populateExamples() {
      for (let i = 0; i < this.steps.length; i++) {
        const shuffled = this.examples[i].sort(() => 0.5 - Math.random());
        this.steps[i].examples = shuffled.slice(0, 2);
      }
    },
    handleAfterLeave() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showFourCards = true;
        }, 100);
      });
    },
    responsiveTextSize,
  },
});
</script>

<style scoped>
.freight-header {
  display: flex;
  font-size: 30px;
  padding-bottom: 3%;
  margin-top: 7%;
  margin-left: 5%;
}

.custom-icon {
  width: 50px;
  height: 50px;
}

.v-row {
  display: flex;
  align-items: stretch;
}

.v-col {
  display: flex;
}

.v-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 90%;
}

.query-section-card {
  border: 1px solid grey;
  border-radius: 8px;
  background-color: transparent;
  cursor: context-menu;
  user-select: none;
  height: fit-content;
  width: 100%;
  transition: all 0.3s ease;
}

.query-section-card-title {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  align-items: flex-start;
  font-size: 18px;
  font-weight: normal;
  text-wrap: balance;
  word-break: normal;
}

.v-card--link:focus,
.v-card--link:focus:before,
.v-card--link:focus:after,
.v-card--link:before,
.v-card--link:after {
  opacity: 1 !important;
  background-color: transparent !important;
}

.title-action-row {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
}

.expanded-card {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s ease;
}

.hidden-card {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.extra-content {
  padding-top: 10px;
}

.outlined-card {
  border: 0px solid #3181b6;
  background-color: transparent;
  border-radius: 8px;
  margin-left: 1px;
  justify-content: space-between !important;
}

.rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.hoverable-card {
  border: 1px solid transparent;
}

.hoverable-card:hover {
  border: 1px solid #3181b6;
  transition: border 0.3s ease;
}
@media screen and (max-width: 1200px) {
  .v-card__title{
    padding:10px !important
  }
  .freight-header{
    margin-top: 0px;
  }
}
@media screen and (max-width: 800px) {
  .query-section-card-title {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
  }
  .query-section-card-text {
    padding-bottom: 8px;
  }
  .query-section-card {
    width: 100%;
  }
  .container {
    flex-direction: column;
  }
}

@media screen and (max-width: 1080px) {
    .query-section-card-title {
        padding-top: 8px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .query-section-card-text {
        padding-bottom: 0;
    }
    .query-section-card {
      width: 100%;
    }
    .container {
      flex-direction: column;
    }
}
</style>
