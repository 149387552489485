<template>
    <v-app class="landing-app-class">
        <AppBar :show_login_button="false"></AppBar>
        <v-main>
            <v-container fluid class="page-container">
                <h1 class="landing-header">DOT Number Lookup | Find Carrier Details with DOT Number</h1>
                <DemoChat :page_name="$options.name" />

                <div class="landing-spacer hidden-sm-and-down" />

                <!-- Use Case Section -->
                <section
                    style="display: flex"
                    :style="
                        isMobile
                            ? { flexDirection: 'column', alignItems: 'center' }
                            : { flexDirection: 'row', paddingLeft: '2rem', paddingRight: '2rem'}
                    ">
                    <div
                        :style="
                            isMobile ? { width: '90%', marginTop: '40px' } : { width: '50%' }
                        ">
                        <h2 class="landing-sub-header">Verify carriers</h2>
                        <div
                            v-for="(item, index) in useCases"
                            :key="index"
                            style="padding-bottom: 1rem"
                            class="mr-4">
                            <div
                                class="landing-text"
                                style="display: flex; align-items: center">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 122 122"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="flex-shrink: 0">
                                    <path
                                        d="M75 0C61.7392 0 49.0215 5.85316 39.6447 16.2718C30.2678 26.6905 25 40.8213 25 55.5556C25 70.2898 30.2678 84.4206 39.6447 94.8393C49.0215 105.258 61.7392 111.111 75 111.111C88.2608 111.111 100.979 105.258 110.355 94.8393C119.732 84.4206 125 70.2898 125 55.5556H112.5C112.5 78.6111 95.75 97.2222 75 97.2222C65.0544 97.2222 55.5161 92.8324 48.4835 85.0183C41.4509 77.2043 37.5 66.6062 37.5 55.5556C37.5 44.5049 41.4509 33.9068 48.4835 26.0928C55.5161 18.2788 65.0544 13.8889 75 13.8889C77.6875 13.8889 80.375 14.2361 82.9375 14.8611L93 3.75C87.25 1.25 81.25 0 75 0ZM116.188 10.9722L75 56.7361L60.125 40.2083L51.3125 50.0694L75 76.3889L125 20.8333M18.3125 26.5278C6.75 37.0833 0 52.8472 0 69.4444C0 84.1787 5.26784 98.3095 14.6447 108.728C24.0215 119.147 36.7392 125 50 125C54 125 57.9375 124.444 61.75 123.403C50.75 120.694 40.625 114.583 32.3125 106.181C20.125 98.9583 12.5 84.7917 12.5 69.4444C12.5 67.3611 12.6875 65.3472 12.9375 63.2639C12.6875 60.6944 12.5 58.125 12.5 55.5556C12.5 45.5556 14.5 35.625 18.3125 26.5278Z"
                                        fill="#22C0DA" />
                                </svg>
                                <div style="margin-left: 1rem">
                                    {{ item.answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        "
                        :style="isMobile ? { width: '100%', justifyContent: 'center' } : { width: '50%', justifyContent: 'flex-end' }"
                    >
                        <SignUpPanel style="background-color: rgba(255, 255, 255, 0.05);" />
                    </div>
                </section>

                <div class="landing-spacer" />

                <AdRedirectPanels current_page="dot_lookup_ad" />

                <div class="landing-spacer" />

                <!-- FAQ Section -->
                <FAQ
                    :content="[
                        {
                            question: 'How do I look up a DOT Number?',
                            answer: 'Simply ask above for “DOT <your dot number>”'
                        },
                        {
                            question:
                                'How do I get detailed inspection and crash information?',
                            answer: 'Sign up for FreightGPT and ask “Inspection information for DOT <your dot number>”'
                        },
                        {
                            question:
                                'How much do I have to pay to create an account?',
                            answer: 'Nothing! FreightGPT Beta and all its capabilities are completely free. Create an account to get started.'
                        },
                        {
                            question:
                                'What other solutions does FreightGPT offer?',
                            answer: 'FreightGPT allows you to get multiple lane quotes, include margins, check real-time diesel costs, and estimate transit times, all through our AI chat interface. It also allows you to check weather along your route and find new carriers more optimized for carrying your loads.'
                        }
                    ]" />
                <div class="landing-spacer" />
                <div
                    style="width: 100%; display: flex; justify-content: center">
                    <SignUpPanel style="background-color: rgba(255, 255, 255, 0.05)" />
                </div>
            </v-container>
        </v-main>
        <FooterBar></FooterBar>
    </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppBar from '../../components/AppBar.vue';
import FooterBar from '../../components/Footer.vue';
import DemoChat from '../../components/DemoChat.vue';
import AdRedirectPanels from '../../components/AdRedirectPanels.vue';
import FAQ from '../../components/FAQ.vue';
import '../landing/landing-page-style.css';
import SignUpPanel from '../../components/SignUpPanel.vue';
import * as analytics from '../../analytics/pinpoint';

export default defineComponent({
    name: 'DOTLookupDemo',
    metaInfo: {
        title: 'DOT Number Lookup Tool to Find Carrier Details | FreightGPT',
        link: [
            {
                rel: 'canonical',
                href: 'https://www.freightgpt.com/about/dot-lookup'
            }
        ],
        meta: window.location.href.includes('freightgpt.com')
            ? [{ name: 'description', content: "Use FreightGPT's DOT number lookup tool to get detailed information about carriers. Find contact details, safety rating and insurance minimums for your freight, get detailed crash and inspection information to check backgrounds." }]
            : [{ name: 'robots', content: 'noindex' }, { name: 'description', content: "Use FreightGPT's DOT number lookup tool to get detailed information about carriers. Find contact details, safety rating and insurance minimums for your freight, get detailed crash and inspection information to check backgrounds." }]
    },
    computed: {
        isMobile(): any {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    components: {
        AppBar,
        FooterBar,
        DemoChat,
        AdRedirectPanels,
        FAQ,
        SignUpPanel
    },
     beforeMount() {
        // Force scroll to top when page is loaded
        window.scrollTo(0, 0);
        analytics.sendEvent("ad_page_dot_lookup_visit");
        this.$store.commit('setStateProperty', {
                property: 'sourcePage',
                value: "ad_page_dot_lookup"
            });
    },
    data: () => {
        return {
            useCases: [
                {
                    answer: 'Ensure carriers have the safety rating and insurance minimums for your freight'
                },
                {
                    answer: 'Get contact information for your carrier to book a load now'
                },
                {
                    answer: 'Get detailed crash and inspection information to check backgrounds'
                }
            ]
        };
    }
});
</script>

<style scoped>
html,
body {
    overflow-y: auto !important;
    background: #07090e !important;
}

.appBar {
    background: rgb(48, 49, 52);
}

.full-height {
    height: 100%;
}
</style>
