import Vue from 'vue';
import VueRouter from 'vue-router';
import VueTypedJs from 'vue-typed-js';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Landing from './containers/landing/landing-page.vue';
import ChatStream from './containers/ChatStream.vue';
import LoginIntermediary from './containers/LoginIntermediary.vue';
import DotNumber from './containers/dotNumber.vue';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { DEV_OAUTH_COGNITO_DOMAIN, PROD_OAUTH_COGNITO_DOMAIN } from './resources';
import * as VueGoogleMaps from 'vue2-google-maps';
export const PROD_URL = 'freightgpt.com';
export const DEV_URL = 'localhost';
export const TEST_URL = 'dev.freightgpt.com';
import store from './store';
import VueVectorMap from 'vuevectormap';
import './us-map';
import TruckingQuotes from './containers/TruckingQuotes.vue';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';
import CarrierSearchDemo from './containers/ad_pages/CarrierSearchDemo.vue';
import ReeferRatesDemo from './containers/ad_pages/ReeferRatesDemo.vue';
import DryVanRatesDemo from './containers/ad_pages/DryVanRatesDemo.vue';
import DOTLookupDemo from './containers/ad_pages/DOTLookupDemo.vue';
import MCLookupDemo from './containers/ad_pages/MCLookupDemo.vue';

Vue.use(VueVectorMap);
Vue.use(VueMeta);
Vue.use(VueTypedJs);
Vue.use(VueRouter);
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAPS_KEY,
        libraries: 'geometry,drawing,places'
    }
});
Vue.config.productionTip = false;

export function isProdEnv() {
    return window.location.href.includes(PROD_URL);
}

// TODO (P1): Implement with amplify instead of frontend override.
awsconfig.oauth.domain = isProdEnv() ? PROD_OAUTH_COGNITO_DOMAIN : DEV_OAUTH_COGNITO_DOMAIN;
Amplify.configure(awsconfig);

const failGuard = function (to: any, next: Function) {
    const nextParams = { path: '/' } as any;
    if (routes.filter((route) => route.path == to.path)) {
        nextParams.query = { redirect: to.fullPath };
    }
    next(nextParams);
};


function setSignUpProperties(user: any) {
    const email = user.attributes.email;
    const name = user.attributes.name;


    store.commit('setStateProperty', {
        property: 'email',
        value: email
    });

    store.commit('setStateProperty', {
        property: 'name',
        value: name
    });

    store.commit('setStateProperty', {
        property: 'googleSSO',
        value: true
    });
}


function legacyUserPriorSignIn(user: any) {
    // For legacy users who will have user attributes in local storage without priorSignIn
    if ('email_verified' in user.attributes
        && user.attributes.email_verified
        && !('custom:priorSignIn' in user.attributes)) {

        return true;
    }

    return false;
}

const guard = function (to: any, from: any, next: Function) {
    Auth.currentAuthenticatedUser()
        .then((user) => {
            if (!('custom:priorSignIn' in user.attributes) && !legacyUserPriorSignIn(user)) {
                setSignUpProperties(user);

                next({
                    path: 'signup',
                    replace: true,
                    query: { signUpInitialRedirection: "" }
                })
            } else {
                next();
            }
        })
        .catch((err) => {
            console.log("ERROR", err);
            failGuard(to, next);
        });
};

const decodeEmail = function (text: string) {
    const key = new Uint8Array([
        0x48, 0xb6, 0xd0, 0x04, 0x94, 0xfe, 0x0b, 0x0c, 0xc7, 0x86, 0x09, 0x8e,
        0xd5, 0x65, 0x2f, 0x8b, 0x1a, 0x49, 0x8a, 0xbc, 0xfa, 0x06, 0x65, 0xe9,
        0xd4, 0x39, 0x7b, 0x56, 0xca, 0x95, 0x26, 0xfd
    ]);
    const raw = Uint8Array.from(
        atob(text.replace(/-/g, '+').replace(/_/g, '/')),
        (c) => c.charCodeAt(0)
    );
    const iv = raw.slice(0, 16);
    const ciphertext = raw.slice(16);
    return window.crypto.subtle
        .importKey('raw', key, { name: 'AES-CBC' }, false, ['decrypt'])
        .then((importedKey) => {
            return window.crypto.subtle.decrypt(
                {
                    name: 'AES-CBC',
                    iv: iv
                },
                importedKey,
                ciphertext
            );
        })
        .then((decodedData) => {
            return new TextDecoder().decode(decodedData);
        });
};

interface AdditionalInfoRouteParams {
    signUpInitialRedirection: boolean;
}
const routes = [
    { path: '/', name: 'landing', component: Landing },
    { path: '/try', name: 'landing', component: Landing },
    { path: '/what-is-a-dot-number', name: 'dotnumber', component: DotNumber },

    // Routes for ad pages
    { path: '/about/reefer-rates', name: 'reefer_ad', component: ReeferRatesDemo },
    { path: '/about/carrier-search', name: 'carrier_search_ad', component: CarrierSearchDemo },
    { path: '/about/dry-van-rates', name: 'dry_van_ad', component: DryVanRatesDemo },
    { path: '/about/dot-lookup', name: 'dot_lookup_ad', component: DOTLookupDemo },
    { path: '/about/mc-lookup', name: 'mc_lookup_ad', component: MCLookupDemo },

    {
        path: '/freight-trucking-quotes',
        name: 'truckingquotes',
        component: TruckingQuotes
    },

    {
        name: 'chat',
        path: '/chat',
        component: ChatStream,
        props: true,
        beforeEnter: (to: any, from: any, next: Function) => {
            guard(to, from, next);
        }
    },
    {
        name: 'login',
        path: '/login',
        component: Landing,
        props: true,
        // beforeEnter: (to: any, from: any, next: Function) => {
        //     store.commit('setStateProperty', {
        //         property: 'loginWindowProperty',
        //         value: true
        //     });
        // }
    },
    {
        name: 'signup',
        path: '/signup',
        component: LoginIntermediary,
        props: true
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

Vue.use(VueGtag, {
    config: { id: 'AW-16494424913' }
}, router);

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App)
}).$mount('#app');
