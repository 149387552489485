<template>
    <div class="carrier-search-container" ref="rootElement">
        <v-expansion-panels dark multiple v-model="panelStates">
            <div class="header">
                <span
                    :style="{ 'font-size': isMobile ? '14px' : '16px' }"
                    style="font-size: 12px; padding-left: 12px"
                >
                    Search Results:
                    <i>{{ carrierData.length }} results found</i>
                </span>
                <div class="button-container" v-if="!isMobile" :class="isMobile ? 'mobile' : ''">
                    <v-btn
                        class="transparent-button"
                        style="color: white; border-color: #1dd3c0"
                        outlined
                        small
                        @click="toggleAllItems"
                    >
                        <span v-if="allExpanded">Hide All</span>
                        <span v-else>Expand All</span>
                    </v-btn>
                    <div style="position: relative">
                        <div
                            class="menu-tooltip download-all-tooltip"
                        >
                            <span>Download All</span>
                        </div>
                        <v-btn
                            class="transparent-button tooltip-activator"
                            style="border-color: #8cc2ff; color: #8cc2ff;"
                            outlined
                            small
                            @click="downloadAll"
                            @mouseenter="centerAndShowTooltip('download-all-tooltip')"
                            @mouseleave="hideTooltip('download-all-tooltip')"
                            ><v-icon small>mdi-download</v-icon></v-btn
                        >
                    </div>
                    <div style="position: relative">
                        <v-btn
                            class="transparent-button tooltip-activator"
                            outlined
                            small
                            style="border-color: #8cc2ff; fill: #8cc2ff;"
                            @click="copyAllEmails()"
                            @mouseenter="centerAndShowTooltip('copy-all-tooltip')"
                            @mouseleave="hideTooltip('copy-all-tooltip')"
                        >
                            <svg
                                viewBox="0 0 18 18"
                                version="1.1"
                                x="0px"
                                y="0px"
                                id="svg4"
                                sodipodi:docname="new.svg"
                                inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
                                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:svg="http://www.w3.org/2000/svg">
                                <defs
                                    id="defs4" />
                                <sodipodi:namedview
                                    id="namedview4"
                                    pagecolor="#ffffff"
                                    bordercolor="#000000"
                                    borderopacity="0.25"
                                    inkscape:showpageshadow="2"
                                    inkscape:pageopacity="0.0"
                                    inkscape:pagecheckerboard="0"
                                    inkscape:deskcolor="#d1d1d1"
                                    inkscape:zoom="38.409447"
                                    inkscape:cx="18.784441"
                                    inkscape:cy="11.364392"
                                    inkscape:window-width="1920"
                                    inkscape:window-height="1009"
                                    inkscape:window-x="-8"
                                    inkscape:window-y="-8"
                                    inkscape:window-maximized="1"
                                    inkscape:current-layer="svg4"
                                    showguides="true" />
                                <g
                                    transform="translate(0,-280.06665)"
                                    id="g3">
                                    <path
                                    style="fill:inherit;color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
                                    d="m 6.61484,289.59215 a 0.26460982,0.26460982 0 0 0 -0.26562,0.26356 v 5.82238 a 0.26460982,0.26460982 0 0 0 0.26562,0.26355 h 8.99635 a 0.26460982,0.26460982 0 0 0 0.26355,-0.26355 v -5.82238 a 0.26460982,0.26460982 0 0 0 -0.26355,-0.26356 z m 0.26407,0.52917 h 8.46666 v 0.64079 l -4.18372,2.41587 -4.28294,-2.41845 z m 0,1.24592 4.15427,2.34559 a 0.26460982,0.26460982 0 0 0 0.26354,-0.002 l 4.04885,-2.33785 v 4.03903 H 6.87891 Z"
                                    id="path1" />
                                    <path
                                    style="fill:inherit;color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
                                    d="m 1.85131,283.24215 c -0.43516,0 -0.79272,0.35808 -0.79272,0.79324 v 6.87865 c 0,0.43516 0.35756,0.7953 0.79272,0.7953 l 3.70416,-5.3e-4 c 0.14613,0 0.26459,-0.11846 0.26459,-0.26458 0,-0.14613 -0.11846,-0.26459 -0.26459,-0.26459 v 5.3e-4 l -3.70416,-5.3e-4 c -0.15116,0 -0.26355,-0.11446 -0.26355,-0.26561 v -6.87865 c 0,-0.15116 0.11239,-0.26355 0.26355,-0.26355 h 6.88071 c 0.15115,0 0.26407,0.11239 0.26407,0.26355 v 4.76301 a 0.26458337,0.26458337 0 0 0 0.26458,0.26459 0.26458337,0.26458337 0 0 0 0.26459,-0.26459 v -4.76301 c 2e-5,-0.43516 -0.35808,-0.79324 -0.79324,-0.79324 z"
                                    id="path2" />
                                    <path
                                    style="fill:inherit;color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.5291667;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:normal;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate"
                                    d="m 3.96849,281.12497 c -0.43516,0 -0.79272,0.35808 -0.79272,0.79324 v 0.53019 a 0.26458337,0.26458337 0 0 0 0.26459,0.26459 0.26458337,0.26458337 0 0 0 0.26458,-0.26459 v -0.53019 c 2e-5,-0.15116 0.1124,-0.26356 0.26355,-0.26356 h 6.87917 c 0.15116,0 0.26561,0.1124 0.26561,0.26356 v 6.88019 c 0,0.14613 0.11846,0.26459 0.26459,0.26459 0.14612,0 0.26458,-0.11846 0.26458,-0.26459 v -6.88019 c 10e-6,-0.43516 -0.35962,-0.79324 -0.79478,-0.79324 z"
                                    id="path3" />
                                </g>
                            </svg>
                        </v-btn>
                        <div
                            class="menu-tooltip copy-all-tooltip"
                        >
                            <span
                                v-if="!allCopied"
                                class="hover-tooltip">
                                Copy all emails
                            </span>
                            <span
                                v-else
                                class="copy-tooltip">
                                Copied to clipboard
                            </span>
                        </div>
                    </div>
                    <div style="position: relative">
                        <div
                            class="menu-tooltip filter-tooltip"
                        >
                            <span>Filters</span>
                        </div>
                        <v-menu
                            bottom
                            left
                            dark
                            offset-y
                            attach
                            style="z-index: 10"
                            v-model="filterMenu"
                            :close-on-content-click="false"
                        >
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    ref="filterButton"
                                    class="transparent-button tooltip-activator"
                                    :retain-focus-on-click="false"
                                    style="
                                        color: #bf7bd7 !important;
                                        border-color: #bf7bd7;
                                    "
                                    outlined
                                    small
                                    v-on="on"
                                    v-bind="attrs"
                                    @mouseenter="centerAndShowTooltip('filter-tooltip')"
                                    @mouseleave="hideTooltip('filter-tooltip')"
                                    ><v-icon small>mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            <v-card style="background-color: #161616" width="750px">
                                <v-card-title style="border-bottom: 0.5px solid #3181B6">
                                    <span> Carrier Search Filters </span>
                                    <v-spacer />
                                    <div @click="resetFilter" style="cursor: pointer; font-size: 14px; font-weight: 300">Reset</div>
                                </v-card-title>
                                <v-card-actions style="flex-direction: column;">
                                    <div class="filter-row">
                                        <v-autocomplete
                                            prepend-icon="mdi-truck-flatbed"
                                            class="filter-item"
                                            :menu-props="{
                                                bottom: true,
                                                offsetY: true,
                                            }"
                                            bottom
                                            offset-y
                                            style="max-width: 50%; padding-left: 0; margin-top: 8px"
                                            multiple
                                            label="Equipment and Cargo"
                                            v-model="filterData.cargo_or_equipment_hauled"
                                            :search-input.sync="searchInputCargo"
                                            @change="searchInputCargo = ''"
                                            :items="equipmentAndCargo"
                                        />
                                        <v-range-slider
                                            custom-slider
                                            prepend-icon="mdi-briefcase"
                                            style="margin-left: 1rem; max-width: 52%"
                                            min="0"
                                            max="60"
                                            :tick-labels="Array(61).fill('').map((_, index) => {
                                                if (index === 0) return '0';
                                                if (index === 60) return '60';
                                                return '';
                                            })"
                                            :ticks="false"
                                            v-model="inBusinessSlider"
                                        >
                                            <template #label>
                                                <div style="position: absolute; bottom: 1.2rem; width: 300px; font-size: 14px">
                                                    Years in Business
                                                </div>
                                                </template>
                                            <template #thumb-label="props">
                                                <div>{{ props.value }}</div>
                                            </template>
                                        </v-range-slider>
                                    </div>
                                    <div class="filter-row">
                                        <div style="display:flex; position: relative; width: 50%; margin-right: auto">
                                            <v-icon>mdi-shield-check</v-icon>
                                            <v-text-field
                                                style="padding-left: 0.6rem"
                                                label="Insurance Minimum"
                                                class="filter-item"
                                                prefix="$"
                                                @keydown="filterNumberInput($event)"
                                                @input="validateDollarInput($event, 'insurance_coverage_min')"
                                                @change="preventEmptyInput($event, 'insurance_coverage_min')"
                                                v-model="filterData.insurance_coverage_min"
                                            />
                                            <v-text-field
                                                style="padding-left: 0.6rem"
                                                label="Inspection Minimum"
                                                class="filter-item"
                                                @keydown="filterNumberInput($event)"
                                                @input="validateNumberInput($event, 'inspections_min')"
                                                @change="preventEmptyInput($event, 'inspections_min')"
                                                v-model="filterData.inspections_min"
                                            />
                                        </div>
                                        <div style="display: flex; justify-content: flex-end; width: 32%; margin-right: auto">
                                            <v-text-field
                                                prepend-icon="mdi-truck"
                                                class="filter-item"
                                                label="Fleet Size Min"
                                                type="number"
                                                style="width: 130%"
                                                @keydown="filterNumberInput($event)"
                                                @change="validateNumberInput($event, 'fleet_size_min')"
                                                v-model="filterData.fleet_size_min"
                                            />
                                            <v-text-field
                                                style="margin-left: 1rem;"
                                                class="filter-item"
                                                label="Fleet Size Max"
                                                type="number"
                                                @keydown="filterNumberInput($event)"
                                                @change="validateNumberInput($event, 'fleet_size_max')"
                                                v-model="filterData.fleet_size_max"
                                            />
                                        </div>
                                    </div>
                                    <div class="filter-row" style="margin-top: 0.8rem; margin-bottom: 0.6rem">
                                        <!-- <v-range-slider
                                            prepend-icon="mdi-truck"
                                            style="max-width: 42%"
                                            label="Fleet Size"
                                            :min="fleetMin"
                                            :max="fleetMax"
                                            v-model="fleetSizeSlider"
                                            @input="console.log(fleetSizeSlider)"
                                        >
                                            <template #thumb-label="props">
                                                <div>{{ linearToLog(props.value).toFixed() }}</div>
                                            </template>
                                        </v-range-slider> -->
                                        <div style="display: flex; justify-content: start; width: 80%; margin-right: auto">
                                            <v-icon>mdi-transit-connection-variant</v-icon>
                                            <div class="filter-group" style="width: 260px">
                                                <header class="filter-group-header">Origin</header>
                                                <v-text-field
                                                    style="max-width: 32%;"
                                                    class="filter-item"
                                                    label="City"
                                                    v-model="filterData.origin_city"
                                                />
                                                <v-autocomplete
                                                    style="max-width: 25%; margin-bottom: 4px;"
                                                    label="State"
                                                    class="filter-item hide-label"
                                                    :menu-props="{
                                                        bottom: true,
                                                        offsetY: true,
                                                        offsetOverflow: false,
                                                    }"
                                                    v-model="filterData.origin_state"
                                                    :search-input.sync="searchInputOrigState"
                                                    @change="searchInputOrigState = ''"
                                                    :items="stateCodes"
                                                />
                                                <v-text-field
                                                    style="max-width: 60px;"
                                                    label="Radius"
                                                    class="filter-item"
                                                    type="number"
                                                    @keydown="filterNumberInput($event)"
                                                    @change="validateNumberInput($event, 'near_origin_radius')"
                                                    v-model="filterData.near_origin_radius"
                                                />
                                            </div>
                                            <div class="filter-group" style="width: 200px">
                                                <header class="filter-group-header">Destination</header>
                                                <v-text-field
                                                    style="max-width: 42%;"
                                                    class="filter-item"
                                                    label="City"
                                                    v-model="filterData.destination_city"
                                                />
                                                <v-autocomplete
                                                    style="max-width: 30%; margin-bottom: 4px;"
                                                    label="State"
                                                    class="filter-item hide-label"
                                                    :menu-props="{
                                                        bottom: true,
                                                        offsetY: true,
                                                        offsetOverflow: false,
                                                    }"
                                                    v-model="filterData.destination_state"
                                                    :search-input.sync="searchInputDestState"
                                                    @change="searchInputDestState = ''"
                                                    :items="stateCodes"
                                                />
                                            </div>
                                        </div>
                                        <div class="outbound-toggle" @click.capture.stop.prevent="filterData.backhaul = !filterData.backhaul" style="margin-right: auto">
                                            Outbound
                                            <v-switch label="Backhaul" v-model="filterData.backhaul" />
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: space-between; width: 100%; padding-right: 1rem; padding-left: 1rem; padding-bottom: 0.8rem">
                                        <v-item-group
                                            multiple
                                            active-class="selected-item"
                                            v-model="filterData.safety_ratings"
                                        >
                                            <v-subheader style="padding-left: 2rem; height: min-content; margin-bottom: 0.2rem"> Safety Rating </v-subheader>
                                            <div style="display: flex; padding: 0.2rem 0.6rem 0.2rem 0;">
                                                <v-icon style="margin-right: 0.6rem">mdi-shield-alert</v-icon>
                                                <v-item class="filter-safety-item" v-slot="{ toggle }" value="S">
                                                    <div @click="toggle">Satisfactory</div>
                                                </v-item>
                                                <v-item class="filter-safety-item" v-slot="{ toggle }" value="C">
                                                    <div @click="toggle">Conditional</div>
                                                </v-item>
                                                <v-item class="filter-safety-item" v-slot="{ toggle }" value="U">
                                                    <div @click="toggle">Unsatisfactory</div>
                                                </v-item>
                                                <v-item class="filter-safety-item" v-slot="{ toggle }" value="N">
                                                    <div @click="toggle">No Rating</div>
                                                </v-item>
                                            </div>
                                        </v-item-group>
                                        <v-btn
                                            class="transparent-button"
                                            style="border-color: #3181B6; align-self: flex-end;"
                                            @click="applyFilter"
                                        >Apply</v-btn>
                                    </div>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </div>
                </div>
            </div>
            <div class="carrier-container show-scroll" style="overflow-x: hidden">
                <v-expansion-panel
                    v-for="(item, i) in pages[pageIndex]"
                    :ref="'panel'+ i.toString()"
                    :key="i.toString() + pageIndex.toString()"
                    :id="'panel' + i.toString()"
                    style="margin-top: 0"
                    @change="scrollPanelIntoView(i)"
                    :style="isMobile? {
                        'display': 'flex',
                        'flex-direction': 'column',
                        'max-width': '100%',
                    } : {}"
                >
                    <v-expansion-panel-header
                        class="panel-header"
                        style="padding-top: 0.6rem; padding-bottom: 0.6rem"
                    >
                        <div v-if="!isMobile" class="header-content">
                            <div style="margin-right: auto; display: flex; align-items: center">
                                <v-menu
                                    open-on-hover
                                    top
                                    offset-y
                                    nudge-top="6"
                                    style="
                                        z-index: 16;
                                        box-shadow: none;"
                                    content-class="safety-rating-tooltip"
                                    v-model="showSafetyRatingTooltips[i]"
                                >
                                    <template #activator={on}>
                                        <span
                                            v-on="on"
                                            :style="{
                                                backgroundColor: dotColor['safety_rating' in item ? item.safety_rating : 'N'],
                                            }"
                                            class="dot"
                                        ></span>
                                    </template>
                                    <span class="custom-tooltip-style">{{ SAFETY_MAPPING[item.safety_rating] }}</span>
                                </v-menu>
                                <span style="margin-left: 1rem">
                                    {{ getCarrierProp(item, 'carrier_name') }}
                                </span>
                                <span
                                    v-if="
                                        item.mc_number && item.mc_number !== ''
                                    "
                                    style="display: flex; align-items: center"
                                >
                                    <div class="header-divider"/>
                                    <span class="trucks-info">
                                        {{ item.mc_number }}
                                    </span>
                                    <v-menu
                                        bottom
                                        offset-y
                                        attach
                                        nudge-left='50%'
                                        content-class="elevation-0"
                                        v-model="showMCCopyTooltip[i]"
                                        style="box-shadow: none;"
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                v-on="on"
                                                v-bind="attrs"
                                                class="mc-copy-button"
                                                icon
                                                depressed
                                                small
                                                @click.stop="copy(item.mc_number);"
                                                @mouseleave="setMCToolTipClose(i)"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 32 32"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
                                                    />
                                                    <path
                                                        fill="currentColor"
                                                        d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z"
                                                    />
                                                </svg>
                                            </v-btn>
                                        </template>
                                        <div
                                            class="custom-tooltip-style"
                                            style="
                                                color: rgb(140, 194, 255) !important;
                                            ">Copied to Clipboard</div>
                                    </v-menu>
                                </span>
                                <div class="header-divider"/>
                                <span
                                    class="trucks-info"
                                    v-if="displayTrucks(i)"
                                >
                                    {{
                                        item.num_trucks > 1
                                            ? item.num_trucks + ' trucks '
                                            : item.num_trucks + ' truck '
                                    }}
                                </span>
                            </div>
                            <div
                                style="
                                    margin-left: auto;
                                    padding-right: 0.4rem;
                                    display: flex;
                                    font-weight: 360;
                                    color: #cccccc;
                                    align-items: center;
                                "
                            >
                                <div>
                                    {{ item.phone }}
                                </div>
                                <v-menu
                                    bottom
                                    :close-on-click="false"
                                    content-class="email-copy-tooltip"
                                    v-model="showCopyEmailTooltips[i]"
                                    nudge-top="4"
                                    offset-y
                                    nudge-left="36%"
                                    nudge-bottom="6"
                                    style="
                                        z-index: 10;
                                    "
                                >
                                    <template #activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            class="copy-button"
                                            style="color: #cccccc"
                                            icon
                                            depressed
                                            @click.stop.capture="
                                                openCopyEmailTooltip(i)
                                                copy(item.email);
                                            "
                                            @mouseenter="copyEmailMouseEnter(i)"
                                            @mouseleave="copyEmailMouseLeave(i)"
                                            ><v-icon>mdi-email</v-icon></v-btn
                                        >
                                    </template>
                                    <span class="custom-tooltip-style" v-if="copiedSuccess" style="color: rgb(140, 194, 255) !important">Copied to Clipboard</span>
                                    <span class="custom-tooltip-style" v-else style="color: #ffffff !important">Copy {{ item.email }}</span>
                                </v-menu>
                                <!-- <v-tooltip
                                    bottom
                                    open-delay="250"
                                    v-model="showCopyPhoneTooltips[i]"
                                    style="z-index: 10"
                                    nudge-top="4"
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            class="copy-button"
                                            v-on="on"
                                            v-bind="attrs"
                                            icon
                                            @click.stop.capture="
                                                copy(item.phone)
                                            "
                                            @mouseenter="
                                                updateCopyTooltip(1, item.phone)
                                            "
                                            @mouseleave="
                                                resetCopyTooltip(i, item.phone)
                                            "
                                            ><v-icon>mdi-phone</v-icon></v-btn
                                        >
                                    </template>
                                    <div
                                        :class="
                                            savedToolTip ===
                                            'Copied to clipboard'
                                                ? 'copy-tooltip'
                                                : 'hover-tooltip'
                                        "
                                    >
                                        {{ savedToolTip }}
                                    </div>
                                </v-tooltip> -->
                            </div>
                        </div>
                        <div v-else
                            style="
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                line-height: 24px;
                        ">
                            <span style="display: flex; align-items: center;">
                                <v-menu
                                    open-on-hover
                                    top
                                    offset-y
                                    nudge-top="6"
                                    style="
                                        z-index: 16;
                                        box-shadow: none;"
                                    content-class="safety-rating-tooltip"
                                    v-model="showSafetyRatingTooltips[i]"
                                >
                                    <template #activator={on}>
                                        <span
                                            v-on="on"
                                            :style="{
                                                backgroundColor: dotColor['safety_rating' in item ? item.safety_rating : 'N'],
                                            }"
                                            class="dot"
                                        ></span>
                                    </template>
                                    <span class="custom-tooltip-style">{{ SAFETY_MAPPING[item.safety_rating] }}</span>
                                </v-menu>
                                <span style="margin-left: 1rem;" class="header-item-mobile">
                                    {{ getCarrierProp(item, 'carrier_name') }}
                                </span>
                            </span>
                            <span
                                v-if="
                                    item.mc_number && item.mc_number !== ''
                                "
                                class="header-item-mobile"
                            >
                                <span class="trucks-info">
                                    {{ item.mc_number }}
                                </span>
                                <v-menu
                                    bottom
                                    offset-y
                                    attach
                                    nudge-left='50%'
                                    content-class="elevation-0"
                                    v-model="showMCCopyTooltip[i]"
                                    style="box-shadow: none;"
                                >
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            class="mc-copy-button"
                                            icon
                                            depressed
                                            small
                                            @click.stop="copy(item.mc_number);"
                                            @mouseleave="setMCToolTipClose(i)"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                viewBox="0 0 32 32"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
                                                />
                                                <path
                                                    fill="currentColor"
                                                    d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z"
                                                />
                                            </svg>
                                        </v-btn>
                                    </template>
                                    <div
                                        class="custom-tooltip-style"
                                        style="
                                            color: rgb(140, 194, 255) !important;
                                        ">Copied to Clipboard</div>
                                </v-menu>
                            </span>
                            <span
                                class="trucks-info header-item-mobile"
                                v-if="displayTrucks(i)"
                            >
                                {{
                                    item.num_trucks > 1
                                        ? item.num_trucks + ' trucks '
                                        : item.num_trucks + ' truck '
                                }}
                            </span>
                            <div class="header-item-mobile">
                                {{ item.phone }}
                            </div>
                            <v-menu
                                bottom
                                :close-on-click="false"
                                content-class="email-copy-tooltip"
                                v-model="showCopyEmailTooltips[i]"
                                nudge-top="4"
                                offset-y
                                nudge-left="36%"
                                nudge-bottom="6"
                                style="
                                    z-index: 10;
                                "
                            >
                                <template #activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class="copy-button"
                                        style="color: #cccccc"
                                        icon
                                        depressed
                                        @click.stop.capture="
                                            openCopyEmailTooltip(i)
                                            copy(item.email);
                                        "
                                        @mouseenter="copyEmailMouseEnter(i)"
                                        @mouseleave="copyEmailMouseLeave(i)"
                                        ><v-icon>mdi-email</v-icon></v-btn
                                    >
                                </template>
                                <span class="custom-tooltip-style" v-if="copiedSuccess" style="color: rgb(140, 194, 255) !important">Copied to Clipboard</span>
                                <span class="custom-tooltip-style" v-else style="color: #ffffff !important">Copy {{ item.email }}</span>
                            </v-menu>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                        <div
                            style="
                                display: grid;
                                grid-template-columns: 0.8fr repeat(2, 0.9fr) repeat(2, 0.6fr) 0.9fr;
                                grid-template-rows: [row1] 50% [row2] 50%;
                                height: 140px;
                                margin-left: 1%;
                            "
                            :style="isMobile ? {
                                'grid-template-columns': '1fr 1fr',
                                'grid-template-rows': 'auto 80px 80px repeat(4, auto)',
                                'height': 'fit-content',
                                'margin-left': '0',
                                'gap': '8px',
                            } : {}"
                        >
                            <div
                                class="text-border"
                            >
                                <div class="column-heading">DOT Number</div>
                                <div>
                                    {{ getCarrierProp(item, 'dot_number') }}
                                </div>
                            </div>
                            <div class="text-border" v-if="isMobile">
                                <div class="column-heading">Hazmat</div>
                                <div>
                                    {{ getCarrierProp(item, 'hazmat') }}
                                </div>
                            </div>
                            <div
                                class="text-border"
                                style="
                                    grid-row: span 2;
                                    height: 88%;
                                "
                            >
                                <div class="column-heading">
                                    Equipment Types
                                </div>
                                <div v-if="!item.equipment_types">
                                    {{ '-' }}
                                </div>
                                <div v-else
                                    class="show-scroll"
                                    style="
                                        overflow-y: auto;
                                        max-height: 80%;
                                    "
                                >
                                    <div
                                        v-for="equipment in item.equipment_types"
                                        :key="equipment"
                                    >
                                        {{ equipment ? equipment : '-' }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="text-border"
                                style="
                                    grid-row: span 2;
                                    height: 88%;
                                "
                            >
                                <div class="column-heading">
                                    Transported Cargo
                                </div>
                                <div v-if="!item.cargo_transported">
                                    {{ '-' }}
                                </div>
                                <div v-else
                                    class="show-scroll"
                                    style="
                                        overflow-y: auto;
                                        max-height: 80%;
                                    "
                                    :style="isMobile ? {'max-height': '60%'} : {}"
                                >
                                    <div
                                        v-for="cargo in item.cargo_transported"
                                        :key="cargo"
                                    >
                                        {{ cargo ? cargo : '-' }}
                                    </div>
                                </div>
                            </div>
                            <div class="text-border" v-if="!isMobile">
                                <div class="column-heading">Hazmat</div>
                                <div>
                                    {{ getCarrierProp(item, 'hazmat') }}
                                </div>
                            </div>
                            <div class="text-border">
                                <div class="column-heading">
                                    Y.I.B
                                    <div class="info-tooltip">
                                        <v-icon small color="#7b7b7b">mdi-help-circle-outline</v-icon>
                                        <span class="info-tooltip-text">Years in business</span>
                                    </div>
                                </div>
                                <div>
                                    {{
                                        getCarrierProp(
                                            item,
                                            'in_business_for_years'
                                        )
                                    }}
                                </div>
                            </div>
                            <div
                                class="text-border"
                            >
                                <div class="column-heading">
                                    Location
                                </div>
                                <div>
                                    {{
                                        getCarrierProp(
                                            item,
                                            'location'
                                        )
                                    }}
                                </div>
                            </div>


                            <div
                                class="text-border"
                            >
                                <div class="column-heading">Safety Rating</div>
                                <div>
                                    {{ getCarrierProp(item, 'safety_rating') }}
                                </div>
                            </div>
                            <div
                                class="text-border"
                                style="grid-column: span 2;"
                            >
                                <div class="column-heading">
                                    Inspections / Crashes
                                    <div class="info-tooltip">
                                        <v-icon small color="#7b7b7b">mdi-help-circle-outline</v-icon>
                                        <span class="info-tooltip-text">Inspections and crashes over the past two years</span>
                                    </div>
                                </div>
                                <div>
                                    {{
                                        getCarrierProp(
                                            item,
                                            'num_inspections_last_two_years'
                                        )
                                    }}
                                    {{
                                        getCarrierProp(item, 'num_inspections_last_two_years') !== 1
                                            ? 'inspections'
                                            : 'inspection'
                                    }}
                                    /
                                    {{
                                        getCarrierProp(
                                            item,
                                            'num_crashes_last_two_years'
                                        )
                                    }}
                                    {{
                                        getCarrierProp(item, 'num_crashes_last_two_years') !== 1
                                            ? 'crashes'
                                            : 'crash'
                                    }}
                                </div>
                            </div>
                            <div
                                class="text-border"
                                :style="isMobile ? {'grid-column': 'span 2'} : {}"
                            >
                                <div class="column-heading">
                                    Min Coverage
                                </div>
                                <div>
                                    {{
                                        getCarrierProp(
                                            item,
                                            'min_insurance_coverage_amount'
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </div>
            <div style="margin-left: auto">
                <span>
                    {{ pageSize * pageIndex + 1 }}-{{
                        Math.min(pageSize * (pageIndex + 1), carrierData.length)
                    }}
                    of {{ carrierData.length }} results
                </span>
                <v-btn
                    icon
                    :disabled="pageIndex === 0"
                    @click="
                        pageIndex = 0;
                        panelStates = [];
                    "
                    ><v-icon>mdi-chevron-double-left</v-icon></v-btn
                >
                <v-btn
                    icon
                    :disabled="pageIndex === 0"
                    @click="
                        pageIndex--;
                        panelStates = [];
                    "
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <v-btn
                    icon
                    :disabled="pageIndex >= pageCount"
                    @click="
                        pageIndex++;
                        panelStates = [];
                    "
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
                <v-btn
                    icon
                    :disabled="pageIndex >= pageCount"
                    @click="
                        pageIndex = pageCount;
                        panelStates = [];
                    "
                    ><v-icon>mdi-chevron-double-right</v-icon></v-btn
                >
            </div>
            <v-overlay absolute :value="isLoading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-overlay>

            <!-- Dialog for carrier search error -->
            <v-overlay absolute :value="carrierSearchError">
                <v-card style="background: transparent;" elevation="0">
                    <v-card-text>
                        <v-alert type="error" color="#C94B4E">
                            <div class="mt-n1">
                                Sorry, carrier search failed! Please try again.
                                <v-btn icon @click="closeAlert" class="ml-auto">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-overlay>


        </v-expansion-panels>
    </div>
</template>
<script>
import Vue from 'vue';
import { isDevEnv, APIEndpoints } from '../resources';
import { formatDollars, convertStringToInt } from './formatUtils';

const PAGE_SIZE = 10;
const EQUIPMENT_TYPES = {
    "Dry Van": "dry van",
    "Reefer": "reefer",
    "Flatbed": "flatbed",
    "Power Only": "power only",
    "Container": "container",
    "Straight Truck": "straight truck",
    "Pup": "pup",
    "Vented Van": "vented van",
    "Cargo Van": "cargo van",
}
const CARGO_TRANSPORTED = {
    "Beverages": "beverages",
    "Building Materials": "building materials",
    "Chemicals": "chemicals",
    "Coal/Coke": "coalcoke",
    "Commodities Dry Bulk": "commodities dry bulk",
    "Construction": "construction",
    "Driveaway/Towaway": "driveaway towaway",
    "Farm Supplies": "farm supplies",
    "Fresh Produce": "fresh produce",
    "Garbage, Refuse, Trash": "garbage refuse trash",
    "General Freight": "general freight",
    "Grain, Feed, Hay": "grain feed hay",
    "Household Goods": "household goods",
    "Intermodal Containers": "intermodal containers",
    "Liquids/Gases": "liquids gases",
    "Livestock": "livestock",
    "Logs, Poles, Beams, Lumber": "logs poles beams lumber",
    "Machinery, Large Objects": "machinery large objects",
    "Meat": "meat",
    "Metal: Sheets, Coils, Rolls": "metal sheets coils rolls",
    "Mobile Homes": "mobile homes",
    "Motor Vehicles": "motor vehicles",
    "Oilfield Equipment": "oilfield equipment",
    "Paper Products": "paper products",
    "Passengers": "passengers",
    "Refrigerated Food": "refrigerated food",
    "U.S. Mail": "us mail",
    "Utility": "utility",
    "Water-Well": "water well",
}
const STATE_CODES = ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MP", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TT", "TX", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"];
const FILTER_DEFAULTS = {
    "cargo_or_equipment_hauled": [],
    "insurance_coverage_min": 0,
    "fleet_size_min": 10,
    "fleet_size_max" : 300,
    "in_business_years_min": 0, //UI min
    "in_business_years_max": 60, // UI max
    "safety_ratings": ["S", "N"],
    "inspections_min": 0,
    "backhaul": false,
    "origin_city": '',
    "origin_state": '',
    "destination_city": '',
    "destination_state": '',
    "near_origin_radius": 50,
}
const SAFETY_MAPPING = {
    "S": "Satisfactory",
    "C": "Conditional",
    "U": "Unsatisfactory",
    "N": "No Rating",
}

const FLEET_MIN = 0;
const FLEET_MAX = 300;
const FLEET_LOG_MIN = 0.01;
const FLEET_LOG_MAX = 120000;

export default {
    name: 'carrierSearch',
    props: ['mapEvents', 'id', 'isMobile', 'carrier_data', 'websocket', 'is_public'],
    data() {
        return {
            panelStates: [],
            panelObserver: null,
            timers: [],
            isLoading: false,
            allExpanded: false,
            dotColor: {
                S: '#1DD3C0',
                C: '#D3B61D',
                U: '#D31D74',
                N: '#7B7B7B',
            },
            pageIndex: 0,
            pageSize: PAGE_SIZE,
            SAFETY_MAPPING,

            allCopied: false,
            copyAllToolTip: false,
            copiedSuccess: false,
            savedToolTip: '',
            showMCCopyTooltip: Array.from(
                { length: PAGE_SIZE },
                () => false
            ),
            showSafetyRatingTooltips: Array.from(
                { length: PAGE_SIZE },
                () => false
            ),
            showCopyEmailTooltips: Array.from(
                { length: PAGE_SIZE },
                () => false
            ),
            showCopyPhoneTooltips: Array.from(
                { length: PAGE_SIZE },
                () => false
            ),

            filterMenu: false,
            carrierData: this.carrier_data.search_results,
            filterData: {
                ...FILTER_DEFAULTS,
                ...this.carrier_data.filters
            },
            fleetSizeSlider: [
                this.logToLinear(Math.min(10, this.carrier_data.filters?.fleet_size_min || 10)),
                this.logToLinear(Math.max(300, this.carrier_data.filters?.fleet_size_max || 300))
            ],
            inBusinessSlider: [
                Math.min(0, this.carrier_data.filters.in_business_years_min || 0),
                Math.max(60, this.carrier_data.filters.in_business_years_max || 60)
            ],
            dropFleetSize: false,
            searchInputCargo: '',
            searchInputOrigState: '',
            searchInputDestState: '',
            equipmentAndCargo: [...Object.keys(EQUIPMENT_TYPES), ...Object.keys(CARGO_TRANSPORTED)],
            stateCodes: STATE_CODES,

            fleetMin: FLEET_MIN,
            fleetMax: FLEET_MAX,

            carrier_search_endpoint: isDevEnv()
                ? APIEndpoints.dev.carrier_search_endpoint
                : APIEndpoints.prod.carrier_search_endpoint,

            carrierSearchError: false
        };
    },
    computed: {
        pages() {
            // Returns a list of paginated lists from carrier data
            return this.carrierData.reduce((acc, item, index) => {
                const pageIndex = Math.floor(index / this.pageSize);
                if (!acc[pageIndex]) {
                    acc[pageIndex] = [];
                }
                acc[pageIndex].push(item);
                return acc;
            }, []);
        },
        pageCount() {
            return Math.floor((this.carrierData.length - 1) / this.pageSize);
        },
        logarithmicFleetRange() {
            let result = [...this.fleetSizeSlider]
            return result.map(this.linearToLog);
        },
        console: () => console,
    },
    methods: {
        displayTrucks(i) {
            if (!this.isMobile) {
                return true;
            } else if (this.isMobile && !this.itemsOpened(i)) {
                return true;
            } else {
                return false;
            }
        },
        itemsOpened(index) {
            return this.panelStates.includes(index);
        },
        toggleAllItems() {
            this.panelStates = [];
            if (!this.allExpanded) {
                for (let index = 0; index < this.carrierData.length; index++) {
                    this.panelStates.push(index);
                }
            }
            this.allExpanded = !this.allExpanded;
        },
        downloadAll() {
            let jsonData = this.carrierData;

            const headerMappings = {
                carrier_name: 'Carrier Name',
                email: 'Email Address',
                phone: 'Phone Number',
                dot_number: 'Dot Number',
                mc_number: 'MC Number',
                safety_rating: 'Safety Rating',
                num_trucks: 'Fleet Size',
                cargo_transported: 'Transported Cargo',
                equipment_types: 'Equipment Types',
                location: 'Address',
                hazmat: 'Hazmat',
                in_business_for_years: 'Years In Business',
                num_inspections_last_two_years: 'Inspections (2y)',
                num_crashes_last_two_years: 'Crashes (2y)'
            };
            const headers = Object.keys(headerMappings);

            let csvContent =
                headers.map((header) => headerMappings[header]).join(',') +
                ';\n';
            jsonData.forEach((item) => {
                const row = headers.map((header) => {
                    let data = item[header];

                    if (Array.isArray(data)) {
                        return (
                            '"' +
                            data
                                .map((value) => value.replace(/"/g, '""'))
                                .join(';') +
                            '"'
                        );
                    } else {
                        if (data) {
                            if (typeof data == 'number') {
                                return data ? data : '-';
                            } else {
                                return data.includes(',')
                                    ? '"' + data.replace(/"/g, '""') + '"'
                                    : item[header];
                            }
                        } else {
                            return '-';
                        }
                    }
                });
                csvContent += row.join(',') + '\n';
            });

            const blob = new Blob([csvContent], { type: 'text/csv' });

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let fileName = 'carriers_' +new Date().getTime();
            link.setAttribute('download', fileName + '.csv');

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        },
        copyAllEmails() {
            let jsonData = this.carrierData;
            let copiedText = '';
            jsonData.forEach((obj, index) => {
                if (obj.email) {
                    copiedText = copiedText + obj.email;
                    if (jsonData.length != index + 1) {
                        copiedText = copiedText + '; ';
                    }
                }
            });
            navigator.clipboard
                .writeText(copiedText)
                .then(() => {
                    console.log('Copied to Clipboard!');
                    this.allCopied = true;
                })
                .catch((error) => {
                    console.error('Failed to copy: ', error);
                });

            this.timers.push(
                setTimeout(() => {
                    this.allCopied = false;
                }, 800)
            );
        },
        copy(toCopy) {
            navigator.clipboard
                .writeText(toCopy)
                .then(() => {
                    this.copiedSuccess = true;
                    this.timers.push(
                        setTimeout(() => {
                            this.copiedSuccess = false;
                        }, 1200)
                    );
                    console.log('Copied to Clipboard!');
                })
                .catch((error) => {
                    this.updateCopyTooltip(3, toCopy);
                    console.error('Failed to copy: ', error);
                });
        },
        openCopyEmailTooltip(index) {
            Vue.set(this.showCopyEmailTooltips, index, true);
        },
        copyEmailMouseEnter(index) {
            this.copiedSuccess = false;
            this.toolTipDelay = setTimeout(() => {
                Vue.set(this.showCopyEmailTooltips, index, true);
            }, 250);
            this.timers.push(this.toolTipDelay);
        },
        copyEmailMouseLeave(index) {
            clearTimeout(this.toolTipDelay);
            Vue.set(this.showCopyEmailTooltips, index, false);
            this.timers.push(
                setTimeout(() => {
                    this.copiedSuccess = false;
                }, 400)
            );
        },
        centerAndShowTooltip(tooltipClass) {
            const activators = this.$refs.rootElement.getElementsByClassName('tooltip-activator');
            const tooltips = this.$refs.rootElement.getElementsByClassName('menu-tooltip');

            for (let i = 0; i < activators.length; i++) {
                const activator = activators[i];
                const tooltip = tooltips[i];

                if (tooltip.classList.contains(tooltipClass)) {
                    const activatorRect = activator.getBoundingClientRect();
                    const tooltipRect = tooltip.getBoundingClientRect();

                    const centerX = activatorRect.left + (activatorRect.width / 2);
                    const tooltipOffsetX = centerX - (tooltipRect.width / 2 );
                    const tooltipOffsetY = activatorRect.bottom + 6;

                    tooltip.style.left = `${tooltipOffsetX}px`;
                    tooltip.style.top = `${tooltipOffsetY}px`;
                    tooltip.style.visibility = 'visible';
                }

            }
        },
        hideTooltip(tooltipClass) {
            const tooltips = document.getElementsByClassName(tooltipClass);
            for (let i = 0; i < tooltips.length; i++) {
                const tooltip = tooltips[i];
                if (tooltip.classList.contains(tooltipClass)) {
                    tooltip.style.visibility = 'hidden';
                }
            }
        },

        getCarrierProp(item, property) {
            if (property === 'safety_rating') {
                return item?.[property] ? this.SAFETY_MAPPING[item[property]] : '-';
            } else if (property === 'num_crashes_last_two_years' || property === 'inspections_min') {
                return item?.[property] ? item[property] : 0;
            } else if (property === 'min_insurance_coverage_amount') {
                return item?.[property] ? formatDollars(item[property]) : '-';
            }
            return item?.[property] ? item[property] : '-';
        },

        resetFilter() {
            if (this.dropFleetSize) {
                this.filterData = {
                    ...FILTER_DEFAULTS,
                    ...this.carrier_data.filters,
                    "fleet_size_min": '',
                    "fleet_size_max" : '',
                }
                return
            }
            this.filterData = {
                ...FILTER_DEFAULTS,
                ...this.carrier_data.filters,
            }
        },
        validateNumberInput(value, key) {
            if (value === '') {
                return;
            }
            if (parseInt(value) <= 0) {
                Vue.set(this.filterData, key, 0);
                return;
            }
            Vue.set(this.filterData, key, parseInt(value));

        },
        filterNumberInput(event) {
            // Only allow select keypresses
            const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
            if (!validKeys.includes(event.key)) {
                event.preventDefault();
            }
        },
        validateDollarInput(value, key) {
            if (value === '') return;
            let cleanedVal = value.replace(/[^0-9]/g, '');
            const result =  formatDollars(parseFloat(cleanedVal), 0, false, true)
            Vue.set(this.filterData, key, result);
        },
        preventEmptyInput(value, key) {
            if (value === '') {
                Vue.set(this.filterData, key, FILTER_DEFAULTS[key]);
            }
        },
        applyFilter() {
            this.filterMenu = false;
            this.isLoading = true;
            this.websocket.sendMessage(
                {
                    action: this.carrier_search_endpoint,
                    ...this.cleanFilterForQuery(this.filterData),
                    ...(this.is_public && { isPublicChat: 1 })
                },
                this.setFilteredCarrierSearch
            );
        },
        cleanFilterForQuery(filterData) {
            let cleanedFilter = {};
            filterData['in_business_years_min'] = Math.min(...this.inBusinessSlider)
            filterData['in_business_years_max'] = Math.max(...this.inBusinessSlider)
            for (let key in filterData) {
                if ((key === 'fleet_size_min' || key === 'fleet_size_max') && filterData[key] === '') {
                    continue
                }
                if (key === 'in_business_years_min') {
                    cleanedFilter['in_business_months_min'] = filterData[key] * 12;
                } else if (key === 'in_business_years_max') {
                    cleanedFilter['in_business_months_max'] = filterData[key] * 12;
                } else if (key === 'insurance_coverage_min') {
                    cleanedFilter[key] = convertStringToInt(filterData[key]);
                } else {
                    cleanedFilter[key] = filterData[key];
                }
            }
            return cleanedFilter;
        },
        setFilteredCarrierSearch(event, socket) {
            this.isLoading = false;
            this.pageIndex = 0;
            var event_data = JSON.parse(event.data);
            if (event_data.event?.type === "error"){
                this.carrierSearchError = true;
                this.resetFilter()
                socket.close()
                return;
            }
            this.carrierData = event_data;
            socket.close();
        },
        closeAlert() {
            this.carrierSearchError = false;
        },
        linearToLog(value) {
            if (value === 0) return FLEET_LOG_MIN;
            if (value === undefined) return undefined;

            const mid = (FLEET_MAX + FLEET_MIN) / 2;
            if (value < mid) {
                return  (FLEET_LOG_MAX - FLEET_LOG_MIN) * (value - FLEET_MIN) / (mid - FLEET_MIN) + FLEET_LOG_MIN
            } else {
                const scale = (Math.log2(FLEET_LOG_MAX) - Math.log2(FLEET_LOG_MIN)) / (FLEET_MAX - FLEET_MIN);
                return Math.pow(2, Math.log2(FLEET_LOG_MIN) + scale * (value - FLEET_MIN));
            }
        },
        logToLinear(value) {
            if (value <= FLEET_LOG_MIN) return 0;
            if (value === undefined) return undefined;

            const mid = (FLEET_MAX + FLEET_MIN) / 2;
            if (value <= (FLEET_LOG_MAX - FLEET_LOG_MIN) / 2 + FLEET_LOG_MIN) {
                return (value - FLEET_LOG_MIN) * (mid - FLEET_MIN) / (FLEET_LOG_MAX- FLEET_LOG_MIN) + FLEET_MIN;
            } else {
                const scale = (Math.log2(FLEET_LOG_MAX) - Math.log2(FLEET_LOG_MIN)) / (FLEET_MAX  - FLEET_MIN);
                return (Math.log2(value) - Math.log2(FLEET_LOG_MIN)) / scale + FLEET_MIN;
            }

        },

        scrollPanelIntoView(index) {
            if (!this.panelStates.includes(index)) {
                const element = this.$refs[`panel${index}`][0].$el
                if (element) {
                    this.timers.push(
                        setTimeout(() => {
                            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                        }, 300)
                    );
                }
            }
        },
        setMCToolTipClose(index) {
            this.$set(this.showMCCopyTooltip, index, false);
        }
    },
    mounted() {
        if ("expanded_search" in this.carrier_data && this.carrier_data.expanded_search) {
            this.dropFleetSize = true;
            this.resetFilter();
        }
    },
    watch: {
        panelStates() {
            if (this.panelStates.length == this.carrierData.length) {
                this.allExpanded = true;
            }
            if (this.panelStates.length == 0) {
                this.allExpanded = false;
            }
        },
    },
    beforeDestroy() {
        this.timers.forEach((timer) => {
            clearTimeout(timer)
        });
    }
};
</script>
<style scoped>
.carrier-search-container {
    background-color: #1e1e1e;
    border-radius: 10px;
    margin-top: 0.4rem;
}

.carrier-container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow-y: auto;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.12);
}

.menu-panel {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.v-expansion-panel:not(:first-child) {
    border-top: 0.5px solid rgba(255, 255, 255, 0.12);
}

.v-expansion-panel::after {
    border: none !important;
}

.hover-tooltip {
    color: #ffffff;
}
.copy-tooltip {
    color: rgb(140, 194, 255) !important;
}
.safety-rating-tooltip {
    box-shadow: none;
    height: 30px;
    transform: translateX(-50%);
}
.email-copy-tooltip {
    box-shadow: none;
    height: 30px;
}
.menu-tooltip {
    box-shadow: none;
    visibility: hidden;
    height: 30px;
    z-index: 10;
    position: fixed;
    display: inline-block;
}
.menu-tooltip span {
    background-color: #333333;
    color: #dddddd;
    text-align: center;
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 14px;
}
.custom-tooltip-style {
    background-color: #333333;
    color: #dddddd;
    text-align: center;
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 14px;
}

.filter-row {
    display: flex;
    align-items: center;
    margin: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    justify-content: flex-start;
    width: 100%;
    min-height: 70px;
    height: fit-content;
}
.filter-row ::v-deep .v-messages {
    height: 2px !important;
    min-height: 0 !important;
    line-height: 2px !important;
}
.filter-row ::v-deep .v-text-field__details {
    height: 2px !important;
    min-height: 0 !important;
    line-height: 2px !important;
}
.filter-item {
    /* padding: 0 1rem 0 1rem; */
    align-items: flex-end;
    padding-top: 0;
    margin-top: 0;
}
.filter-group {
    display: flex;
    margin-left: 0.6rem;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.7);;
    padding: 0.6rem 0 0 0.2rem;
    border-radius: 0.6rem;
    justify-content: space-around;
    position: relative;
}
.filter-group-header {
    position: absolute;
    top: -1.5rem;
    left: 0.4rem;
    color: rgba(255, 255, 255, 0.7);
}
.filter-item.v-input--is-focused ::v-deep input {
    min-width: 0px !important;
}

.outbound-toggle {
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    user-select: none;
}
.outbound-toggle .v-input {
    padding: 0 !important;
    margin: 0 0 0 8px !important;
}
.outbound-toggle ::v-deep .v-input__slot {
    margin: 0;
}
.outbound-toggle ::v-deep .v-messages {
    display: none;
}

::v-deep .v-list-item {
    min-height: 32px !important;
}
::v-deep .v-list-item__content {
    padding: 0 0 0 0.8rem !important;
}
::v-deep .v-list-item__action {
    margin: 0 !important;
}
::v-deep .v-input__prepend-outer {
    align-self: center;
}
::v-deep .v-input--range-slider .v-input__control .v-input__slot {
    margin-bottom: 0;
}

.custom-slider {
    position: relative;
    top: 1.8rem;
}
.filter-safety-item {
    padding: 0.2rem 0.6rem;
    font-size: 14px;
    margin-right: 0.5rem;
    cursor: pointer;
}
.mc-copy-tooltip {
    box-shadow: none;
    background-color: rgba(97, 97, 97, 0.9);
    opacity: 0.9;
    color: rgb(140, 194, 255);
    border-radius: 4px;
    padding: 5px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.custom-slider ::v-deep .v-label {
    /* color: #000000 */
    position: absolute !important;
    top: -1rem;
}

.hide-label ::v-deep .v-label {
    position: absolute;
    top: 10px;
}

.oversize-label ::v-deep .v-label--active {
    text-wrap: wrap;
    position: absolute;
    height: fit-content;
    top: -10px;
}

.copy-button {
    border-radius: 12px;
    margin-left: 10px;
}

.copy-button.v-btn::before {
    opacity: 0 !important;
}

.mc-copy-button.v-btn::before {
    opacity: 0 !important;
}

.text-border {
    border-left: 1px solid #c9c9c9;
    padding: 0 0 0 8px;
    margin: 10px 0 10px 10px;
    height: min-content;
}

.header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
}

.header-divider {
    width: 1px;
    height: 24px;
    align-self: center;
    background-color: #a9a9a9;
    margin: 6px;
}
.header-item-mobile {
    display: flex;
    align-items: center;
    margin-right: 10px !important;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 1px;
    flex-shrink: 0;
}

.trucks-info {
    color: #9c9c9c;
    font-style: italic;
    margin-right: 3px;
}

.button-container {
    display: flex;
}
.button-container.mobile {
    flex-wrap: wrap;
}


.transparent-button {
    background-color: transparent !important;
    border: 1px solid;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
    text-transform: none;
}
.v-expansion-panel-content {
    border-top: 1px solid #3181b6;
    margin: 0px 15px;
}
::v-deep .v-expansion-panel-content__wrap {
    padding: 12px 0 12px 0 !important;
}
.content-wrapper {
    display: flex;
}
.column-heading {
    color: #7b7b7b;
}

.show-scroll::-webkit-scrollbar {
    width: 0.4rem !important;
}
.show-scroll::-webkit-scrollbar-thumb {
    border-radius: 0.2rem;
}
.show-scroll::-webkit-scrollbar-track {
    border-radius: 0.2rem;
}

.info-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.info-tooltip .info-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #333333;
    color: #dddddd;
    text-align: center;
    border-radius: 6px;
    padding: 6px 12px;
    position: absolute;
    z-index: 7;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}

.info-tooltip:hover .info-tooltip-text {
    visibility: visible;
    opacity: 1;
}

.selected-item {
    background-color: #3FA0E1;
}

@media screen and (max-width: 600px) {
    .panel-header {
        padding: 0.4rem 0.6rem;
    }
    .text-border {
        margin-top: 0;
        margin-bottom: 0;
    }
    .header {
        padding: 12px 2px;
    }
}
</style>
