import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // User Info
        username: '',
        name: '',
        email: '',
        phone_number: '',
        company: '',
        role: ''
    },

    mutations: {
        setStateProperty(state: any, prop: any) {
            state[prop.property] = prop.value;
        },

        addToStateList(state: any, prop: any) {
            state[prop.property].push(prop.value);
        }
    },

    plugins: [createPersistedState()]
});
