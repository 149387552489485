<template>
    <div class="signup-button-wrapper">
        <v-btn
            dark
            class="text-capitalize signup-button"
            ref="waitlistBtn"
            @click="openSignUpForm"
            text
            color="#0366f9"
            large
            ><span class="white--text">Sign Up for Free</span>
        </v-btn>
    </div>
</template>

<script>
export default {
    methods: {
        openSignUpForm() {
            const url = window.location.href
            this.$store.commit('setStateProperty', {
                property: 'url',
                value: url
            });
            this.$router.push({
                name: 'signup',
                params: { signUpInitialRedirection: 'true' }
            });
        }
    }
}
</script>

<style scoped>
.signup-button {
    background: #07090e;
    color: whitesmoke;
    margin: 1.6px;
    font-size: 14px;
}

.signup-button-wrapper {
    max-width: fit-content;;
    width: fit-content;
    height: fit-content;
    position: relative;
    background: linear-gradient(to left, #0366f9, #ad00ff);
    border-radius: 7%;
}

@media screen and (max-width: 600px) {
    .signup-button {
        padding: 0 4px !important;
    }
}
</style>
