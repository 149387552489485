<template>
    <v-app class="app">
        <AppBar :show_login_button="false"></AppBar>
        <v-main class="white--text">
            <v-container fluid class="page-container">
                <h1 class="text-h2 text-center pt-10 pb-10">
                    On Demand Freight Trucking Quotes
                </h1>
                <p class="pb-4">
                    Trucking companies face a lot of challenges consistently
                    determining quotes because they vary with the market. This is
                    because there are a variety of factors contributing quotes
                    including fuel, distance, demand, and cargo type.
                </p>
                <div class="landing-spacer hidden-sm-and-down" />
                <DemoChat page_name="DryVanRatesDemo" />

                <div class="landing-spacer hidden-sm-and-down" />

                <!-- Use Case Section -->
                <section
                    style="display: flex"
                    :style="
                        isMobile
                            ? { flexDirection: 'column', alignItems: 'center' }
                            : { flexDirection: 'row', paddingLeft: '2rem', paddingRight: '2rem'}
                    ">
                    <div
                        :style="
                            isMobile ? { width: '90%', marginTop: '40px' } : { width: '50%' }
                        ">
                        <h2 class="landing-sub-header">
                            Understand the market
                        </h2>
                        <div
                            v-for="(item, index) in useCases"
                            :key="index"
                            style="padding-bottom: 1rem"
                            class="mr-4">
                            <div
                                class="landing-text"
                                style="display: flex; align-items: center">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 122 122"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="flex-shrink: 0">
                                    <path
                                        d="M75 0C61.7392 0 49.0215 5.85316 39.6447 16.2718C30.2678 26.6905 25 40.8213 25 55.5556C25 70.2898 30.2678 84.4206 39.6447 94.8393C49.0215 105.258 61.7392 111.111 75 111.111C88.2608 111.111 100.979 105.258 110.355 94.8393C119.732 84.4206 125 70.2898 125 55.5556H112.5C112.5 78.6111 95.75 97.2222 75 97.2222C65.0544 97.2222 55.5161 92.8324 48.4835 85.0183C41.4509 77.2043 37.5 66.6062 37.5 55.5556C37.5 44.5049 41.4509 33.9068 48.4835 26.0928C55.5161 18.2788 65.0544 13.8889 75 13.8889C77.6875 13.8889 80.375 14.2361 82.9375 14.8611L93 3.75C87.25 1.25 81.25 0 75 0ZM116.188 10.9722L75 56.7361L60.125 40.2083L51.3125 50.0694L75 76.3889L125 20.8333M18.3125 26.5278C6.75 37.0833 0 52.8472 0 69.4444C0 84.1787 5.26784 98.3095 14.6447 108.728C24.0215 119.147 36.7392 125 50 125C54 125 57.9375 124.444 61.75 123.403C50.75 120.694 40.625 114.583 32.3125 106.181C20.125 98.9583 12.5 84.7917 12.5 69.4444C12.5 67.3611 12.6875 65.3472 12.9375 63.2639C12.6875 60.6944 12.5 58.125 12.5 55.5556C12.5 45.5556 14.5 35.625 18.3125 26.5278Z"
                                        fill="#22C0DA" />
                                </svg>
                                <div style="margin-left: 1rem">
                                    {{ item.answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        "
                        :style="isMobile ? { width: '100%', justifyContent: 'center' } : { width: '50%', justifyContent: 'flex-end' }"
                    >
                        <SignUpPanel style="background-color: rgba(255, 255, 255, 0.05);"  />
                    </div>
                </section>

                <div class="landing-spacer" />
                <h2 class="text-h3 pt-10 pb-6">
                    What factors contribute to freight quotes?
                </h2>
                <v-row>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Standard Quote
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                The base quote for trucking companies consists of
                                their operating costs. This includes items such
                                as equipment cost, maintenance, and truck driver
                                salary. While equipment cost tends to be more
                                static, other items like salary and maintenance
                                tend to vary.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Fuel
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Fuel price is one of the key drivers of cost.
                                This is because it can vary across the country
                                meaning the origin and destination greatly
                                affect the cost. It is also easily affected by
                                other factors such as distance and weight.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Freight Class
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Industry standards like the ones defined in the
                                National Motor Freight Classification (NMFC)
                                determine the class of cargo. The NMFC defines
                                18 different classes based on density, handling,
                                stowability, and liability. These classes and
                                the type of cargo contribute to the quote as
                                well.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Distance
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Distance is one of the key factors driving the
                                quotes. A longer distance traveled leads to
                                higher pricing for fuel both because of miles
                                driven and varying fuel prices. It also
                                contributes to salary and means more wear and
                                tear on the equipment. It also requires the
                                proper training when traveling across state
                                lines to abide by local laws.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Weight
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                A higher weight will also contribute to higher
                                costs. Not only will this affect fuel cost since
                                it will take more fuel to transport but there
                                are also additional charges for more weight. If
                                the weight exceeds the maximum allowed then it
                                requires more trips.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="transparent-card full-height">
                            <v-card-title style="word-break: break-word">
                                Demand
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Current demand will also contribute to cost. If
                                there is a huge need for trucks, but not a lot
                                available then the price will be higher.
                                Conversely, if there is not as great of a need
                                and many trucks available then this will drive
                                prices down. This sway in the truck market makes
                                it difficult to consistently price freight and
                                maintain a stable margin.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <h2 class="text-h3 py-8">How to calculate quotes?</h2>

                <p>
                    It can be difficult to understand the current market for
                    specific lanes. A lack of visibility into the whole market
                    as well as static national quotes contribute to this.
                    FreightGPT enables a user to check dynamic quotes at any
                    time.
                </p>
                <v-row justify="end" class="py-4 pr-8">
                    <div style="border: 2px solid #0366f9" class="rounded pa-4">
                        What is the dry van rate for Chicago to Atlanta?
                    </div>
                </v-row>
                <v-row class="py-4 px-16">
                    <div style="border: 2px solid #ad00ff" class="rounded pa-4">
                        The cost of a Dry Van from Chicago, IL to Atlanta, GA is
                        $1,663.20.
                    </div>
                </v-row>

                <p class="pt-8 pb-6">
                    We can then understand what the fuel cost for this route
                    would be.
                </p>

                <v-row justify="end" class="py-4 pr-8">
                    <div style="border: 2px solid #0366f9" class="rounded pa-4">
                        How much is the route fuel cost for this van freight?
                    </div>
                </v-row>
                <v-row class="py-4 px-16">
                    <div style="border: 2px solid #ad00ff" class="rounded pa-4">
                        The route fuel cost for the shipment from Chicago to
                        Atlanta is $0.02.
                    </div>
                </v-row>
                <v-row class="py-6" align="center" justify="center">
                    <SignUpPanel></SignUpPanel>
                </v-row>
            </v-container>
        </v-main>
        <FooterBar></FooterBar>
    </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppBar from '../components/AppBar.vue';
import FooterBar from '../components/Footer.vue';
import * as analytics from '../analytics/pinpoint';
import SignUpPanel from '../components/SignUpPanel.vue';
import DemoChat from '../components/DemoChat.vue';

export default defineComponent({
    name: 'TruckingQuotes',
    metaInfo: {
        title: 'On Demand Freight Trucking Quotes with AI | FreightGPT',
        link: [
            {
                rel: 'canonical',
                href: 'https://www.freightgpt.com/freight-trucking-quotes'
            }
        ],
        meta: window.location.href.includes('freightgpt.com')
            ? [{ name: 'description', content: "Get accurate and instant freight quotes with our AI-powered freight solutions. Save time and money on freight with our intelligent cost-saving solutions." }]
            : [{ name: 'robots', content: 'noindex' }, { name: 'description', content: "Get accurate and instant freight quotes with our AI-powered freight solutions. Save time and money on freight with our intelligent cost-saving solutions." }]
    },
    components: {
        AppBar,
        FooterBar,
        SignUpPanel,
        DemoChat
    },
    computed: {
        isMobile(): any {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    beforeMount() {
        // Force scroll to top when page is loaded
        window.scrollTo(0, 0);
        analytics.sendEvent("trucking_rates_seo_page_visit");
        this.$store.commit('setStateProperty', {
                property: 'sourcePage',
                value: "seo_page_trucking_rates"
            });
    },
    data() {
        return {
            useCases:[
                {
                    answer: 'Save on loads by using FreightGPT’s quotes for leveraged negotiation.'
                },
                {
                    answer: 'Improve your margins by finding precise market quotes using FreightGPT'
                }
            ]
        }
    }
});
</script>

<style scoped>
html,
body {
    overflow-y: auto !important;
    background: #07090e !important;
}

.app {
    background: #07090e !important;
    color: whitesmoke !important;
}

.appBar {
    background: rgb(48, 49, 52);
}

.transparent-card {
    background: rgba(255, 255, 255, 0.05) !important;
    color: white !important;
}

.full-height {
    height: 100%;
}

.page-container {
    height: 100%;
    max-width: 50%;
}

.landing-spacer {
    height: 9vh;
}

/* For mobile devices */
@media screen and (max-width: 500px) {
    .page-container {
        height: 100%;
        max-width: 90% !important;
    }
}
</style>
