<template>
    <section class="px-10">
        <h2 class="landing-sub-header">Satisfy All your Freight Questions</h2>
        <div class="grid-container">
            <div
                v-for="(panel, index) in panelContent"
                :key="index"
                class="redirect-panel">
                <h3 style="font-weight: 350; margin: 1rem">
                    {{ panel.title }}
                </h3>
                <v-btn class="redirect-button" @click="redirect(panel.pageName)"
                    >Learn More</v-btn
                >
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AdRedirectPanels',
    props: {
        current_page: {
            type: String,
            required: true
        }
    },
    methods: {
        redirect(pageName) {
            this.$router
                .push({
                    name: pageName
                })
                .catch(() => {});
        }
    },
    data: () => {
        return {
            panelContent: [],
            headerMap: {
                reefer_ad: 'Free Reefer Rates',
                dry_van_ad: 'Free Dry Van Rates',
                dot_lookup_ad: 'DOT Number Lookup',
                mc_lookup_ad: 'MC Number Lookup',
                carrier_search_ad: 'Carrier Lane Search'
            },
            orderMap: {
                reefer_ad: [
                    'dry_van_ad',
                    'dot_lookup_ad',
                    'carrier_search_ad',
                    'mc_lookup_ad'
                ],
                dry_van_ad: [
                    'reefer_ad',
                    'carrier_search_ad',
                    'dot_lookup_ad',
                    'mc_lookup_ad'
                ],
                dot_lookup_ad: [
                    'mc_lookup_ad',
                    'carrier_search_ad',
                    'dry_van_ad',
                    'reefer_ad'
                ],
                mc_lookup_ad: [
                    'dot_lookup_ad',
                    'carrier_search_ad',
                    'dry_van_ad',
                    'reefer_ad'
                ],
                carrier_search_ad: [
                    'dot_lookup_ad',
                    'mc_lookup_ad',
                    'dry_van_ad',
                    'reefer_ad'
                ]
            }
        };
    },
    beforeMount() {
        const panels = this.orderMap[this.current_page];
        this.panelContent = panels.map((pageName) => {
            return {
                title: this.headerMap[pageName],
                pageName: pageName
            };
        });
    }
};
</script>

<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    column-gap: 2rem;
}
.redirect-panel {
    display: flex;
    max-width: 65%;
    width: 65%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    margin: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
}
.redirect-panel:nth-child(1),
.redirect-panel:nth-child(3) {
    justify-self: end;
}
.redirect-panel:nth-child(2),
.redirect-panel:nth-child(4) {
    justify-self: start;
}
.redirect-button {
    background-color: transparent !important;
    border: 1px solid #0d99e8;
    text-transform: none;
    font-size: 14px;
    font-weight: 300;
    margin: 1rem;
    height: fit-content !important;
}
.redirect-button ::v-deep .v-btn__content {
    padding: 0.7rem;
}

@media screen and (max-width: 520px) {
    .grid-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        row-gap: 0;
    }
    .redirect-panel:nth-child(1),
    .redirect-panel:nth-child(3) {
        justify-self: center;
    }
    .redirect-panel:nth-child(2),
    .redirect-panel:nth-child(4) {
        justify-self: center;
    }
    .redirect-panel {
        padding: 0.5rem;
        margin: 0.5rem;
    }
}

@media screen and (max-width: 1400px) {
    .redirect-panel {
        max-width: 80%;
        width: 80%;
    }
    .redirect-button {
        font-size: 16px;
        margin: 0.5rem;
    }
    .redirect-button ::v-deep .v-btn__content {
        padding: 0.4rem;
    }
}
</style>
