<template>
    <v-app>
        <v-main>
            <v-container class="d-flex justify-center align-center" fill-height>
                <v-card
                    class="mx-auto my-12 pa-10"
                    width="500px" :style="{padding: isMobile?'40px 15px !important':''}">
                    <v-overlay :value="loading" absolute>
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card-title class="mb-5">
                            <logo-svg-component
                                class="mr-3 mt-1"
                                width="7%"
                                height="7%"
                            ></logo-svg-component>
                            {{ this.google_sso || this.additional_info.state ? "Complete your FreightGPT signup!" : "Sign Up for FreightGPT!" }}
                    </v-card-title>

                    <!-- Initial sign up form -->
                    <template v-if="sign_up_initial_redirection && !additional_info.state">
                        <v-card-text>
                            <v-form>
                                <!-- <v-btn
                                    color="white"
                                    dark
                                    class="text-capitalize mb-5 px-2"
                                    style="width: 100%; display: flex"
                                    outlined
                                    @click="enterFullEmailSignUp"
                                >
                                    <v-icon left size="23"
                                        >mdi-email</v-icon
                                    >
                                    Sign Up with Email
                                </v-btn> -->
                                <GoogleSSOButton :onSignupForm="true" buttonPhrase="Sign Up with Google" />
                                <div class="container-section">
                                    <div class="left-line"></div>
                                    <div class="text">or</div>
                                    <div class="right-line"></div>
                                </div>
                                <v-form
                                    ref="signupForm"
                                    :value="formValid"
                                    @submit.prevent="checkAndSubmit"
                                    lazy-validation>
                                    <v-text-field
                                        v-model="signupEmail"
                                        label="Email *"
                                        outlined
                                        dark
                                        autofocus
                                        :dense="$vuetify.breakpoint.mobile"
                                        :rules="[rules.required, rules.email]"
                                        @focus="hadFocus['email'] = true"></v-text-field>
                                        <v-text-field
                                        v-model="signupName"
                                        label="Full Name *"
                                        ref="nameField"
                                        name="fullname"
                                        id="fullname"
                                        type="text"
                                        outlined
                                        dark
                                        :dense="$vuetify.breakpoint.mobile"
                                        :rules="[rules.required]"
                                        @focus="hadFocus['name'] = true"></v-text-field>
                                    <v-text-field
                                        v-model="signupPassword"
                                        label="Password *"
                                        outlined
                                        dark
                                        validate-on-blur
                                        :dense="$vuetify.breakpoint.mobile"
                                        :rules="[
                                            rules.required,
                                            rules.min,
                                            rules.max,
                                        ]"
                                        :type="showPwd ? 'text' : 'password'"
                                        @focus="hadFocus['pwd'] = true"
                                        @blur="checkPasswordMatch">
                                        <template #append>
                                            <v-icon
                                                tabindex="-1"
                                                icon
                                                @click="showPwd = !showPwd"
                                                >{{ showPwd ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
                                            >
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        v-model="confirmPassword"
                                        label="Confirm Password *"
                                        ref="confirmPasswordField"
                                        outlined
                                        dark
                                        :dense="$vuetify.breakpoint.mobile"
                                        :rules="[
                                            rules.required,
                                            rules.min,
                                            rules.max,
                                            rules.pwdMatch
                                        ]"
                                        :type="showPwd ? 'text' : 'password'"
                                        @focus="hadFocus['conf'] = true"
                                        @blur="checkPasswordMatch">
                                        <template #append>
                                            <v-icon
                                                tabindex="-1"
                                                icon
                                                @click="showPwd = !showPwd"
                                                >{{ showPwd ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon
                                            >
                                        </template>
                                    </v-text-field>


                                    <v-btn
                                        color="#348EFF"
                                        dark
                                        type="submit"
                                        :disabled="!isFormPopulated"
                                        class="text-capitalize"
                                        style="width: 100%">
                                        <div>
                                            {{
                                                'Sign Up'
                                            }}
                                        </div>
                                    </v-btn>
                                </v-form>
                            </v-form>
                        </v-card-text>
                        <div
                        style="width: 100%"
                        class="text-center d-flex align-center justify-center mt-10"
                    >
                        <span class="pl-1 link">
                            Already have an account?
                            <a @click="redirectToSignIn">Sign In</a>
                        </span>
                    </div>
                    </template>

                    <!-- Fill out additional info -->
                    <template v-else>
                        <v-card-text>
                            <SignUpFlow
                                @showConfirm="showConfirm"
                                @showLoading="showLoading"
                                :redirectGoogleSSO="google_sso"
                                :getAdditionalInfo="additional_info"
                                @setEmail="setEmail"
                                @setPassword="setPassword"
                                @setAdditionalInfo="setAdditionalInfo"
                                @updateSignupFormData="updateSignupFormData"
                                @sendMessageToQueue="sendMessageToQueue"
                            />
                        </v-card-text>
                    </template>

                    <!-- <template v-else>
                        <v-card-text class="text-s1 mt-n2 pb-2">
                            Enter the six digit code sent to {{ signupEmail }}. It
                            may be in your spam folder.
                        </v-card-text>
                        <v-card-actions style="flex-direction: column">
                            <div>
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit1"
                                    maxlength="1"
                                />
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit2"
                                    maxlength="1"
                                />
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit3"
                                    maxlength="1"
                                />
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit4"
                                    maxlength="1"
                                />
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit5"
                                    maxlength="1"
                                />
                                <input
                                    type="text"
                                    class="confirmDigit"
                                    v-model="digit6"
                                    maxlength="1"
                                />
                            </div>
                            <v-btn
                                @click="confirmUser"
                                color="#348EFF"
                                dark
                                style="
                                    margin-top: 1rem;
                                    width: 100%;
                                    display: flex;
                                "
                            >
                                Verify
                            </v-btn>
                        </v-card-actions>
                    </template> -->
                </v-card>
                <v-dialog v-model="dialog" width="28rem">
                <v-card dark :color="dialogColor">
                    <v-card-title class="headline pb-0"
                        >{{ dialogTitle }} <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="text-s1 pt-2 pb-6">
                        {{ dialogMessage }}
                    </v-card-text>
                </v-card>
            </v-dialog>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import Vue from 'vue';
import GoogleSSOButton from './landing/GoogleSSOButton.vue';
import LogoSvgComponent from '../components/LogoSVG.vue';
import SignUpFlow from '../components/SignUpFlow.vue';
import * as auth from '../authentication/amplify-auth';
import * as analytics from '../analytics/pinpoint';
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
export default Vue.extend({
    name: 'LoginIntermediary',
    props: {
        signUpInitialRedirection: {
            type: String,
            default: ''
        },
        skipToForm: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LogoSvgComponent,
        GoogleSSOButton,
        SignUpFlow
    },

    beforeMount() {
        if (this.skipToForm) {
            this.enterFullEmailSignUp();
        }
    },

    mounted() {
        analytics.sendEvent('signup_form_view');
        if (this.sign_up_initial_redirection) {
            this.$gtag.event('sign_up_start', {
                send_to: 'AW-16494424913/wDDcCJLHzs4ZENHmk7k9',
                event_category: 'engagement',
                event_label: 'new_user_confirmed',
                value: '1'
            });
        }
    },

    computed: {
        confirmationCode() {
            return (
                this.digit1 +
                this.digit2 +
                this.digit3 +
                this.digit4 +
                this.digit5 +
                this.digit6
            );
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        isFormPopulated() {
            if (
                this.signupEmail != '' &&
                this.signupName != '' &&
                this.signupPassword != '' &&
                this.confirmPassword != ''
            ) {
                return true;
            }

            return false;
        }
    },

    methods: {
        checkPasswordMatch(){
            this.$refs.confirmPasswordField.validate();
        },
        setEmail(val) {
            this.signupEmail = val;
        },
        checkAndSubmit() {
            this.formValid = this.$refs.signupForm.validate();
            if (this.formValid) {
                this.submitSignupForm();
            }
        },
        async submitSignupForm() {
            this.$store.commit('setStateProperty', {
                property: 'name',
                value: null
            });
            this.$store.commit('setStateProperty', {
                property: 'email',
                value: null
            });
            this.$store.commit('setStateProperty', {
                property: 'googleSSO',
                value: null
            });
            this.google_sso = false

            this.additional_info = {
                name: this.signupName.trim(), // Required - the username to sign up
                password: this.signupPassword, // Required - the user's password
                email:this.signupEmail.trim(),
                state:true
            };
        },
        setAdditionalInfo(val,error=null) {
            this.additional_info.state = val;
            if(error){
                this.showDialog(
                    `${this.signupEmail}: ${error.message}`,
                    'We encountered an issue.',
                    '#37474F'
                );
            }
        },
        setPassword(val) {
            this.signupPassword = val;
        },

        enterFullEmailSignUp() {
            // this.$gtag.event('sign_up_click', {
            //         event_category: 'engagement',
            //         event_label: 'new_user',
            //         value: '1'
            //     });
            this.google_sso = false;
            this.sign_up_initial_redirection = false;
        },

        showLoading(val) {
            this.loading = val;
        },

        redirectToSignIn(){
            this.$router.push({
                    name: 'login',
                });
        },

        async showConfirm() {
            // this.confirm = val;
            // this.$nextTick(() => {
            //     document.querySelectorAll('.confirmDigit').forEach((input, index, array) => {
            //         input.addEventListener('keyup', (event) => {
            //             if (event.key !== 'Backspace' && input.value) {
            //                 if (index < array.length - 1) {
            //                     array[index + 1].focus();
            //                 }
            //             }
            //             if (event.key === 'Backspace' && !input.value && index > 0) {
            //                 array[index - 1].focus();
            //             }
            //         });
            //         input.addEventListener('paste', (event) => {
            //             const pasteData = event.clipboardData.getData('text');
            //             this.fillConfirmationDigits(pasteData);
            //             event.preventDefault();
            //         });
            //         input.addEventListener('input', (event) => {
            //             const value = event.target.value;
            //             if (!/^\d*$/.test(value)) {
            //                 event.target.value = value.replace(/\D/g, '');
            //             }
            //         });
            //         input.addEventListener('keydown', (event) => {
            //             if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
            //                 if (!(event.ctrlKey && (event.key === 'v' || event.key === 'V'))) {
            //                     event.preventDefault();
            //                 }
            //             }
            //         });
            //     });
            // });
            this.loading = true;
            let user = null;
            try {
                user = await auth.signIn(
                    this.signupEmail,
                    this.signupPassword
                ).then(() => {
                    this.sendMessageToQueue(this.signupFormData)
                });

                const sourcePage = ('sourcePage' in this.$store.state) ? this.$store.state['sourcePage'] : null;
                if (sourcePage != null) {
                    analytics.sendEvent('signup_' + sourcePage);
                }

                // analytics.sendEvent(
                //     'signup_verified',
                //     user.attributes.sub,
                //     user.attributes.name,
                //     user.attributes.email
                // );

                this.$gtag.event('sign_up_confirmed', {
                        send_to: 'AW-16494424913/kAvvCLaSrbgZENHmk7k9',
                        event_category: 'engagement',
                        event_label: 'new_user_confirmed',
                        value: '1'
                    });
                // Navigate to chat
                this.$router.push({
                    name: 'chat',
                    user_info: user
                });
            } catch (error) {
                this.loading=false
                console.log(
                    'An error occurred during automatic sign in.',
                    error
                );
            }

        },

        showDialog(message, title = 'Notice', color = 'white') {
            this.dialogMessage = message;
            this.dialogTitle = title;
            this.dialogColor = color;
            this.dialog = true;
        },
        closeDialog() {
            this.dialogMessage = null;
            this.dialogTitle = null;
            this.dialogColor = null;
            this.dialog = false;
        },
        async sendMessageToQueue(formFields){

        // Create an SQS client
        const clientConfig = {
            region: 'us-east-1', // Specify your desired AWS region
            credentials: {
                accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID, // Replace with your AWS access key ID
                secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY, // Replace with your AWS secret access key
            },
            };

        // Create an SQS client instance
        const sqsClient = new SQSClient(clientConfig);

        // Define the queue URL
        const queueUrl = 'https://sqs.us-east-1.amazonaws.com/903881895532/SignupRegisterQueue.fifo';

        const message = {
            MessageBody: JSON.stringify(formFields),
            MessageGroupId : 'post_signup_confirmation',
            MessageDeduplicationId: new Date().getTime(),
            QueueUrl: queueUrl
        };

        // Function to send a message to SQS
        const sendMessage = async () => {
        try {
            const command = new SendMessageCommand(message);
            const response = await sqsClient.send(command);
            if('url' in this.$store.state){
                delete this.$store.state['url'];
            }
            console.log("Message sent successfully:", response.MessageId);
        } catch (error) {
            console.error("Error sending message:", error);
        }
        };

        // Call the function to send a message
        sendMessage();
        },
        updateSignupFormData(val){
            this.signupFormData = val
        },
        async confirmUser() {
            this.loading = true;
            try{
                await auth.confirmSignUp(
                this.signupEmail,
                this.confirmationCode
                ).then(()=>{
                    this.sendMessageToQueue(this.signupFormData)
                });
                // Once user signup is confirmed, send conversion event
                const sourcePage = ('sourcePage' in this.$store.state) ? this.$store.state['sourcePage'] : null;
                if (sourcePage != null) {
                    analytics.sendEvent('signup_' + sourcePage);
                }
                // const storedUrl = ('url' in this.$store.state) ? this.$store.state['url'] : null;
                // if (storedUrl && storedUrl.includes('about')) {
                //     this.$gtag.event('adpageSignup', {
                //         send_to: 'AW-16494424913/kAvvCLaSrbgZENHmk7k9',
                //         value: 1.0,
                //         currency: 'USD'
                //     });
                // }
            }
            catch(error){
                this.loading=false
                this.showDialog(
                    error.message,
                    'Error',
                    '#37474F'
                );
            }
            let user = null;
            try {
                user = await auth.signIn(
                    this.signupEmail,
                    this.signupPassword
                );
                analytics.sendEvent(
                    'signup_verified',
                    user.attributes.sub,
                    user.attributes.name,
                    user.attributes.email
                );
                this.$gtag.event('sign_up_confirmed', {
                        send_to: 'AW-16494424913/kAvvCLaSrbgZENHmk7k9',
                        event_category: 'engagement',
                        event_label: 'new_user_confirmed',
                        value: '1'
                    });
                // Navigate to chat
                this.$router.push({
                    name: 'chat',
                    user_info: user
                });
            } catch (error) {
                this.loading=false
                console.log(
                    'An error occurred during automatic sign in.',
                    error
                );
            }
        },
        fillConfirmationDigits(pasteString) {
        const digits = pasteString.replace(/\D/g, '').slice(0, 6);
        for (let i = 0; i < digits.length; i++) {
            this[`digit${i + 1}`] = digits[i];
        }
        const nextInputIndex = digits.length < 6 ? digits.length : 5;
        this.$nextTick(() => {
            document.querySelectorAll('.confirmDigit')[nextInputIndex].focus();
        });
    },

    },

    data: function () {
        return {
            sign_up_initial_redirection: !(this.signUpInitialRedirection === ''),
            google_sso: ('googleSSO' in this.$store.state) ? this.$store.state['googleSSO'] : null,
            additional_info: {
                name:'',
                email:'',
                password:'',
                state:false
            },
            signupFormData:{},
            loading: false,
            confirm: false,
            formValid: false,
            showPwd: false,
            signupEmail: "",
            signupName: '',
            signupPassword: "",
            confirmPassword: "",
            hadFocus: {
                email: false,
                name:false,
                pwd: false,
                conf: false,
            },
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: '',
            dialog: false,
            dialogMessage: '',
            dialogTitle: '',
            dialogColor: 'white',

            rules: {
                required: (v) => {
                    return !!v || 'This field cannot be empty';
                },
                min: (v) => v.length >= 8 || 'Min 8 characters',
                max: (v) => v.length <= 20 || 'Max 20 characters',
                pwdMatch: () =>
                    this.confirmPassword === '' ||
                    this.signupPassword === this.confirmPassword ||
                    `The passwords you entered don't match`,
                email: (v) => {
                    // Validates that the input string follows the email format: local part with
                    // alphanumeric and special characters, an '@' symbol, and a valid domain.
                    const regex = /^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
                    if (regex.test(v)) {
                        return true;
                    } else {
                        return 'E-mail is not valid';
                    }
                },
            }
        };
    }
});
</script>
<style scoped>
::v-deep .v-icon {
    margin-left: 0 !important;
}

.container-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 0px;
}
.left-line {
    flex: 1;
    height: 1px;
    background-color: #8f8f8f;
    margin: 0 10px 0 0;
}
.right-line {
    flex: 1;
    height: 1px;
    background-color: #8f8f8f;
    margin: 0 0 0 10px;
}
.text {
    white-space: nowrap;
}
</style>
