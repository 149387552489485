<template>
    <v-app class="landing-app-class">
        <AppBar></AppBar>
        <v-main class="white--text">
            <v-container
                fluid
                class="pb-0 main-container landing-page-container">
                <h1 class="text-h2 text-center pt-10">
                    Meet your ultimate AI freight assistant
                </h1>
                <v-row class="pt-10 pb-16" justify="center">
                    <SignUpButton />
                </v-row>
                <v-row
                    justify="center"
                    class="pt-xl-16 pt-lg-16 pt-md-16 pt-sm-16 pb-16"
                    style="height: 60px">
                    <div class="rounded pa-3" :style="demoChatField">
                        <div class="rounded typing-container-wrapper">
                            <vue-typed-js
                                :strings="phrases"
                                :loop="true"
                                :showCursor="false"
                                :contentType="'null'"
                                :backDelay="2500"
                                :typeSpeed="20">
                                <v-row align="center" class="typing-container">
                                    <v-col cols="12">
                                        <span class="typing typing-span"></span>
                                    </v-col>
                                </v-row>
                            </vue-typed-js>
                        </div>
                    </div>
                </v-row>

                <h2
                    class="text-h3 white--text pb-6 mt-12"
                    style="padding-top: 10% !important">
                    Features
                </h2>
                <p class="pb-4">
                    Data driven answers to all your freight questions - within
                    seconds.
                </p>
                <v-row>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-card class="transparent-card full-height">
                            <v-avatar
                                :size="$vuetify.breakpoint.smAndDown ? 26 : 40"
                                tile
                                class="mt-3 ml-3">
                                <v-icon x-large color="#00E49F"
                                    >mdi-currency-usd</v-icon
                                >
                            </v-avatar>
                            <v-card-title style="word-break: break-word">
                                Freight trucking rates
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Get accurate dry van or reefer rates for any
                                lane using FreightGPT’s pricing technology.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-card class="transparent-card full-height">
                            <v-avatar
                                :size="$vuetify.breakpoint.smAndDown ? 26 : 40"
                                tile
                                class="mt-3 ml-3">
                                <img
                                    src="../../assets/delivered-icon.png"
                                    alt="truck icon with check mark" />
                            </v-avatar>
                            <v-card-title style="word-break: break-word">
                                Search for carriers
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Discover new carriers by lane or lookup by
                                DOT/MC number for detailed carrier insights.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-card class="transparent-card full-height">
                            <v-avatar
                                :size="$vuetify.breakpoint.smAndDown ? 26 : 40"
                                tile
                                class="mt-3 ml-3">
                                <img
                                    src="../../assets/delivery-time-icon.png"
                                    alt="timer" />
                            </v-avatar>
                            <v-card-title style="word-break: break-word">
                                Compute transit times
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Accurately plan for loading and unloading by
                                computing total transit time, accounting for
                                stops
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <v-card class="transparent-card full-height">
                            <v-avatar
                                :size="$vuetify.breakpoint.smAndDown ? 26 : 40"
                                tile
                                class="mt-3 ml-3">
                                <img
                                    src="../../assets/rainy-weather-icon.png"
                                    alt="umbrella with rain falling on it" />
                            </v-avatar>
                            <v-card-title style="word-break: break-word">
                                Be prepared for weather
                            </v-card-title>
                            <v-card-text style="color: white !important">
                                Check weather along your route to anticipate
                                storms and check for hazards
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row style="padding:35px; justify-content: center; width: 100%;">
                <div class="try-button-wrapper" >
                        <v-btn
                            dark
                            class="text-capitalize try-button"
                            text
                            color="#0366f9"
                            large
                            @click="
                                navigateToPage('dry_van_ad','landing_page')
                            "
                            ><span class="white--text">Must try!</span>
                        </v-btn>
                    </div>
                </v-row>
                <div class="text-h3" style="padding-top: 2% !important; display: flex; justify-content: space-between;">
                    <div style="padding-right: 10px;">How it works</div>
            </div>
                <v-row class="pt-10">
                    <v-timeline
                        align-top
                        :dense="$vuetify.breakpoint.smAndDown"
                        dark>
                        <v-timeline-item
                            color="#0366F9"
                            icon="mdi-source-branch"
                            fill-dot
                            left>
                            <v-card class="transparent-card" dark>
                                <v-card-title class="text-h6">
                                    Multiple data sources
                                </v-card-title>
                                <v-card-text>
                                    <p>
                                        FreightGPT integrates with multiple data
                                        sources to give you a comprehensive and
                                        accurate answer.
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item
                            color="#5833FC"
                            icon="mdi-brain"
                            fill-dot
                            right>
                            <v-card class="transparent-card" dark>
                                <v-card-title class="text-h6">
                                    Leverage AI
                                </v-card-title>
                                <v-card-text>
                                    <p>
                                        FreightGPT uses AI to efficiently parse
                                        through questions to identify the target
                                        keywords for correctly contstructing the
                                        desired response.
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item
                            color="#AD00FF"
                            icon="mdi-clock-fast"
                            fill-dot
                            left>
                            <v-card class="transparent-card" dark>
                                <v-card-title class="text-h6">
                                    Maximize Efficiency
                                </v-card-title>
                                <v-card-text>
                                    <p>
                                        FreightGPT provides a centralized
                                        platform on any device. It enables you
                                        to ask any freight question any time and
                                        any where. Whether its the weather along
                                        your route or the truck rate from SF to
                                        LA, FreightGPT has got you covered.
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-row>
            </v-container>
        </v-main>
        <FooterBar></FooterBar>
        <v-overlay
            v-model="isLoading"
            class="loadingOverlay"
            style="z-index: 11">
            <v-progress-circular indeterminate />
        </v-overlay>
    </v-app>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import * as analytics from '../../analytics/pinpoint';
import './landing-page-style.css';
import AppBar from '../../components/AppBar.vue';
import FooterBar from '../../components/Footer.vue';
import SignUpButton from '../../components/SignUpButton.vue';
import './landing-page-style.css';

export default defineComponent({
    name: 'LandingPage',

    metaInfo: {
        title: 'FreightGPT - AI Freight Quotes & DOT Number Lookup',
        link: [{ rel: 'canonical', href: 'https://www.freightgpt.com/' }],
        meta: window.location.href.includes('freightgpt.com')
            ? [{name: 'description', content: "Get instant freight quotes, comprehensive DOT number lookups, detailed weather along route and much more with Freight GPT."}]
            : [{ name: 'robots', content: 'noindex' }, {name: 'description', content: "Get instant freight quotes, comprehensive DOT number lookups, detailed weather along route and much more with Freight GPT."}]
    },

    components: {
        AppBar,
        FooterBar,
        SignUpButton
    },

    created() {
        // Add script for typeform when component created
        let script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        document.body.appendChild(script);
    },
    beforeMount() {
        // Force scroll to top when page is loaded
        window.scrollTo(0, 0);
        this.$store.commit('setStateProperty', {
                property: 'sourcePage',
                value: "landing_page"
            });
    },
    mounted() {
        analytics.landingPageVisit();
        document.addEventListener('DOMContentLoaded', function() {
        const tryButtonWrapper: Element | null = document.querySelector('.try-button-wrapper');

        if (tryButtonWrapper) { // Check if the element exists
            const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                tryButtonWrapper.classList.add('shake');

                // Remove the shake class after 2s
                setTimeout(() => {
                    tryButtonWrapper.classList.remove('shake');
                }, 2000);
                }
            });
            });

            observer.observe(tryButtonWrapper);
        }
        });

    },

    computed: {
        demoChatField() {
            let baseClass = {
                width: '70%',
                position: 'relative',
                background: 'linear-gradient(to right, #0366f9, #ad00ff)',
                'box-shadow': '0 0 5px 2px #454545'
            };

            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                case 'sm': {
                    baseClass.width = '90%';
                    return baseClass;
                }
                default:
                    return baseClass;
            }
        }
    },

    methods: {
        waitlistFormOpened() {
            setTimeout(() => {
                let overlay = document.querySelector('.tf-v1-slider');
                if (overlay) {
                    overlay.addEventListener('click', () => {
                        overlay?.remove();
                    });
                }
            }, 200);
        },
        navigateToPage(page: string, origin?: string) {
            const routeOptions: any = { name: page };
            if (origin) {
                routeOptions.query = { origin: origin };
            }
            this.$router
                .push(routeOptions)
                .catch(() => {});
        }
    },

    data: function () {
        return {
            phrases: [
                'What is the price from NYC to Chicago for a Dry Van?',
                'What is the weather like in Miami and St Louis right now?',
                'Does MC982322 have good safety scores?',
                'What is the current diesel price in Dallas?',
                'What is my estimated fuel cost to haul from Las Vegas to San Jose?',
                'What is the distance between SF and LA?',
                'Can you add a 10% margin to my request?',
                'What does code 116 mean on an international truck?',
                'Can you benchmark Stockton to LA for me? I am currently paying $1250',
                'Where are the rest stops in Topeka, KS?',
                'Can a semi drive in the left lane in Wisconsin?',
                'My driver was involved in a tow away accident, what do I need to know?',
                'Is Grand Rapids within 150 air miles to Elgin, IL?',
                'Can you give the rate in CPM and take out fuel at 43 cents a mile?'
            ],

            isLoading: false
        };
    }
});
</script>
<style scoped>
html,
body {
    overflow-y: auto !important;
    background: #07090e !important;
}

body {
    --page-end-x: '100wh';
    --page-end-y: '80vh';
    --left-offset: '0px';
    --top-percentage: '86%';
}

/* For mobile devices */
@media screen and (max-width: 600px) {
    .buttons {
        margin-left: 12px !important;
    }

    .main-container {
        height: 100%;
        width: 90%;
    }

    .landing-page-container {
        height: 100%;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 1600px) {
    .typing-container {
        height: 60px;
        overflow: hidden;
        height: auto !important;
    }

    .typing-span {
        white-space: pre-wrap;
        word-break: keep-all;
        padding-left: 0px !important;
        color: #cfcfcf !important;
    }
}

.v-text-field--outlined.v-input--is-focused fieldset {
    border-width: 0.8px !important;
}

.transparent-card {
    background: rgba(255, 255, 255, 0.05);
    color: white;
}

.full-height {
    height: 100%;
}

.typing-container-wrapper {
    background: #07090e;
    padding: 0.7rem;
    margin: -0.6rem;
}

.landing-page-container {
    height: 100%;
    max-width: 55%;
}

.typing-container {
    height: 60px;
    overflow: hidden;
}
.try-button-wrapper {
    max-width: fit-content;
    width: fit-content !important;
    height: fit-content !important;
    position: relative;
    background: linear-gradient(to left, #0366f9, #ad00ff);
    border-radius: 2%;
}
.shake {
  animation: shake 0.5s;
  animation-iteration-count: 4;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-3px); }
  50% { transform: translateX(3px); }
  75% { transform: translateX(-3px); }
}
.try-button {
    background: #07090e;
    color: whitesmoke;
    margin: 1.6px;
    font-size: 14px;
}
@media screen and (max-width: 600px) {
    .signup-button {
        padding: 0 4px !important;
    }
}
</style>
