export function formatDollars(dollars, fixed_point = 0, showCents = false, prefixSign = false) {
    if (dollars === undefined || dollars == null || isNaN(dollars)) return '--';
    dollars = parseFloat(dollars);

    // Regex to replace to add ',' every 3 characters.
    const dollarAmount = dollars.toFixed(fixed_point);
    var formatted = dollarAmount
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace('-', '');
    if (showCents == true && dollars !== 0) {
        if (formatted.includes('.')) {
            let split = formatted.split('.');
            split[1] = split[1].padEnd(2, '0');
            formatted = split.join('.');
        } else {
            formatted = formatted.concat('.00');
        }
    }
    if (prefixSign) {
        return parseFloat(dollars) < 0 ? '-' + formatted : formatted;
    }
    if (parseFloat(dollars) < 0) {
        return '-$' + formatted;
    } else {
        return '$' + formatted;
    }
}

export function convertStringToInt(str) {
    // Check if the input is not a string, return immediately
    if (typeof str !== 'string') {
        return str;
    }
    // Remove commas from the string
    let cleanString = str.replace(/,/g, '');
    let number = parseFloat(cleanString);
    return Math.floor(number);
}

export function responsiveTextSize(xs_fs, sm_fs, md_fs, lg_fs, def_fs) {
    const { xs, sm, md, lg } = this.$vuetify.breakpoint;

    if (xs) return { fontSize: `${xs_fs}px !important` };
    if (sm) return { fontSize: `${sm_fs}px !important` };
    if (md) return { fontSize: `${md_fs}px !important` };
    if (lg) return { fontSize: `${lg_fs}px !important` };
    return { fontSize: `${def_fs}px !important` }; // Default for 'xl' and above
}
