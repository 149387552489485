import axios from 'axios';
import aws4 from 'aws4';

export async function sendContactMessage(body: any) {
    const request = {
        host: 'gkiuzzt1h8.execute-api.us-east-1.amazonaws.com',
        method: 'POST',
        url: 'https://gkiuzzt1h8.execute-api.us-east-1.amazonaws.com/contactTest/contact',
        data: body
    };

    const signedRequest = aws4.sign(request, {
        // assumes user has authenticated and we have called
        // AWS.config.credentials.get to retrieve keys and
        // session tokens
        secretAccessKey: '',
        accessKeyId: ''
    }) as any;

    delete signedRequest?.headers['Host'];
    delete signedRequest?.headers['Content-Length'];
    const response = axios(signedRequest);

    return response;
}
