<template>
    <div
        :id="id"
        :class="dialog ? 'map-container-expanded' : 'windy-map'"
        @click="handleDocumentClick"
    >
        <span @click.stop>
            <span
                id="expandMapPlugin"
                class="leaflet-bar leaflet-control"
                :style="{
                    background: 'linear-gradient(to right, #027794, #93b4aa)',
                    height: !dialog ? '25px !important' : '36px !important'
                }"
            >
                <span :style="isMobile ? 'top: 2px;' : 'top: 30px;'">
                    <v-menu v-model="showDistanceList" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                icon
                                :style="{
                                    color: '#fefefe',
                                    'padding-bottom': dialog ? '0px' : '8px'
                                }"
                            >
                                <v-icon :size="dialog ? '' : '19'">{{
                                    showDistanceList
                                        ? 'mdi-map-legend'
                                        : 'mdi-map-legend'
                                }}</v-icon>
                            </v-btn>
                        </template>
                        <v-list
                            style="
                                padding: 0px;
                                margin: 0px;
                                max-height: 200px;
                                background-color: rgba(15, 15, 15, 0.75);
                            "
                        >
                            <v-list-item
                                class="list-class"
                                v-for="(item, index) in distanceArray"
                                :key="index"
                            >
                                <v-list-item-content
                                    @click="focusOnPolyline(index)"
                                    class="textDisplay center-content"
                                >
                                    <span>
                                        {{ item }}
                                    </span></v-list-item-content
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </span>
                <v-spacer></v-spacer>
                <span v-if="!isMobile">
                    <v-btn
                        icon
                        @click="expandMaps"
                        style="color: #fefefe; float: right"
                    >
                        <v-icon v-if="dialog" medium color="">
                            mdi-arrow-collapse
                        </v-icon>
                        <v-icon v-else size="19" color="" class="mt-n2">
                            mdi-arrow-expand
                        </v-icon>
                    </v-btn>
                </span>
            </span>
        </span>
        <div
            id="windy"
            ref="windyMap"
            :style="{ height: dialog ? '100%' : mapHeight }"
        ></div>
    </div>
</template>

<script>
/* global L */ // This tells your linter that L is a global variable

import * as decoder from './polyline_utils/decoder';

export default {
    name: 'WindyMap',
    props: {
        mapHeight: {
            type: String,
            default: '300px'
        },
        windyEvents: Array,
        id: {
            type: String,
            default: ''
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            leaflet_map: null,
            dialog: false,
            showDistanceList: false,
            ne_bounds: {},
            defaultOverlay: null,
            sw_bounds: {},
            windy_events: this.windyEvents,
            targetElement: null,
            broadcast: null,
            plugins_injected: true,
            curr_polylines: [],
            polyline_colors: {
                temp: '#f0eed1',
                wind: '#dbb324',
                pressure: '#006594'
            },
            overlay_type: 'temp',
            customOverlay: null,
            showcity: false,
            distanceArray: []
        };
    },

    beforeMount() {
        // Check if the key exists in the JSON object
        var curr_windy_el = document.getElementById('windy');
        if (curr_windy_el != null) {
            curr_windy_el.remove();
        }
    },

    mounted() {
        // 1. Select the target element.
        this.targetElement = document.getElementById('windy');

        // 2. Create an instance of the observer with a callback.
        // Removes logo from windy maps.
        // eslint-disable-next-line
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                // Check the addedNodes property if there's any new node added
                if (mutation.addedNodes.length) {
                    var logo = document.getElementById('logo');
                    logo.style.display = 'none';
                }
            }
        });

        // 3. Configure the observer.
        const config = { childList: true }; // We're only interested in child list changes

        // Start observing the target node
        observer.observe(this.targetElement, config);

        this.initWindyMap();
    },

    beforeDestroy() {
        this.broadcast.fire('closeAll', 'menu');
        this.broadcast.fire('rqstClose', 'menu');
        this.broadcast.fire('pluginClosed', 'menu');
    },

    methods: {
        handleDocumentClick(event) {
            let className = event.srcElement.classList.length
                ? event.srcElement.classList[0]
                : '';
            if (this.dialog && className == 'map-container-expanded') {
                this.expandMaps();
            }
        },
        renderPolyLinesAndFit() {
            var [coordinates, polylines, start_city, end_city, distanceArray] =
                decoder.coordinates_and_polylines_from_events(
                    this.windy_events
                );
            this.distance = distanceArray;
            this.renderPolyLineOnMap(polylines, start_city, end_city);
            this.fitMapToBounds(coordinates);
        },

        toggleDistanceList() {
            this.showDistanceList = !this.showDistanceList;
        },
        expandMaps() {
            this.dialog = !this.dialog;

            setTimeout(() => {
                // this.initWindyMap()
                // const mapContainer = this.leaflet_map.getContainer();

                // mapContainer.style.width = '100vw';
                // mapContainer.style.height = '100vh';
                this.leaflet_map.invalidateSize();
                if (document.getElementsByClassName('progress-line')[0]) {
                    document.getElementsByClassName(
                        'progress-line'
                    )[0].style.width = '90%';
                }
                this.renderPolyLinesAndFit();

                const container = document.getElementById(this.id);
                if (container) {
                    container.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    });
                }
            }, 100);
        },

        renderPolyLineOnMap(polylines, startCities, endCities) {
            this.distanceArray = [];
            for (let i = 0; i < polylines.length; i++) {
                const pl = polylines[i];
                const startCity = startCities[i];
                const endCity = endCities[i];
                this.distanceArray.push(`${startCity} to ${endCity}`);

                const latlngs = decoder.decodePolyline(pl);
                const polyline = L.polyline(latlngs, {
                    opacity: 0,
                    weight: 7
                }).addTo(this.leaflet_map);
                const displayPoly = L.polyline(latlngs, {
                    color: this.polyline_colors[this.overlay_type],
                    weight: this.isMobile ? 5 : 4
                }).addTo(this.leaflet_map);
                this.curr_polylines.push(polyline);
                this.curr_polylines.push(displayPoly);

                polyline.on('click', (event) => {
                    const position = event.latlng;
                    this.createDefaultOverlay(
                        position,
                        this.distanceArray[i],
                        `${this.distance[i]} miles`
                    );
                });

                polyline.on('mouseover', (event) => {
                    const position = event.latlng;
                    this.createDefaultOverlay(
                        position,
                        this.distanceArray[i],
                        `${this.distance[i]} miles`
                    );
                });
                polyline.on('mouseout', () => {
                    setTimeout(() => {
                        this.removeDefaultOverlay();
                    }, 1000);
                });

                const iconOptions = {
                    radius: 5,
                    fillColor: this.polyline_colors[this.overlay_type],
                    color: '#000',
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 1
                };
                const startMarker = L.circleMarker(
                    latlngs[0],
                    iconOptions
                ).addTo(this.leaflet_map);
                const endMarker = L.circleMarker(
                    latlngs[latlngs.length - 1],
                    iconOptions
                ).addTo(this.leaflet_map);

                startMarker.on('click', () => {
                    const startPosition = L.latLng(
                        latlngs[0].lat,
                        latlngs[0].lng
                    );
                    this.createDefaultOverlay(startPosition, startCity, '');
                });

                endMarker.on('click', () => {
                    const endPosition = L.latLng(
                        latlngs[latlngs.length - 1].lat,
                        latlngs[latlngs.length - 1].lng
                    );
                    this.createDefaultOverlay(endPosition, endCity, '');
                });
            }
        },
        focusOnPolyline(index) {
            const polyline = this.curr_polylines[index];

            // Check if polyline exists
            if (polyline) {
                // Get the bounds of the polyline
                const bounds = polyline.getBounds();
                // Check if bounds exist and are valid
                if (bounds.isValid()) {
                    // Fit the map to the bounds of the polyline
                    this.leaflet_map.fitBounds(bounds);
                } else {
                    console.error('Invalid bounds for polyline:', bounds);
                }
            } else {
                console.error('Invalid polyline at index:', index);
            }
        },
        createDefaultOverlay(position, title, content) {
            // Remove any existing default overlay
            this.removeDefaultOverlay();

            // Create a Leaflet popup at the specified position with the content
            const popup = L.popup()
                .setLatLng(position)
                .setContent(content)
                .openOn(this.leaflet_map);
            popup._container.innerHTML = `<div style="width:100px; font-size:10px; color:white; background-color: rgba(15,15,15,0.75);padding: 10px;padding-bottom: 1px;"">
                <h3>${title}</h3>
                <p style="margin-top:5px">${content}</p>
                </div>`;
            if (
                document.getElementsByClassName(
                    'leaflet-popup  leaflet-zoom-animated'
                ).length
            ) {
                document.getElementsByClassName(
                    'leaflet-popup  leaflet-zoom-animated'
                )[0].style.left = 0;
                document.getElementsByClassName(
                    'leaflet-popup  leaflet-zoom-animated'
                )[0].style.bottom = 0;
                document.getElementsByClassName(
                    'leaflet-popup  leaflet-zoom-animated'
                )[0].style.margin = 0;
            }

            // Store the popup reference
            this.defaultOverlay = popup;
        },

        removeDefaultOverlay() {
            // Check if a default overlay exists
            if (this.defaultOverlay) {
                // Close and remove the popup from the map
                this.defaultOverlay.remove();
                // Reset the default overlay reference
                this.defaultOverlay = null;
            }
        },
        handleMapClick() {
            // Remove the default overlay if it exists
            this.removeDefaultOverlay();
        },
        showCityOverlay(cityName, latlngs) {
            const position = L.latLng(latlngs);
            L.popup()
                .setLatLng(position)
                .setContent(
                    `<div style="background-color: rgba(15,15,15,0.75); padding: 10px; padding-bottom:1px;">
                <p style="margin-top:5px">${cityName}</p>
                </div>`
                )
                .openOn(this.leaflet_map);
        },

        fitMapToBounds(coordinates) {
            var global_bounds = decoder.compute_bounds_from_list(coordinates);
            var northEast = L.latLng(global_bounds.ne[0], global_bounds.ne[1]),
                southWest = L.latLng(global_bounds.sw[0], global_bounds.sw[1]),
                bounds = L.latLngBounds(southWest, northEast);

            // zoom the map to the polyline
            this.leaflet_map.fitBounds(bounds, {
                padding: [50, 50] // Adds padding around the bounds
            });
        },

        setPolyLineColor(overlay_type) {
            for (let pl of this.curr_polylines) {
                pl.setStyle({
                    color: this.polyline_colors[overlay_type]
                });
            }
        },

        initWindyMap() {
            const options = {
                key: process.env.VUE_APP_WINDY_MAPS_KEY,
                overlay: this.overlay_type
            };

            // Access windyInit from window object
            if (typeof window.windyInit === 'function') {
                window.windyInit(options, (windyAPI) => {
                    const { broadcast } = windyAPI;
                    this.broadcast = broadcast;

                    // Set polyline color on change.
                    broadcast.on('paramsChanged', (params) => {
                        if ('overlay' in params) {
                            this.overlay_type = params.overlay;
                            this.setPolyLineColor(this.overlay_type);
                        }
                    });

                    this.leaflet_map = windyAPI.map;
                    this.renderPolyLinesAndFit();

                    this.leaflet_map.on('zoomend', () => {
                        if (
                            document.getElementsByClassName(
                                'leaflet-popup  leaflet-zoom-animated'
                            ).length
                        ) {
                            document.getElementsByClassName(
                                'leaflet-popup  leaflet-zoom-animated'
                            )[0].style.left = 0;
                            document.getElementsByClassName(
                                'leaflet-popup  leaflet-zoom-animated'
                            )[0].style.bottom = 0;
                            document.getElementsByClassName(
                                'leaflet-popup  leaflet-zoom-animated'
                            )[0].style.margin = 0;
                        }
                        // Handle zoom change
                    });

                    // Create plugin observer
                    const pluginobserver = new MutationObserver(
                        // eslint-disable-next-line
                        (mutationsList, observer) => {
                            for (let mutation of mutationsList) {
                                // Check the addedNodes property if there's any new node added
                                if (mutation.addedNodes.length) {
                                    if (this.plugins_injected) {
                                        this.plugins_injected = false;
                                    } else {
                                        for (let added_plugin of mutation.addedNodes) {
                                            added_plugin.remove();
                                        }
                                    }
                                }
                            }
                        }
                    );

                    var plugins = document.getElementById('plugins');
                    const config = { childList: true }; // We're only interested in child list changes
                    pluginobserver.observe(plugins, config);

                    // Override error on plugin injection
                    window.W.define = function n(e, n, i, o, r) {
                        window.W.modules[e] = {
                            name: e,
                            dependencies: n,
                            callback: i,
                            wasLoaded: !1,
                            html: o,
                            css: r
                        };
                    };
                });
            }
        }
    },
    watch: {
        overlay_type(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.renderPolyLinesAndFit();
            }
        }
    }
};
</script>

<style scoped>
.windy-map {
    width: 100%;
    /* height: 300px; */
    z-index: 1;
    display: grid;
}

.map-container-expanded {
    height: 100vh !important;
    width: 100vw;
    position: fixed !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 111;
    border: 80px solid rgba(255, 255, 255, 0.5);
    outline: 10px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.hover-div {
    position: absolute;
    left: 40px;
    background-color: transparent;
    /* Or any color you wish */
    z-index: 111;
    /* Make sure this is above the map's z-index */
    /* More styling for your div */
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* Space between legend items */
    cursor: pointer;
}

.expand-icon {
    position: absolute;
    top: 0px;
    right: 4px;
    background-color: transparent;
    z-index: 111;
}

.center-content {
    display: flex;
    align-items: center;
}

.list-class {
    display: flex;
    align-items: center;
    color: white;
    background-color: rgba(15, 15, 15, 0.75);
}

.textDisplay {
    display: block;
    cursor: pointer;
    overflow: auto;
    color: white;
}

#expandMapPlugin {
    display: flex;
    z-index: 110;
}

#threeDotsPlugin {
    z-index: 111;
}
.v-btn:before {
    background-color: transparent !important;
}
</style>
