export const privacy_markdown = `

# PRIVACY POLICY

**Last Updated: April 8, 2024**

Thank you for visiting [https://www.truce.io/](https://www.truce.io/) (the “Site”) which is operated by Truce Tech Inc. (“Truce” “we” “us” or “our”). This Privacy Policy (the “Policy”) describes what personal information we and our affiliates collect about you, how we collect it, how we use it and share it, and what choices you have regarding it. This Policy applies to personal information we collect about you when you visit the Site, as well as other data that shippers and brokers provide when using our Truce Transparency Plat...

This Policy is governed by and made a part of our Terms of Use [https://www.truce.io/terms-of-use](https://www.truce.io/terms-of-use) (the “Terms”). We encourage you to review the Terms, as well as this Policy periodically, and make sure that you understand the content of each. This Policy may be updated periodically and without prior notice to you to reflect changes in our privacy practices. You should review this Policy periodically so that you keep up to date on our most current policies and practices.

Truce provides a neutral platform where shippers and brokers exchange real-time cost and execution data to increase efficiency. This Policy does not cover or address: the privacy practices of the shippers and brokers (including their authorized licensees) or our privacy practices relating to the personal information of job applicants, employees, agents, and other personnel.

## Personal Information We Collect
We collect the following categories and types of personal information:
- **Contact Information:** your first and last name, postal address, email address, and phone number;
- **Other identifying information:** IP address, passwords, and other security information for authentication and access, written content you provide when writing a review, social media usernames, and cookie identifiers;
- **Financial Information:** credit card, debit card, and bank account information when purchasing products from us;
- **Demographic information:** gender and age;
- **Internet or other electronic activity:** your browsing and click history, including information about how you navigate within the Services;
- **Commercial information:** products purchased or viewed within the Services;
- **Audio and visual information:** your videos and photos that you have uploaded to the Services, and call recordings when you call customer services; and
- **Inferences drawn from the categories described above** in order to create a profile about you to reflect your preferences, characteristics, behavior, and attitude.

\u200B
## Why We Collect Personal Information
We may use or may have used the personal information we have about you in the following ways
- **Transactional Purposes:** We use your contact information, financial information, and commercial information to:
  - Set up your account with us;
  - Receive, process, confirm, send, and track your order;
  - Communicate with you about your order;
- **Analytical Purposes:** We use your other identifying information, internet activity, and browsing history, commercial information, and demographic information to analyze preferences, trends, and statistics.
- **Marketing and Promotional Purposes:** We use your contact information, commercial information, demographic information, internet or other electronic activity, and inferences to:
  - Inform you of our new products, services, and offers;
  - Provide you with and measure and optimize targeted advertising;
  - Provide you with other information from and about Truce, including personalized marketing communications.
- **Maintenance and Improvement of Services and Website:** We use your contact information, commercial information, and internet activity and browsing history to:
  - Provide and maintain functionality of the Services, including our customer feedback and review features;
  - Handle your customer services requests; and
  - Help us diagnose technical and service problems and administer the Services.
- **Review and Content Creation Purposes:** We use your contact information, commercial information, and audio and visual information to enable reviews of the restaurants we partner with, and to display content that you have created and allowed us to display on our Services and on social media and other advertising.
- **Security and Fraud Prevention:** We use your contact information, other identifying information, commercial information, financial information, internet activity and browsing history, audio and visual information, and inferences to protect the Site, our company, and others, and to prevent fraud, theft, and misconduct.

\u200B
## How We Collect Personal Information
We may collect or may have collected personal information about you from various sources.
- **We collect information directly from you:** We collect contact information, demographic information, financial information, and audio and visual information directly from you.
- **From your friends or family members:** We may collect contact information from your friends or family member when they tag you on a post to one of our social media pages.
- **Through other technologies:** We collect audio and visual information such as when you call our customer support.
- **From our business partners and service providers:** We may collect contact information, demographic information, commercial information, and internet and electronic activity from demographic companies, analytics providers, advertising companies and networks, third-party retailers, and other third parties that we choose to collaborate or work with.
- **From our affiliates:**  We may obtain your contact information and other identifying information from affiliates that you have interacted with.

\u200B
We collect information from you passively. We collect Internet or other electronic activity passively using tools like browser cookies. This activity is further described below.

**Information Collected from Shippers and Brokers Using Our Services.** Users of our services may be requested to provide data fields including but not limited to Broker and Shipper Name and Primary Reference, Customer Name, Origin and Destination Information, execution timestamps, and data related to revenue and cost of goods sold (“User Data”). The use, storage, return and destruction of this User Data will be governed by the Data Security and Integrity provisions of our Terms of Use [https://www.truce.io/terms-of-use](https://www.truce.io/terms-of-use).

**Combining Information.**  We may combine information you give us online or you submit through the platform. We may also combine that information with publicly available information and information we receive from or cross-reference with our business partners and service providers. You authorize Truce to use this combined User Data in an anonymized, de-identified or aggregated manner to build new products and features, enhance the Services we provide to you, and for other promotional and commercial purposes.
You agree and represent that you have reviewed and are subject to our Terms of Use, which describes, among other things, Truce’s use of User Data (SEE DATA SECURITY AND INTEGRITY SECTION OF TERMS OF USE).

**With Whom We Share Your Personal Information.**  In general, we do not share personal information about you with third parties for such third-parties’ own marketing or advertising purposes. We do share personal information with third parties for other purposes. For example, we share personal information with:

- **Our Service Providers:**  We may contract with companies or persons to provide certain services including credit card processing, shipping, data analysis and management. We call them our Service Providers. We provide our Service Providers with the information needed for them to perform these services. We also ask our Service Providers to confirm that their privacy practices are consistent with ours.
- **Our Affiliates:**  We may share personal information with businesses controlling, controlled by, or under common control with Truce.
- **Corporate Transactions:**  We may share personal information with parties to business transactions such as those we deal with in mergers, acquisitions, joint ventures, sales of assets, reorganizations, divestitures, dissolutions, bankruptcies, liquidations, or other types of business transactions. In these types of transactions, personal information may be shared, sold, or transferred, and it may be used subsequently by a third party.
- **Law Enforcement and Courts:**  In certain instances we may disclose your contact information when we have reason to believe that it is necessary to identify, contact or bring legal action against persons or entities who may be causing injury to you, to Truce or to others. We may also disclose your contact information when we believe the law or legal process requires it.

\u200B
**Cookies.**  When you visit the Site, we or a third-party company may send one or more cookies to your computer or other device. We may also use cookies and other similar technologies in emails that you receive from us. A cookie is a small data file that is placed on the hard drive of your computer when you visit a website. A session cookie expires immediately when you end your session (i.e., close your browser). A persistent cookie stores information on the hard drive so when you end your session and return to the same website at a later date the cookie information is still available. We use cookies to improve the quality of the Services. We also use cookies to identify who you are, tailor measure and optimize the Services and advertising to suit the personal interests of you and others, estimate our audience size, assist our online merchants to track visits to and sales through our Services and to process your order, track your order, and/or analyze your visiting and email interaction patterns.  If you would like to opt out of accepting cookies altogether, you can generally set your browser to not accept cookies or to notify you when you are sent a cookie, giving you the chance to decide whether or not to accept it. However, certain features of our web sites or other Services may not work if you delete or disable cookies.

**Advertising and Online Tracking.**  We may allow third-party companies to serve ads and/or collect certain information when you visit the Site. These companies may use information (e.g., click stream information, browser type, time and date, subject of advertisements clicked or scrolled over) collected during your visits to this and other websites in order to provide advertisements about goods and services likely to be of interest to you. These companies may also tie identifiers associated with a particular browser or device to identifiers associated with other browsers or devices used by the same user or household (e.g., a device identifier associated with a user's computer may be tied to the device identifiers of that user's tablet and phone) in order to measure and target advertisements tailored to user interests across their devices and to send personalized marketing communications. These companies typically use a cookie or third-party web beacon to collect this information. To learn more or opt out from these companies you can visit [http://www.networkadvertising.org/choices](http://www.networkadvertising.org/choices) and [https://www.aboutads.info/](https://www.aboutads.info)  we also provide you with additional tools to opt out of marketing from us or certain transfers of your information. You can learn about this in the “Opting Out of Mailings” section immediately below.

**Mobile Applications.**  Depending on your permissions, we may receive your personal information from your Internet service and mobile device providers. Users of mobile devices who do not want to receive interest-based advertising may opt-out in several ways. Learn more about your choices for mobile devices by visiting [https://www.aboutads.info/appchoices](https://www.aboutads.info/appchoices). Each operating system, (iOS for Apple phones, Android for Android devices, and Windows for Microsoft devices) provides its own instructions on how to prevent the delivery of tailored in-application advertisements. You should view your device or system “Settings” to determine how you can opt out of use of your device ID for “cross-app” personalized advertising.

**Opting Out of Mailings.**  The Site provides you with several opportunities to agree to receive communications from Truce, and any of our affiliated brands or divisions. If at any time you wish to stop receiving communications from us please send us an email to contact@truce.io with the phrase “Privacy Opt-out: Truce Mailings” in the subject line, or write to us at the address provided below, and we will remove you from our mailing list. Alternatively, for e-mail communications, you may opt out of receiving such communications by following the unsubscribe instructions set forth at the bottom of our promotional e-mail messages.

**Third-Party Sites.**  In some instances, the Services or our website may link to third-party websites and services that are not controlled by us. We are not responsible for the privacy practices of those websites or services, and your use of them is at your sole discretion and risk. We recommend that you review the privacy policies and practices of any such third party.

**Information Security.**  Truce takes commercially reasonable security and backup measures to protect the information submitted to us. However, no method of transmission over the Internet or method of electronic storage and security is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. We encourage all of our customers and partners to retain copies of all uploaded information on their own systems. Additionally, if you contact us by email, you should be aware that email transmissions might not be secure. Information you send by email could be viewed in transit by a third party. We will have no liability for disclosure of your information due to errors or unauthorized acts of third parties during transmission.

**Children’s Personal Information.**  The Truce Services are not directed to children under the age of 13 and we do not knowingly collect any personal information from children under the age of 13 without parental consent. If we learn that a child under the age of 13 has provided us with personal information, or that such personal information has otherwise been inadvertently collected, we will delete it in accordance with applicable law.

**International Customer Privacy.**  Truce is operated in the United States. If you are located outside of the United States, then please be aware that information we collect will be transferred to and processed in the United States. By using our Services, or providing us with any information, you fully understand and unambiguously consent to this transfer, processing and storage of your information in the United States, a jurisdiction in which the privacy laws may not be as comprehensive as those in the country where you are located, reside and/or are a citizen.

**Updating or Amending Your Information.**  You may update, amend or otherwise change the information that Truce retains about you at any time by logging into your account and visiting the settings page or by contacting our customer support team.

**Updates to this Policy.**  This Policy may be updated periodically and without prior notice to you to reflect changes in our personal information practices. You should review this Policy periodically so that you keep up to date on our most current policies and practices. We will post a prominent notice on the Site to notify you of any significant changes to our privacy practices and indicate at the top of the Policy when it was last updated. Continued use of our Services after any update shall constitute your agreement to the new policy.

**Questions or Comments.**  If you have any questions or comments about this Policy, our privacy practices, or use of the Services, please feel free to contact us by email at contact@truce.io, or by mail at the following address: Truce Tech Inc - 109 Symonds Dr. #429 - Hinsdale, IL 60522.
`;

export const tos_markdown = `
# TERMS OF USE

**Last Updated: April 8, 2024**

## AGREEMENT TO TERMS
These Terms of Use constitute a legally binding agreement made between you, as a user of or visitor to truce.io (the “Site”) whether personally or on behalf of an entity (“you” or “user” as the context may require) and Truce Tech Inc and its affiliates, ( “Truce”, "Company", “we”, “us”, or “our” as the context may require), concerning your access to and use of the Site, the Truce Transparency Platform, any mobile application we provide, and anywhere else we may interact with you (collectively, the “Services”). You agree that by using the Services, you have read, understood, and agreed to be bound by all of these Terms of Use. WE RESERVE THE RIGHT TO CHANGE THESE TERMS OF USE WITHOUT NOTICE AND FOR ANY REASON, FROM TIME TO TIME IN OUR SOLE DISCRETION. IF YOU DO NOT AGREE WITH THESE TERMS OF USE, PLEASE DO NOT ACCESS THIS SITE OR USE OUR SERVICES.
You will be subject to and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Services after the date such revised Terms of Use are posted.
You may not use the Services or distribute the information provided on the Services in any manner whereby such use or distribution would be contrary to law or regulation.
The Services is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or otherwise register for the Services.

## CONTENT OF SERVICES
We believe that the information contained within the Services is accurate as at the date of publication, however, no warranty is made as to the accuracy, suitability, usefulness, reliability or completeness of any such information and no liability with respect to any errors or omissions (including any third-party liability) is accepted by us or any of our directors or employees. The use of our Services and the delivery of information by us or our agents through our Services or via email or other modes of delivery, is at your own risk.
The information on provided via our Services is for informational purposes only. It is not intended as an offer to sell, or solicitation of an offer to purchase any products or services. No content within our Services should be construed as a recommendation for any product or service by us or any third party. Products and services offered by Truce are subject to applicable laws and regulations are not available in all geographic locations or to all customers. The information on this Services does not include all applicable terms or issues pertaining to any of Truce’s product or service offerings.
This Services may provide referral information, third party content or live links to an unaffiliated third party and their websites, including companies that have a relationship with us. Our decision to provide referral information to a third party or to provide a link from our Services to a third party website is not an endorsement of the third party, their products or services, or their website accessed through any such links. Truce has no control over the content on such third party website and your access, use and reliance upon the content on such third party websites is at your own risk.

## INTELLECTUAL PROPERTY RIGHTS
Unless otherwise indicated, the Services constitute our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Services (collectively, the “Content”) and any trademarks, service marks, and logos (“Marks”) contained therein are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Services “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
You hereby grant to Truce, for marketing, advertising and promotional purposes, a limited, nonexclusive, nontransferable license to use your Marks designated from time to time for such use. Truce shall abide by all standards you provide concerning the formatting, style, and presentation of your Marks, and shall make all use of your Marks available to you for quality inspection. Truce shall immediately discontinue or correct any use of your Marks which does not conform to the aforementioned standards.
Provided that you are eligible to use the Services, you are granted a limited license to access and use the Services and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Services, the Content and the Marks.
Copyright notice: The works of authorship contained on the Services, including but not limited to all design, text, sound recordings, and images, are owned, except as otherwise expressly stated, by Truce. Except as otherwise expressly stated herein, they may not be copied, transmitted, displayed, performed, distributed (for compensation or otherwise), licensed, altered, framed, stored for subsequent use, or otherwise used in whole or in part in any manner without our prior written consent.

## USER REPRESENTATIONS
By using our Services, you represent and warrant that:  (1) you have the legal capacity and you agree to comply with these Terms of Use; (2) you are not a minor in the jurisdiction in which you reside; (3) you will not access the Services through automated or non-human means, whether through a bot, script, or otherwise; (4) you will not use the Services for any illegal or unauthorized purpose; and (5) your use of the Services will not violate any applicable law or regulation.
If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).

## PROHIBITED ACTIVITIES
You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
As a user of the Services, you agree not to:
1. Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
2. Circumvent, disable, or otherwise interfere with security-related features of the Services. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
3. Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
4. Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
5. Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.

\u200B
## SERVICES MANAGEMENT
We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Terms of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) your access to the Services any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.

## PRIVACY POLICY
We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services [https://www.truce.io/privacy-policy](https://www.truce.io/privacy-policy), which is incorporated into these Terms of Use. Please be advised the Services are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Services, you are transferring your data to the United States, and you agree to have your data transferred to and processed in the United States.

## DATA SECURITY AND INTEGRITY
We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data. You and the Services agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).
As part of a user’s access to Truce’s Transparency Platform or other Services, user will provide User Data as defined in Truce’s Privacy Policy [https://www.truce.io/privacy-policy](https://www.truce.io/privacy-policy). Users represent and warrant that any User Data submitted is accurate and upon reasonable notice, Truce may conduct technical, financial, and operational audits to ensure the accuracy of this User Data. Truce will maintain this data in confidence, and implement, maintain, and test controls reasonably designed to ensure the security and integrity of system on or through which User Data is stored, accessed, processed or transmitted.
You hereby grant Truce a non-exclusive, non-perpetual, revocable, non-sublicensable, limited right and license to User Data solely for Truce to provide Services and for other relevant commercial purposes. User Data will be anonymized and Truce reserves all rights in and ownership of this anonymized User Data (with the exception of the Broker Name and Shipper Name data fields) and you will not disclose or provide access to this User Data to any of your competitors. You also agree that you will not share any broker or shipping data with competitors and the prohibition on sharing any data or information with competitors will supersede any provision in any confidentiality, non-disclosure or other agreement entered into in conjunction with your use of our Services. Truce will not attempt to identify or re-identify any person or entity whose information may be included in any anonymized or otherwise aggregated or de-identified data received or processed as part of your use of the Services.
Subject to the requirements of these Terms of Use, Truce will delete, destroy, or otherwise remove from the platform the specific fields or data points that make up your User Data after the date you leave or otherwise terminate your access to our Services.

## CONTENT
**Your Content.** You may provide input to the Services (“Input”), and receive output from the Services based on the Input (“Output”). Input and Output are collectively “Content.” You are responsible for Content, including ensuring that it does not violate any applicable law or these Terms. You represent and warrant that you have all rights, licenses, and permissions needed to provide Input to our Services.

**Ownership of Content.** As between you and FreightGPT, and to the extent permitted by applicable law, you (a) retain your ownership rights in Input and (b) own the Output. We hereby assign to you all our right, title, and interest, if any, in and to Output.

**Similarity of Content.** Due to the nature of our Services and artificial intelligence generally, output may not be unique and other users may receive similar output from our Services. Our assignment above does not extend to other users’ output or any Third Party Output.

**Our Use of Content.** We may use Content to provide, maintain, develop, and improve our Services, comply with applicable law, enforce our terms and policies, and keep our Services safe.

**Accuracy.** Artificial intelligence and machine learning are rapidly evolving fields of study. We are constantly working to improve our Services to make them more accurate, reliable, safe, and beneficial. Given the probabilistic nature of machine learning, use of our Services may, in some situations, result in Output that does not accurately reflect real people, places, or facts.
When you use our Services you understand and agree:
- Output may not always be accurate. You should not rely on Output from our Services as a sole source of truth or factual information, or as a substitute for professional advice.
- You must evaluate Output for accuracy and appropriateness for your use case, including using human review as appropriate, before using or sharing Output from the Services.
- You must not use any Output relating to a person for any purpose that could have a legal or material impact on that person, such as making credit, educational, employment, housing, insurance, legal, medical, or other important decisions about them.
- Our Services may provide incomplete, incorrect, or offensive Output that does not represent FreightGPT's views. If Output references any third party products or services, it doesn’t mean the third party endorses or is affiliated with FreightGPT.

\u200B
## TERM AND TERMINATION
These Terms of Use shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.

## MODIFICATIONS AND INTERRUPTIONS
We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.

## GOVERNING LAW
These Terms of Use and your use of the Services are governed by and construed in accordance with the laws of the State of Illinois applicable to agreements made and to be entirely performed within the State of Illinois, without regard to its conflict of law principles.

## CORRECTIONS
There may be information within the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.

## DISCLAIMER; LIMITATION OF LIABILITY
THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES’S CONTENT OR THE CONTENT OF ANY WEBSERVICESS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, DELAYS, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR OTHER MALICIOUS CONTENT WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSERVICES, OR ANY WEBSERVICES OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES OR ANY PORTION THEREOF, REGARDLESS OF WHETHER WE HAVE BEEN APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, WARRANTY, TORT, STRICT LIABILITY, OR OTHERWISE.

## INDEMNIFICATION
You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) use of the Services; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these Terms of Use; or (4) your violation of the rights of a third party, including but not limited to intellectual property rights. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.

## ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
Using the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.

## CALIFORNIA USERS AND RESIDENTS
If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.

## MISCELLANEOUS
These Terms of Use and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Services. You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.

## CONTACT US
In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at contact@truce.io.
`;
