<template>
    <div class="card-gradient-wrapper">
        <v-card
            class="ma-1 pa-10 card-gradient"
            style="background: rgb(15,20,30, 1) !important; max-width: 500px"
            elevation="0">
            <v-card-title class="mb-5" style="color: #dddddd">
                {{ panelTitle }}
            </v-card-title>
            <v-card-text class="px-0">
                <v-form>
                    <v-btn
                        color="#DDDDDD"
                        dark
                        class="text-capitalize mb-5 px-2"
                        style="width: 100%; display: flex"
                        outlined
                        @click="redirectToSignUp">
                        <v-icon
                            left
                            size="23"
                            >mdi-email</v-icon
                        >
                        Sign Up with Email
                    </v-btn>
                    <GoogleSSOButton
                        color="#DDDDDD"
                        buttonPhrase="Sign Up with Google" />
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import GoogleSSOButton from '../containers/landing/GoogleSSOButton.vue';

export default {
    name: 'SignUpPanel',
    props: {
        panelTitle: {
            type: String,
            default: 'Sign Up for Free Today!'
        }
    },
    components: {
        GoogleSSOButton
    },
    methods: {
        redirectToSignUp() {
            const url = window.location.href;
            this.$store.commit('setStateProperty', {
                property: 'url',
                value: url
            });
            this.$router.push({
                name: 'signup',
                params: { signUpInitialRedirection: 'true' }
            });
        }
    }
};
</script>

<style scoped>
.card-gradient {
    background: #07090e;
    color: whitesmoke;
    margin: 1.6px;
    font-size: 14px;
}

.card-gradient-wrapper {
    max-width: 508px;
    flex-grow: 2;
    width: fit-content;
    height: fit-content;
    position: relative;
    background: linear-gradient(to left, #0366f9, #ad00ff);
}

::v-deep .v-icon {
    margin-left: 0 !important;
}

@media screen and (max-width: 800px) {
    .card-gradient-wrapper {
        flex-grow: 0;
    }
}
</style>
