import { WEBSOCKET_LINK, LOCAL_TESTING_API } from './resources';

export default class LangChainSocket {
    socketUrl: string;

    constructor() {
        this.socketUrl = WEBSOCKET_LINK;
        if (
            window.location.href.includes('localhost') &&
            process.env?.VUE_APP_STAGE_NAME
        ) {
            this.socketUrl = LOCAL_TESTING_API;
        }
    }

    sendMessage(message_obj: any, callback: any) {
        const socket = new WebSocket(this.socketUrl);
        socket.onopen = function () {
            console.log('[open] Connection established');
            socket.send(JSON.stringify(message_obj));
        };
        socket.onmessage = function (event) {
            callback(event, socket);
        };
        socket.onclose = function (e) {
            if (e.wasClean) {
                console.log(
                    `[close] Connection closed cleanly, code=${e.code} reason=${e.reason}`
                );
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                console.log('[close] Connection died');
            }
        };
        socket.onerror = function (error) {
            console.log(error);
        };
        return socket;
    }
}
