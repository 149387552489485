<template>
    <v-btn
        :color="color"
        dark
        class="text-capitalize custom-border g-signin2 px-2"
        style="width: 100%; display: flex"
        outlined
        @click="signIn"
    >
        <v-icon left size="23"
            >mdi-google</v-icon
        >
        {{ buttonPhrase }}
    </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import * as sso from '../../authentication/sso-login';
import * as analytics from '../../analytics/pinpoint';

export default Vue.extend({
    name: 'GoogleSSOButton',
    props: {
        initial_email: String,
        errors: Array,
        buttonPhrase: {
            type: String,
            default: "Login with Google"
        },
        color: {
            type: String,
            default: "white"
        },
        onSignupForm: {
            type: Boolean,
            default: false
        }
    },

    components: {},

    methods: {
        signIn() {
            if (this.onSignupForm) {
                analytics.sendEvent('form_sso_click');
            } else {
                analytics.sendEvent('other_sso_click');
            }
            const url = window.location.href;
            if (url && !url.includes("/signup")) {
                this.$store.commit('setStateProperty', {
                    property: 'url',
                    value: url
                });
            }
            // this.$gtag.event('sign_up_click', {
            //         event_category: 'engagement',
            //         event_label: 'new_user',
            //         value: '1'
            //     });
            this.$emit('showLoading');
            sso.googleSignIn();
        }
    },

    data: function () {
        return {
        };
    }
});
</script>
