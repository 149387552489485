<template>
    <div :id="id" class="container-1">
        <div class="data-info-heading">
            <v-icon
                @click="changeDisplayInfo('left')"
                v-if="displayInfo.length > 1"
                style="cursor: pointer; font-size: 30px"
                color="white"
            >
                mdi-chevron-left
            </v-icon>
            <span style="padding: 0px 10px">
                {{
                    displayInfo[displayIndex]
                        ? displayInfo[displayIndex].origin_city
                        : ''
                }}
                <v-icon color="white"> mdi-arrow-right-thin </v-icon>
                {{
                    displayInfo[displayIndex]
                        ? displayInfo[displayIndex].destination_city
                        : ''
                }}
            </span>
            <v-icon
                @click="changeDisplayInfo('right')"
                v-if="displayInfo.length > 1"
                style="cursor: pointer; font-size: 30px"
                color="white"
            >
                mdi-chevron-right
            </v-icon>
        </div>
        <div :class="!isMobile ? 'container-2' : ''">
            <div
                class="data-info-container"
                :style="{ width: isMobile ? '100% !important' : '', ...dynamicTextSize}"
            >
                <div
                    class="data-info"
                    :style="{
                        'padding-left': displayInfo.length <= 1 ? '' : '25px'
                    }"
                >
                    <span class="data-input">
                        <div class="data-input-heading">5-Day Avg Rate w Fuel</div>
                        <div class="price-value">
                            {{
                                displayInfo[displayIndex]
                                    ? formatDollars(displayInfo[displayIndex].all_in_rate, fixed_point=2, showCents=true)
                                    : '$'
                            }}
                            <div class="info-tooltip">
                                <v-icon small color="rgb(143, 143, 143)">mdi-help-circle-outline</v-icon>
                                <span class="info-tooltip-text" style="">This price is calculated based on current market conditions and historical data trends</span>
                            </div>
                        </div>
                    </span>
                    <span style="padding: 15px"></span>
                    <span class="data-input">
                        <div class="data-input-heading">Equip. Type</div>
                        <div>
                            {{
                                displayInfo[displayIndex]
                                    ? displayInfo[displayIndex].equipment_type
                                    : ''
                            }}
                        </div>
                    </span>
                </div>
                <div
                    class="data-info"
                    :style="{
                        'padding-left': displayInfo.length <= 1 ? '' : '25px'
                    }"
                >
                    <span class="data-input">
                        <div class="data-input-heading">Distance</div>
                        <div>
                            {{
                                displayInfo[displayIndex]
                                    ? displayInfo[displayIndex].total_distance
                                    : ''
                            }}
                            miles
                        </div>
                    </span>
                    <span style="padding: 15px"></span>
                    <span class="data-input">
                        <div class="data-input-heading">Diesel Cost</div>
                        <div>
                            {{
                                displayInfo[displayIndex]
                                    ? formatDollars(displayInfo[displayIndex].fuel_cost, fixed_point=2, showCents=true)
                                    : '$'
                            }}
                        </div>
                    </span>
                </div>
                <div
                    class="data-info reduced-apex-chart"
                    :style="{
                        'padding-left': displayInfo.length <= 1 ? '' : '25px'
                    }"
                >
                    <span
                        style="width: 100%"
                    >
                        <div class="trend-data data-input-heading">
                            <span
                                style="
                                    padding-left: 10px;
                                    border-left: 2px solid #8f8f8f;
                                    "
                                    :style="dynamicTextSize"
                                >Trend (30d)</span
                            >
                            <span class="trend-expand-icon" v-if="!isMobile">
                                <v-btn
                                    icon
                                    @click="expandTrend"
                                    class="mt-1"
                                    style="color: #8f8f8f"
                                >
                                    <v-icon size="22" color="">
                                        mdi-arrow-expand-all
                                    </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <div
                            style='height: 80px'
                        >
                            <div :id="id + 'chart'"></div>
                        </div>
                    </span>
                </div>
            </div>
            <div
                :class="[
                    isMobile ? 'mobile-map-container' : 'desk-map-container'
                ]"
            >
                <span
                    v-if="!dialog"
                    style="
                        padding: 0px 10px;
                        color: #8f8f8f;
                        border-left: 2px solid #8f8f8f;
                        font-weight: 400;
                    "
                    :style="dynamicTextSize"
                    >Lane Map</span
                >
                <span>
                    <GMapPolyline
                        :mapEvents="map_events"
                        :isMobile="isMobile"
                        :id="id + 'gmap'"
                        :displayIndex="displayIndex"
                        type="GmapPricer"
                        @updateDialogValue="updateDialogValue"
                    />
                </span>
            </div>
            <v-dialog v-model="showMapDialog" width="55%">
                <v-card>
                    <v-card-title class="pb-6">
                        <span>
                {{
                    displayInfo[displayIndex]
                        ? displayInfo[displayIndex].origin_city
                        : ''
                }}
                <v-icon color="white"> mdi-arrow-right-thin </v-icon>
                {{
                    displayInfo[displayIndex]
                        ? displayInfo[displayIndex].destination_city
                        : ''
                }}
            </span>
            <v-spacer></v-spacer>
            <v-btn-toggle
                v-model="selectedPeriod"
                mandatory
                @change="updateFilteredSeries"
                class="toggle-group"
            >
            <v-btn value="7">7d</v-btn>
            <v-btn value="15">15d</v-btn>
            <v-btn value="30">30d</v-btn>
            </v-btn-toggle>
            <v-btn icon @click="closeDialog" class="mt-1 ml-3">
                <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <HistPriceGraph
                            :series="filteredHistPriceSeries"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import * as decoder from './polyline_utils/decoder';
import GMapPolyline from './GMapPolyline.vue';
import HistPriceGraph from './HistPriceGraph.vue';
import { formatDollars, responsiveTextSize } from './formatUtils';

export default {
    name: 'GMapPricer',
    components: {
        GMapPolyline,
        HistPriceGraph
    },
    props: {
        mapEvents: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: ''
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        markerData: {
            type: Object,
            default: () => {}
        },
        transit_markers: {
            type: Array,
            default: () => []
        }
    },
    mounted() {
        var allInfo = decoder.coordinates_and_polylines_from_events(
            this.map_events
        );
        this.displayInfo = allInfo[5];
        let mockData =
            this.displayInfo[this.displayIndex].historical_price_data;
        this.chartOptions.series[0].data = [];
        mockData.time_series.forEach((element) => {
            this.chartOptions.series[0].data.push(element[1].toFixed(2));
        });
        this.chartOptions.series[1].data = Array(
            mockData.time_series.length
        ).fill(mockData.average.toFixed(2));

        // Expanded historical price
        this.histPriceName = `${this.displayInfo[this.displayIndex].origin_city || 'Unknown Origin'} → ${this.displayInfo[this.displayIndex].destination_city || 'Unknown Destination'}`
        this.histPriceSeries = [{"data":mockData.time_series.map((point) =>
                        [(point[0] | 0) * 1000, point[1].toFixed(2)]
                    ), "name": ""}];
        this.updateFilteredSeries();

        // eslint-disable-next-line
        this.chart = new ApexCharts(
            document.querySelector('#' + this.id + 'chart'),
            this.chartOptions
        );
        this.chart.render().then(() => {
            // this.applyGradient();
        });
    },

    watch: {
      '$vuetify.breakpoint': {
        handler() {
          this.dynamicTextSize = this.responsiveTextSize(15, 15, 16, 17, 18);
          // Update other styles similarly
        },
        deep: true
      }
    },

    data: function () {
        return {
            chart: {},
            showMapDialog: false,
            histPriceSeries: [],
            filteredHistPriceSeries: [],
            histPriceName: '',
            selectedPeriod: "30",
            chartOptions: {
                chart: {
                    type: 'line',
                    width: '100%',
                    height: '100%',
                    sparkline: {
                        enabled: true
                    },
                },
                series: [
                    {
                        name: 'Price',
                        data: [],
                        tooltip: {
                            enabled: true // Enable tooltip for the first series
                        }
                    },
                    {
                        name: 'Average',
                        data: [],
                        color: '#fff' // Set the color for the average line
                    }
                ],
                stroke: {
                    width: 3,
                    curve: 'smooth',
                    dashArray: [0, 4]
                },
                tooltip: {
                    theme: 'dark',
                    //eslint-disable-next-line
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return '<div style="background-color: #333333 !important; box-shadow: none !important; padding: 8px !important;">' +
                            '<span> Price : ' + formatDollars(series[0][dataPointIndex], 2, true) + '</span>' + '<br>' +
                            '<span> Average : ' + formatDollars(series[1][dataPointIndex], 2, true) + '</span>' +
                            '</div>';
                    },

                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        // title: {
                        //     formatter: function (seriesName) {
                        //         console.log(seriesName)
                        //         return seriesName + '$';
                        //     }
                        // }
                    },
                    marker: {
                        show: false
                    }
                },
                colors: ['#348EFF'] // Default color
            },
            expandedTrend: false,
            displayIndex: 0,
            displayInfo: [],
            map_events: this.mapEvents,
            dialog: false,
            dynamicTextSize: {}
        };
    },
    methods: {
        updateDialogValue(value) {
            this.dialog = value;
        },
        closeDialog() {
            this.showMapDialog = false;
        },
        updateFilteredSeries() {
            const period = parseInt(this.selectedPeriod, 10);
            this.filteredHistPriceSeries = [{
                "data": this.histPriceSeries[0].data.slice(-period),
                "name": ""
            }]
        },
        applyGradient() {
            // Define the gradient
            const svg = document.querySelector('#' + this.id + 'chart svg');
            const defs =
                svg.querySelector('defs') ||
                document.createElementNS('http://www.w3.org/2000/svg', 'defs');
            if (!svg.querySelector('defs')) {
                svg.insertBefore(defs, svg.firstChild);
            }

            const linearGradient = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'linearGradient'
            );
            linearGradient.setAttribute('id', 'lineGradient');
            linearGradient.setAttribute('x1', '0%');
            linearGradient.setAttribute('y1', '0%');
            linearGradient.setAttribute('x2', '100%');
            linearGradient.setAttribute('y2', '0%');

            const stop1 = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'stop'
            );
            stop1.setAttribute('offset', '0%');
            stop1.setAttribute('style', 'stop-color:#ffffff;stop-opacity:1');
            linearGradient.appendChild(stop1);

            const stop2 = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'stop'
            );
            stop2.setAttribute('offset', '100%');
            stop2.setAttribute('style', 'stop-color:#348EFF;stop-opacity:1');
            linearGradient.appendChild(stop2);

            defs.appendChild(linearGradient);

            // Apply the gradient to the line stroke
            const seriesPaths = svg.querySelectorAll('.apexcharts-series path');
            seriesPaths[0].setAttribute('stroke', 'url(#lineGradient)');
        },
        expandTrend() {
            this.selectedPeriod = "30";
            this.updateFilteredSeries();
            this.showMapDialog = true;
        },
        changeDisplayInfo(args) {
            if (args == 'left') {
                if (this.displayIndex == 0) {
                    this.displayIndex = this.displayInfo.length - 1;
                } else {
                    this.displayIndex -= 1;
                }
            } else {
                if (this.displayIndex == this.displayInfo.length - 1) {
                    this.displayIndex = 0;
                } else {
                    this.displayIndex += 1;
                }
            }

            let mockData =
                this.displayInfo[this.displayIndex].historical_price_data;

            const newSeries = [
                {
                    name: 'Price :',
                    data: mockData.time_series.map((point) =>
                        point[1].toFixed(2)
                    )
                },
                {
                    name: 'Average :',
                    data: Array(mockData.time_series.length).fill(
                        mockData.average.toFixed(2)
                    )
                }
            ];

            this.chart.updateSeries(newSeries);
            this.histPriceSeries = [{"data":mockData.time_series.map((point) =>
                        [(point[0] | 0) * 1000, point[1].toFixed(2)]
                    ), "name": ""}];
            this.updateFilteredSeries();
        },
        formatDollars,
        responsiveTextSize
    }
};
</script>
<style scoped>
.map-container-reduced {
    position: relative;
    width: 60%;
    /* padding: 0px 40px 0px 40px; */
    /* height: 300px; */
}
.map-container-expanded {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 111;
    border: 80px solid rgba(255, 255, 255, 0.5);
    outline: 10px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.map {
    width: 100%;
    height: 100%;
}
.custom-overlay {
    position: absolute;
    z-index: 1000; /* Ensure it's above the map */
}

.custom-overlay-content {
    background-color: white;
    border: 1px solid #8f8f8f;
    padding: 10px;
    position: absolute;
    max-width: 200px; /* Adjust as needed */
}
.hover-div {
    position: absolute;
    left: 4px;
    background-color: transparent; /* Or any color you wish */
    z-index: 10; /* Make sure this is above the map's z-index */
    /* More styling for your div */
}
.expand-icon {
    position: absolute;
    top: -8px;
    background-color: transparent;
    z-index: 10;
}
.trend-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.trend-expand-icon {
    display: inline-flex;
    position: relative;
    right: 0px;
    background-color: transparent;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Space between legend items */
    cursor: pointer;
}

.color-box {
    display: inline-block;
    width: 10px; /* Width of the color box */
    height: 10px; /* Height of the color box */
    margin-right: 10px; /* Space between the color box and text */
}

.center-content {
    display: flex;
    align-items: center;
}
.list-class {
    display: flex;
    align-items: center;
    color: white;
    background-color: rgba(15, 15, 15, 0.75);
}
.textDisplay {
    display: block;
    cursor: pointer;
    overflow: auto;
    color: white;
}
.v-btn:before {
    background-color: transparent !important;
}
.container-1 {
    /* background-image: linear-gradient(to bottom left, rgb(23 23 23 / 83%), rgb(77 104 108 / 83%));
    border: 2px solid #8f8f8f; */
    background-color: #171717d4;
    border-radius: 10px;
}
.container-2 {
    display: flex;
    padding-bottom: 20px;
}
.data-info-heading {
    display: flex;
    padding: 20px 10px 10px 20px;
    align-items: center;
    /* justify-content: center; */
    font-weight: 700;
    font-size: 18px;
}
.data-info-container {
    padding: 0px 30px 0px 30px;
    width: 50%;
    font-size: 18px;
    font-weight: 400;
    /* border-right: 1px solid #3181b6; */
}
.data-info {
    display: flex;
    padding: 8px 0px;
    /* justify-content: center; */
}
.data-input {
    border-left: 2px solid #8f8f8f;
    padding: 0px 8px;
    width: 50%;
}
.data-input-heading {
    color: #8f8f8f;
}
.p-10 {
    padding: 10px;
}
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: #8f8f8f !important;
}
.desk-map-container {
    width: 60%;
    padding: 0px 30px;
    border-left: 1px solid #3181b6;
}
.mobile-map-container {
    width: 100%;
    padding: 0px 25px 20px 25px;
}
.reduced-apex-chart {
    height: 110px;
}
.expanded-apex-chart {
    width: 100%;
    position: fixed;
    padding: 10px;
    left: 0px;
    top: 0px;
    border: 80px solid rgba(255, 255, 255, 0.5);
    outline: 10px solid rgba(255, 255, 255, 0.5);
    /* background: transparent; */
    height: 100%;
    z-index: 999;
    background-color: #272727;
}
.v-btn:before {
    background-color: transparent !important;
}
.v-icon.v-icon::after {
    background-color: transparent !important;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: scale(1.3);
    width: 100%;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.toggle-group .v-btn {
  height: 30px !important; /* Adjust the height as needed */
  min-width: 50px; /* Adjust the width as needed */
}

.toggle-group .v-btn--active {
  background-color: #1976D2; /* Adjust to your preferred fill color */
  color: white;
}

.info-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.info-tooltip .info-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #333333;
    color: #dddddd;
    text-align: center;
    border-radius: 6px;
    padding: 6px 12px;
    position: absolute;
    z-index: 7;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}

.info-tooltip:hover .info-tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
