<template>
    <v-container dense fluid id="rootContainer" class="px-0" style="z-index: 4">
        <v-container
            id="logContainer"
            class="d-flex flex-column flex-grow-1 align-items-center pa-0"
            :class="[
                active ? 'active' : '',
                $vuetify.breakpoint.md ? 'medium' : ''
            ]">
            <div
                v-if="errorMessage"
                style="
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: -50px;
                ">
                <v-alert
                    icon="mdi-alert-circle-outline"
                    dense
                    outlined
                    type="info">
                    {{ errorMessage }}
                </v-alert>
            </div>
            <div class="text-field-wrapper" v-else>
                <v-textarea
                    ref="textField"
                    :key="textFieldKey"
                    v-model="text"
                    :placeholder="placeholder"
                    class="textField highlight"
                    :class="[active ? 'active' : '']"
                    rows="1"
                    filled
                    hide-details
                    solo
                    flat
                    id="text-area"
                    auto-grow
                    @keydown="handleKeyboard"
                    @focus="handleInputFocus"
                    @blur="handleInputBlur">
                    <template #append>
                        <v-btn
                            v-if="!isMobile"
                            color="info"
                            :class="{ clickable: submittable }"
                            style="
                                text-transform: none;
                                font-weight: 400;
                            "
                            :disabled="!submittable"
                            v-on="submittable ? { click: submit } : {}"
                            >
                            Ask Question
                            <span
                                v-if="submitLocked"
                                class="loader"
                                style="margin-left:10px"
                            >
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                            </span>
                        </v-btn>
                        <v-btn
                            v-else
                            text
                            plain
                            :ripple="false"
                            style="padding: 0; width: min-content; min-width: 0;"
                        >
                            <span v-if="!submitLocked">
                                <v-icon
                                    v-on="submittable ? { click: submit } : {}"
                                    :class="{ clickable: submittable }"
                                    :style="{ color: submittable ? '' : '#666666 !important' }"
                                    class="mt-1 ml-2"
                                    ref="sendBtn"
                                    id="sendBtn"
                                >mdi-send
                                </v-icon>
                            </span>
                            <span
                                v-else
                                class="loader"
                                style="margin-left:10px"
                            >
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                            </span>
                        </v-btn>
                    </template>
                </v-textarea>
                <label
                    for="text-area"
                    style="
                        position: absolute;
                        top: -32px;
                        font-size: 18px;
                        left: 8px;
                    ">
                    Enter your question below
                </label>
            </div>
            <span style="display:flex; width: 100%; justify-content: space-between">
                <span
                    v-if="!errorMessage"
                    style="color: #cccccc; text-align: right; padding: 4px; margin-left: auto"
                    >{{ hitCount }}/{{ queryLimit }}
                </span>
            </span>
            <div
                v-if="limitError"
                style="
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: 5px;
                ">
                <v-alert
                    icon="mdi-alert-circle-outline"
                    dense
                    outlined
                    type="info">
                    You have reached your usage limit with FreightGPT. Sign up
                    to continue!
                </v-alert>
                <div class="login-button-wrapper">
                    <v-btn
                        dark
                        class="text-capitalize login-button"
                        ref="waitlistBtn"
                        text
                        color="#0366f9"
                        @click="redirectToSignup"
                        ><span class="white--text">Sign Up</span>
                    </v-btn>
                </div>
            </div>
            <v-container
                class="mx-0"
                id="chatLog"
                :style="[
                    { paddingBottom: '0' },
                    noTransition
                        ? {
                              scrollBehavior: 'auto !important'
                          }
                        : {
                              scrollBehavior: 'smooth !important'
                          }
                ]">
                <div
                    :key="msg.id"
                    v-for="(msg, index) in messages"
                    style="
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                    ">
                    <v-card
                        class="message my-2 mr-0 d-flex align-center"
                        :class="noTransition ? 'notransition' : ''"
                        flat
                        width="100%"
                        :color="msg.isQuery ? '#303850' : '#0F141E'"
                        :style="
                            msg.isQuery ? {} : { border: '1px solid #0366f9' }
                        "
                        v-if="msg.message_type == 'text'"
                        :id="'msgCard' + msg.id">
                        <v-card-text
                            :style="
                                isMobile
                                    ? {
                                          padding: '12px 12px 12px 12px',
                                          overflowX: 'hidden'
                                      }
                                    : {
                                          paddingLeft: '26px',
                                          paddingRight: '42px',
                                          overflowX: 'hidden',
                                          fontSize: '16px',
                                      }
                            ">
                            <markdown-renderer
                                :markdown-content="msg.content.text"
                            ></markdown-renderer>
                        </v-card-text>
                        <div v-if="!msg.isQuery">
                            <v-card-actions
                                style="
                                    position: absolute;
                                    top: 2px;
                                    padding: 0px;
                                "
                                :style="
                                    isMobile
                                        ? { right: '10px' }
                                        : { right: '6px' }
                                ">
                                <v-btn
                                    v-if="!isMobile"
                                    icon
                                    color="#0366f9"
                                    @click="copyMessage(msg.content.text)">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 32 32">
                                        <path
                                            fill="currentColor"
                                            d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z" />
                                        <path
                                            fill="currentColor"
                                            d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z" />
                                    </svg>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                    <div
                        style="
                            height: 300px;
                            display: block;
                            width: 100%;
                            justify-content: center;
                            margin-bottom: 0.8rem;
                        "
                        v-if="msg.message_type == 'graph'">
                        <HistPriceGraph
                            :series="msg.content.series"
                            :name="msg.content.name" />
                    </div>
                    <div
                        v-if="msg.message_type == 'vis_event'"
                        style="width: 100%"
                        :style="{
                            marginBottom: '0.4rem'
                        }">
                        <GMapPricer
                            v-if="msg.vis_type == 'pricer_polyline'"
                            :mapEvents="msg.event"
                            :isMobile="isMobile"
                            :id="'pricer_polyline' + index" />
                        <GMapPolyline
                            v-if="msg.vis_type == 'gmaps'"
                            :mapEvents="msg.event"
                            :markerData="msg.marker_data"
                            :transit_markers="msg.transit_markers"
                            :isMobile="isMobile"
                            :id="'gmap_' + index" />
                        <WindyMap
                            v-else-if="msg.vis_type == 'windy'"
                            :windyEvents="msg.event"
                            :id="'windymap_' + index"
                            :isMobile="isMobile" />
                        <GMapPolygon
                            v-else-if="msg.vis_type == 'polygon'"
                            :polygonEvents="msg.event"
                            :isMobile="isMobile"
                            :id="'gmap_' + index" />
                    </div>
                    <div
                        v-if="msg.message_type == 'carrier_search_results'"
                        style="width: 100%"
                        :style="{
                            marginBottom: '0.4rem'
                        }">
                        <carrierSearch
                            :carrier_data="msg.event"
                            :isMobile="isMobile"
                            :id="'carrier_' + index"
                            :websocket="lang_chain_socket"
                            :is_public="true" />
                    </div>
                    <div
                        style="
                            width: 100%;
                            padding-left: 8px;
                            height: 20px;
                            margin-top: -0.2rem;
                            margin-bottom: -1rem;
                        "
                        v-if="index === messages.length - 1">
                        <div
                            :key="eventcounter"
                            v-if="msgLoading"
                            class="d-flex justify-start loadingPhrase"
                            :style="
                                isMobile
                                    ? { position: 'inherit' }
                                    : {
                                          width: '100%'
                                      }
                            ">
                            <vue-typed-js
                                :key="loadingPhraseKey"
                                :strings="loadingPhrases"
                                :showCursor="false"
                                :contentType="'html'"
                                :backDelay="2500"
                                :typeSpeed="10">
                                <span
                                    class="typing loading"
                                    style="
                                        white-space: pre-wrap;
                                        word-break: keep-all;
                                        font-size: 0.8rem;
                                    ">
                                </span>
                            </vue-typed-js>
                        </div>
                    </div>
                </div>
            </v-container>
        </v-container>
        <v-snackbar v-model="snackbar" :color="snackColor" top timeout="2500">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>
<script lang="ts">
import Vue from 'vue';
import _ from 'lodash';
import LangChainSocket from '../websocket';
import { ResponseObject } from '../types/responseObject';
import { UserInterface } from '../types/userObject';
import WindyMap from '../components/WindyMaps.vue';
import GMapPolyline from '../components/GMapPolyline.vue';
import GMapPricer from '../components/GMapPricer.vue';
import { isDevEnv, APIEndpoints } from '../resources';
import GMapPolygon from '../components/GMapPolygon.vue';
import carrierSearch from '../components/carrierSearch.vue';
import * as analytics from '../analytics/pinpoint';
import VueApexCharts from 'vue-apexcharts';
import HistPriceGraph from '@/components/HistPriceGraph.vue';
import MarkdownRenderer from './MarkdownRenderer.vue';

const preloadBucketUrl =
    'https://freightgpt7e5e8d79022340cb8d28bac25e79b88e211850-dev.s3.amazonaws.com/prepopulated_data.json';

async function fetchPreloadData(pageName: string) {
    console.log(pageName);
    try {
        const response = await fetch(preloadBucketUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return (data as any)[pageName];
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts); // eslint-disable-line vue/multi-word-component-names

export default Vue.extend({
    name: 'DemoChat',
    props: {
        pre_query: {
            type: String,
            default: '',
            required: false
        },
        page_name: {
            type: String,
            default: '',
            required: true
        }
    },

    components: {
        WindyMap,
        GMapPolyline,
        GMapPricer,
        GMapPolygon,
        HistPriceGraph,
        carrierSearch,
        MarkdownRenderer
    },

    methods: {
        handleKeyboard(event: any) {
            if (event.key === 'Enter' && this.submitLocked) {
                event.preventDefault();
                return;
            }
            if (
                event.key === 'Enter' &&
                !event.shiftKey &&
                this.text.replace(/\s/g, '') !== ''
            ) {
                event.preventDefault();
                this.submit();
            }
        },
        submit() {
            let publicName = this.page_name == '' ? 'public' : `public_${this.page_name}`;
            analytics.sendEvent(
                'send_query',
                this.user_info.sub,
                publicName,
                this.user_info.email
            );
            this.limitError = false;
            this.placeholder = '';
            this.clearMessages();
            this.transit_markers = [];
            this.noTransition = false;
            if (this.submitLocked || this.text === '') {
                return;
            }
            this.active = true;
            this.isNewConv = false;
            if (this.messages.length === 0 && !this.firstMessageSent) {
                this.firstMessageSent = true;
            }
            // add text to messages and clear field
            this.loadingPhrases = [''];
            setTimeout(() => {
                this.loadingPhrases = ['Sending request'];
                this.loadingPhraseKey += 1;
            }, 350);
            this.msgLoading = true;

            // hit API using text, then use returned value to make query
            this.submitLocked = true;
            this.$nextTick(() => {
                const sendBtn = this.$refs.sendBtn as any;
                sendBtn.$el.classList.add('locked');
            });

            // Prepare the message object
            var messageObject: any = {
                action: this.chat_endpoint,
                message: this.text,
                session_id: this.curr_session_id,
                isPublicChat: 1
            };
            console.log(this.chat_endpoint);
            if (this.alb_cookie_value !== 'ALB=null;') {
                messageObject['cookie'] = this.alb_cookie_value;
            }
            this.currentSocket = this.lang_chain_socket.sendMessage(
                messageObject,
                this.handleResponseToken
            );

            this.pushMessage(false, { text: '' });
            if (!this.isMobile) {
                this.textField.focus();
            } else {
                this.textField.blur();
            }
        },

        generateRandomString(length: number) {
            const characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            return result;
        },

        pushMessage(
            isQuery: boolean,
            content: Object,
            type: string = 'text',
            message_id: any = null
        ) {
            this.messages.push({
                id: this.generateRandomString(this.msgCounter + 1),
                message_id: message_id,
                isQuery: isQuery,
                sentiment: null,
                timestamp: Date.now(),
                content: content,
                copyDisabled: true,
                message_type: type
            });
            this.msgCounter += 1;
        },

        pushVisEvent(events: any, message_id: any = null) {
            if (
                this.vis_events.length == 0 &&
                !_.isEmpty(this.transit_markers.length)
            ) {
                this.messages.push({
                    id: this.generateRandomString(this.msgCounter + 1),
                    message_id: message_id,
                    event: events,
                    vis_type: 'gmaps',
                    message_type: 'vis_event',
                    transit_markers: this.transit_markers,
                    marker_data: this.marker_data
                });
                this.msgCounter += 1;
            } else if (
                this.vis_events.length != 0 ||
                this.transit_markers.length != 0
            ) {
                this.messages.push({
                    id: this.generateRandomString(this.msgCounter + 1),
                    message_id: message_id,
                    event: events,
                    vis_type: events[0]['type'],
                    message_type: 'vis_event',
                    marker_data: this.marker_data,
                    transit_markers: this.transit_markers
                });
                this.msgCounter += 1;
            }

            this.marker_data = {};
            this.transit_markers = [];
            this.vis_events = [];
        },

        pushCarrierSearch(event: any, message_id: any = null) {
            this.messages.push({
                id: this.generateRandomString(this.msgCounter + 1),
                message_id: message_id,
                event: event,
                message_type: 'carrier_search_results'
            });
            this.msgCounter += 1;
        },

        setNewSessionProperties(event: any, socket: any) {
            let response_dict = JSON.parse(event.data);
            if (response_dict['event']['status'] == 'SUCCESS') {
                this.curr_session_id = response_dict['event']['session_id'];
                this.curr_conversation_id =
                    response_dict['event']['conversation_id'];
                if (!this.isMobile && this.$refs.textField) {
                    (this.$refs.textField as any).focus();
                }

                if (
                    'cookie' in response_dict['event'] &&
                    this.alb_cookie_value == 'ALB=null;'
                ) {
                    document.cookie = response_dict['event']['cookie'];
                    this.alb_cookie_value = `ALB=${this.getCookieValue(
                        'ALB'
                    )};`;
                }
            } else {
                console.log('New session failed.', event);
            }
            socket.close();
        },

        closeSessionDummyCallback(event: any, socket: any) {
            console.log(event, socket);
        },

        closeSessionCallback(event: any, socket: any) {
            socket.close();
        },

        handleResponseToken(event: any, socket: any) {
            if (this.messages.length === 0) {
                this.submitLocked = false;
                return;
            }
            let response_dict = JSON.parse(event.data);
            let keys = Object.keys(response_dict);
            if (keys.includes('token')) {
                let responseToken = response_dict['token'];
                this.messages[0]['content']['text'] += responseToken;
                this.msgLoading = false;
                let lastMsgId = this.messages[this.lastResponseIndex].id;
                let newMsgHeight = document.getElementById(
                    'msgCard' + lastMsgId
                )?.offsetHeight;
                this.prevMsgHeight = newMsgHeight;
            } else if (keys.includes('event')) {
                var event_content = response_dict['event'];
                switch (event_content.type) {
                    case 'run_id':
                        this.curr_parent_run_id = event_content.data.run_id;
                        this.messages[0].message_id = this.curr_parent_run_id;
                        break;
                    case 'loading_description':
                        this.loadingPhrases = [event_content.data];
                        this.loadingPhraseKey += 1;
                        this.loadText = event_content.data;
                        if (this.loadText === 'Predicting truck cost') {
                            this.messages[this.messages.length - 1].isPricing =
                                true;
                        }
                        break;
                    case 'graph_series':
                        this.graph_data.push(event_content.data);
                        break;
                    case 'pricer_polyline':
                    case 'gmaps':
                    case 'windy':
                    case 'polygon':
                        this.addToVisEvents(event_content);
                        break;
                    case 'transit_markers':
                        this.transit_markers.push(event_content.data);
                        break;
                    case 'carrier_search_results':
                        this.carrierSearchResults = event_content.data;
                        break;
                    case 'summary':
                        this.summaryShow = event_content['data'];
                        break;
                    case 'error':
                        if (event_content.data == 'max limit reached') {
                            this.limitError = true;
                        } else {
                            this.errorMessage = event_content.data;
                        }
                        this.clearMessages();
                        this.submitLocked = false;
                        break;
                    case 'hits':
                        this.hitCount = event_content.data;
                        break;
                }
            } else if (keys.includes('close')) {
                socket.close();
                this.submitLocked = false;
                this.messages[this.messages.length - 1].copyDisabled = false;
                if (this.carrierSearchResults) {
                    this.pushCarrierSearch(this.carrierSearchResults);
                    this.carrierSearchResults = null;
                }
                this.pushVisEvent(this.vis_events, this.curr_parent_run_id);
                if (this.graph_data.length > 0) {
                    for (const entry of this.graph_data) {
                        this.attachGraph(entry);
                    }
                    this.graph_data = [];
                }
                setTimeout(() => {
                    const sendBtn = this.$refs.sendBtn as any;
                    if (sendBtn) {
                        sendBtn.$el.classList.remove('locked');
                    }
                }, 0);
                this.curr_parent_run_id = '';
            }
        },

        logUserQuery(queryText: string) {
            this.pushMessage(true, { text: queryText });
        },

        populateTemplate(template: any, parameters: Object) {
            // Not in use, but just in case I need to do templating of some kind
            for (const [key, value] of Object.entries(parameters)) {
                template = template.replace(
                    new RegExp(`{\\s*${key}\\s*}`, 'gi'),
                    value
                );
            }
            return template;
        },

        translateResponse(responseObj: ResponseObject) {
            let origin =
                responseObj.origin_city + ', ' + responseObj.origin_state;
            let destination =
                responseObj.destination_city +
                ', ' +
                responseObj.destination_state;
            return {
                origin: origin,
                destination: destination,
                date: responseObj.origin_close_day,
                equipmentType: 'Reefer',
                price: parseFloat(responseObj.mlPrice).toFixed(2)
            };
        },

        timeAgo(timestamp: number) {
            return timestamp - Date.now();
        },

        copyMessage(message: string) {
            navigator.clipboard.writeText(message);
            document.getElementById('copiedMessage')?.classList.add('show');
            setTimeout(function () {
                document
                    .getElementById('copiedMessage')
                    ?.classList.remove('show');
            }, 2000);
        },

        addToVisEvents(event: any) {
            if (this.vis_events.length == 0) {
                this.vis_events.push(event);
                return;
            }

            var last_event = this.vis_events.length - 1;
            if (event.type == this.vis_events[last_event].type) {
                this.vis_events.push(event);
            }
        },

        updateCollapsevalue(value: boolean) {
            this.collapsed = value;
        },

        sanitizeString(text: string) {
            let pattern = /[<>{}()"'`;/\\]/g;
            if (text) {
                return text.replace(pattern, '');
            } else {
                return text;
            }
        },

        clearMessages() {
            this.messages.splice(0);
            this.msgCounter = 0;
            this.firstMessageSent = false;
            this.messagesKey += 1;
        },

        roundTimeseries(data: any) {
            let cleaned = [];
            for (const item of data) {
                cleaned.push([(item[0] | 0) * 1000, item[1].toFixed(2)]);
            }
            return cleaned;
        },

        attachGraph(data: any) {
            this.pushMessage(
                false,
                {
                    name: data.origin_city + '↔' + data.destination_city,
                    series: [
                        {
                            data: this.roundTimeseries(data.time_series),
                            name:
                                data.origin_city + '↔' + data.destination_city
                        }
                    ]
                },
                'graph'
            );
        },

        getCookieValue(cookieName: string) {
            var allCookies = document.cookie.split(';');
            for (var i = 0; i < allCookies.length; i++) {
                var cookie = allCookies[i].trim();
                if (cookie.startsWith(cookieName + '=')) {
                    return cookie.substring(cookieName.length + 1);
                }
            }
            return null;
        },
        handleInputBlur() {
            const textField = this.$refs.textField as any;
            textField.$el.classList.remove('highlight');
        },

        handleInputFocus() {
            this.$nextTick(() => {
                const textField = this.$refs.textField as any;
                textField.$el.classList.add('highlight');
            });
        },

        handleHitCount(event: any) {
            let response_dict = JSON.parse(event.data);
            let keys = Object.keys(response_dict);
            if (
                keys.includes('event') &&
                response_dict['event']['type'] == 'hits'
            ) {
                var event_content = response_dict['event'];
                this.hitCount = event_content.data;
            }
        },

        redirectToSignup() {
            this.$router.push({
                name: 'signup',
                params: { signUpInitialRedirection: 'true' }
            });
        }
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        submittable() {
            if (!this.submitLocked && this.text !== '' && this.text !== null && this.hitCount < this.queryLimit) {
                return true;
            } else {
                return false;
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        textCounter() {
            const textLength: string = this.text.length.toString();
            return textLength + '/1024';
        },
        isUserLoaded() {
            if (Object.keys(this.user_info).length !== 0) {
                return true;
            }
            return false;
        },
        lastResponseIndex() {
            for (let i = (this as any).messages.length - 1; i >= 0; i--) {
                if (this.messages[i].message_type === 'text') return i;
            }
            return 1;
        }
    },

    beforeMount() {
        this.lang_chain_socket = new LangChainSocket();
        this.currentSocket = this.lang_chain_socket.sendMessage(
            { action: this.hit_count_endpoint },
            this.handleHitCount
        );
        fetchPreloadData(this.page_name).then((data) => {
            let toLoad = data[Math.floor(Math.random() * data.length)];
            this.messages.push({
                id: this.generateRandomString(this.msgCounter + 1),
                message_id: null,
                isQuery: false,
                sentiment: null,
                timestamp: Date.now(),
                content: { text: toLoad.tokens },
                copyDisabled: false,
                message_type: 'text'
            });
            this.placeholder = toLoad.prompt;
            this.textFieldKey += 1;
            if (!this.isMobile) {
                this.$nextTick(() => {
                    let textArea = document.querySelector('.textField textarea');
                    (textArea as HTMLElement).focus();
                });
            }
            for (const event of toLoad.events) {
                if (
                    ['pricer_polyline', 'gmaps', 'windy', 'polygon'].includes(
                        event.type
                    )
                ) {
                    this.addToVisEvents(event);
                } else if (event.type == 'transit_markers') {
                    this.transit_markers.push(event.data);
                } else if (event.type == 'carrier_search_results') {
                    this.pushCarrierSearch(event.data);
                } else if (event.type == 'error') {
                    this.errorMessage = event.data;
                } else if (event.type === 'graph_series') {
                    this.attachGraph(event.data);
                }
            }
            this.pushVisEvent(this.vis_events);
        });
    },

    mounted() {
        this.text = '';

        this.textField = this.$refs.textField as any;
        if (!this.isMobile) {
            this.textField.focus();
        }

        // Set ALB Cookie
        this.alb_cookie_value = `ALB=${this.getCookieValue('ALB')};`;
    },

    data: function () {
        return {
            queryLimit: 5,
            transit_markers: [] as any,
            collapsed: false,
            textField: null as any,
            textFieldKey: 0 as number,
            hitCount: '-' as string | number,
            prevMsgHeight: 0 as number | undefined,
            chat_endpoint: isDevEnv()
                ? APIEndpoints.dev.chat_endpoint
                : APIEndpoints.prod.chat_endpoint,
            hit_count_endpoint: isDevEnv()
                ? APIEndpoints.dev.hit_count_endpoint
                : APIEndpoints.prod.hit_count_endpoint,
            currentSocket: null as any,
            active: true,
            newSessionBlocked: false,
            text: '' as string,
            placeholder: '' as string,
            submitLocked: false,
            msgLoading: false,
            eventcounter: 0,
            loadingPhrases: [''] as any[],
            loadingPhraseKey: 0,
            messages: [] as any[],
            messagesKey: '',
            isCopyDisabled: true,
            awaitingResponse: false,
            loadText: 'Calculating',
            errorMessage: '',
            limitError: false,
            msgCounter: 0,
            lang_chain_socket: null as unknown as LangChainSocket,
            contactFormValid: false,
            helpMenu: false,
            snackbar: false,
            snackText: '',
            snackColor: 'green',
            user_info: {} as UserInterface,
            curr_session_id: null,
            curr_conversation_id: null,
            isNewConv: true,
            firstMessageSent: false,
            noTransition: false,
            vis_events: [] as any[],
            marker_data: {} as any,
            carrierSearchResults: null as any,
            curr_parent_run_id: '',
            tu_td_single_style: 'd-flex flex-column tu-td-single',
            tu_td_double_style: 'd-flex flex-column tu-td-double',
            summaryShow: null as any,
            alb_cookie_value: null as any,
            graph_data: [] as any,
            highlightIcon: true
        };
    }
});
</script>
<style scoped>
#rootContainer {
    border-radius: 20px;
    background: transparent;
    height: fit-content;
    z-index: 4;
    position: relative;
    width: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.5s ease;
}

#rootContainer.mobile {
    border-radius: 0;
    position: fixed;
    top: auto;
    bottom: 0;
    height: 100%;
    z-index: 4;
    width: 100%;
    margin-right: 0;
    padding-bottom: 12px;
    padding-top: 0px;
}

.textField.active {
    top: 0;
    transition-duration: 0.8s;
    transition-property: top;
}
.textField {
    height: fit-content;
    overflow: visible;
    flex-grow: 0;
    border-radius: 8px;
    position: relative;
    width: 100%;
    background: #0f141e;
    color: whitesmoke;
    margin: 2px;
    font-size: 16px;
}
.textField.highlight {
    border-color: rgb(160, 160, 160);
}
.textField.highlight .mdi-send:not(.locked) {
    color: rgb(244, 244, 244) !important;
}
.textField ::v-deep .v-input__slot {
    border-radius: 8px;
    padding-left: 26px !important;
    background-color: transparent !important;
}
.textField ::v-deep textarea {
    align-self: center;
    max-height: 300px;
    margin-top: 0.6em !important;
    margin-bottom: 0.6em !important;
    line-height: 2rem;
    color: rgb(228, 228, 228) !important;
    caret-color: rgb(228, 228, 228);
    overflow-y: auto;
}
.textField::-webkit-scrollbar-corner {
    display: none;
}
.textField textarea::-webkit-scrollbar {
    width: 0.5rem !important;
}
.textField textarea::-webkit-scrollbar-track {
    background: #434141;
    border-radius: 0.815rem;
}
.textField textarea::-webkit-scrollbar-thumb {
    border-radius: 0.815rem;
    background-color: #656565;
}
::v-deep .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.textField ::v-deep .v-btn__content {
    opacity: 1 !important;
}

.text-field-wrapper {
    width: 100%;
    height: fit-content;
    position: relative;
    background: linear-gradient(to left, #0366f9, #ad00ff);
    border-radius: 8px;
    display: flex;
}

#logContainer {
    width: 100%;
    display: none;
    /* overflow-y: auto; */
    max-width: none;
    position: relative;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
}
#logContainer.mobile {
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: none;
    position: relative;
    justify-content: end;
    align-items: center;
    margin-bottom: 50px;
}
#logContainer.active {
    display: block;
}

.message.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.fade-in {
    animation: fadeIn 0.8s ease-in;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in-fast {
    animation: fadeIn 0.4s ease-in;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fast-hide {
    display: none;
}

#chatLog {
    box-sizing: border-box;
    scroll-behavior: smooth;
    max-width: none;
    height: max-content;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    margin-bottom: 1.6em;
}

.message {
    min-height: 3em !important;
    margin-bottom: 8px;
}
.message > div {
    color: white !important;
}

.message-token-warning {
    min-height: 3em !important;
    margin-bottom: 8px;
    border: 2px solid #f57f17 !important;
    background-color: rgba(245, 160, 23, 0.3) !important;
}

/* * {
    outline: 0.2px solid aliceblue;
} */

.mobileOptions.v-btn--icon.v-size--default {
    height: 28px;
    width: 32px;
}

html {
    /* scrollbar-gutter: stable; */
    overflow-y: scroll;
    overflow-x: hidden;
    background: #201f1f;
}

.loading {
    font-size: 15px;
    color: white;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

#sendBtn:not(.clickable) {
    cursor: auto !important;
}

.sender-icon {
    background-color: transparent !important;
    color: white !important;
    border-radius: 5px !important;
    border-width: 1px;
    border-color: #656565;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

#copiedMessage {
    display: none;
    position: fixed !important;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
#copiedMessage.show {
    display: unset;
}

.tu-td-double {
    position: absolute;
    right: -44px;
    top: -5px;
    z-index: 5;
    padding-right: 0px;
}

.tu-td-single {
    position: absolute;
    right: -44px;
    top: 8px;
    z-index: 5;
    padding-right: 0px;
}

.warning-border {
    border: 2px solid #f57f17;
    border-radius: 15%;
    padding: 8px; /* Adjust the padding value as needed */
}

.login-button {
    background: #07090e;
    color: whitesmoke;
    margin: 1.6px;
    font-size: 14px;
}

.login-button-wrapper {
    max-width: fit-content;
    width: fit-content;
    height: fit-content;
    position: relative;
    background: linear-gradient(to left, #0366f9, #ad00ff);
    border-radius: 7%;
    margin-top: 1px;
    margin-left: 10px;
}

/* @media screen and (max-width: 600px) {
    #rootContainer {
        margin-left: auto !important;
    }
} */
.static-loader{
    padding-left: 6px;
    width: 30px;
    height: 30px;
}
.loader {
    position: relative;
    width: 22px;
    height: 22px;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.loader::before {
    width: 20px;
    height: 20px;
}

.loader::after {
    width: 15px;
    height: 15px;
}

.dot {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    animation: dotAnimation 1.5s infinite ease-in-out;
}

@keyframes dotAnimation {
    0%, 100% {
    transform: translate(-50%, -50%) scale(1);
    }
    50% {
    transform: translate(-50%, -50%) scale(0.5);
    }
}

.dot:nth-child(1) { top: 0%; left: 50%; animation-delay: -1.2s; }
.dot:nth-child(2) { top: 14.64%; left: 85.36%; animation-delay: -1.1s; }
.dot:nth-child(3) { top: 50%; left: 100%; animation-delay: -1s; }
.dot:nth-child(4) { top: 85.36%; left: 85.36%; animation-delay: -0.9s; }
.dot:nth-child(5) { top: 100%; left: 50%; animation-delay: -0.8s; }
.dot:nth-child(6) { top: 85.36%; left: 14.64%; animation-delay: -0.7s; }
.dot:nth-child(7) { top: 50%; left: 0%; animation-delay: -0.6s; }
.dot:nth-child(8) { top: 14.64%; left: 14.64%; animation-delay: -0.5s; }
</style>
