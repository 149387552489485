import axios, { AxiosResponse } from 'axios';
import { PROD_CONVERSATIONS, DEV_CONVERSATIONS } from './resources';

interface PostParams {
    [key: string]: any;
}

async function makePostRequest(
    params: PostParams,
    isDev: boolean
): Promise<AxiosResponse> {
    const conversation_endpoint = isDev
        ? DEV_CONVERSATIONS
        : PROD_CONVERSATIONS;
    const response = await axios.post(conversation_endpoint, params);
    return response;
}

export { makePostRequest };
