<template>
    <svg
        viewBox="123.395 187.56 97.508 120.841"
        xmlns="http://www.w3.org/2000/svg"
        :style="svg_style"
    >
        <path
            fill="#348EFF"
            opacity="1.000000"
            stroke="#348EFF"
            stroke-width="2"
            d="M 129.439 212.222 C 135.548 208.878 166.552 188.747 172.689 188.695 C 178.606 188.645 184.596 194.596 190.514 197.979 C 198.748 202.686 206.89 207.556 215.191 212.141 C 217.668 213.509 218.789 214.983 218.762 217.936 C 218.616 233.426 218.765 248.92 218.591 264.41 C 218.572 266.061 217.572 268.459 216.271 269.225 C 195.188 281.628 173.452 293.56 152.262 305.781 C 151.89 305.996 151.887 306.194 150.912 306.329 C 152.406 300.452 153.686 294.804 155.314 289.259 C 156.171 286.339 155.53 284.789 152.786 283.303 C 144.887 279.026 137.222 274.318 129.349 269.989 C 126.809 268.592 125.97 266.9 125.995 264.045 C 126.128 248.721 126.123 233.394 125.997 218.07 C 125.974 215.205 126.869 213.601 129.441 212.21 M 142.031 219.079 C 138.813 220.26 137.673 222.396 137.762 225.953 C 138.022 236.268 137.975 246.596 137.781 256.914 C 137.722 260.019 138.832 261.742 141.465 263.197 C 150.78 268.347 159.929 273.8 169.298 278.847 C 170.839 279.677 173.558 279.778 175.068 278.972 C 184.602 273.885 194.037 268.591 203.279 262.994 C 204.988 261.96 206.612 259.265 206.673 257.287 C 207.004 246.642 206.997 235.977 206.681 225.332 C 206.622 223.333 205.125 220.591 203.45 219.556 C 194.537 214.054 185.283 209.109 176.309 203.701 C 173.307 201.893 170.987 202.075 168.098 203.825 C 159.705 208.908 151.162 213.743 142.031 219.079 Z"
        />
        <path
            fill="#F1F0F0"
            opacity="1.000000"
            stroke="none"
            d="M 142.354 218.878 C 151.162 213.743 159.705 208.908 168.098 203.825 C 170.987 202.075 173.307 201.893 176.309 203.701 C 185.283 209.109 194.537 214.054 203.45 219.556 C 205.125 220.591 206.622 223.333 206.681 225.332 C 206.997 235.977 207.004 246.642 206.673 257.287 C 206.612 259.265 204.988 261.96 203.279 262.994 C 194.037 268.591 184.602 273.885 175.068 278.972 C 173.558 279.778 170.839 279.677 169.298 278.847 C 159.929 273.8 150.78 268.347 141.465 263.197 C 138.832 261.742 137.722 260.019 137.781 256.914 C 137.975 246.596 138.022 236.268 137.762 225.953 C 137.673 222.396 138.813 220.26 142.354 218.878 M 175.627 235.493 C 182.455 231.537 189.284 227.581 196.724 223.271 C 188.784 218.684 181.663 214.523 174.477 210.477 C 173.58 209.972 172.056 209.559 171.306 209.975 C 163.741 214.164 156.277 218.536 148.029 223.292 C 156.183 228.03 163.274 232.28 170.531 236.228 C 171.638 236.831 173.506 236.034 175.627 235.493 M 176.878 270.255 C 182.096 267.362 187.127 264.037 192.58 261.691 C 198.629 259.09 200.856 255.274 200.141 248.694 C 199.477 242.592 199.999 236.36 199.999 229.221 C 191.827 233.884 184.756 237.817 177.828 241.988 C 176.832 242.587 176.005 244.331 175.992 245.556 C 175.9 253.677 176.051 261.801 176.878 270.255 M 148.909 231.512 C 147.681 230.984 146.453 230.456 144.659 229.685 C 144.659 238.716 144.612 246.849 144.72 254.98 C 144.733 255.977 145.304 257.424 146.079 257.884 C 153.436 262.255 160.894 266.458 169.058 271.13 C 169.058 262.558 168.919 254.961 169.126 247.373 C 169.209 244.346 168.347 242.535 165.584 241.082 C 160.152 238.224 154.932 234.963 148.909 231.512 Z"
        />
        <path
            fill="#292728"
            opacity="1.000000"
            stroke="none"
            d="M 175.325 235.689 C 173.506 236.034 171.638 236.831 170.531 236.228 C 163.274 232.28 156.183 228.03 148.029 223.292 C 156.277 218.536 163.741 214.164 171.306 209.975 C 172.056 209.559 173.58 209.972 174.477 210.477 C 181.663 214.523 188.784 218.684 196.724 223.271 C 189.284 227.581 182.455 231.537 175.325 235.689 Z"
        />
        <path
            fill="#272526"
            opacity="1.000000"
            stroke="none"
            d="M 176.5 270.089 C 176.051 261.801 175.9 253.677 175.992 245.556 C 176.005 244.331 176.832 242.587 177.828 241.988 C 184.756 237.817 191.827 233.884 199.999 229.221 C 199.999 236.36 199.477 242.592 200.141 248.694 C 200.856 255.274 198.629 259.09 192.58 261.691 C 187.127 264.037 182.096 267.362 176.5 270.089 Z"
        />
        <path
            fill="#272525"
            opacity="1.000000"
            stroke="none"
            d="M 149.266 231.69 C 154.932 234.963 160.152 238.224 165.584 241.082 C 168.347 242.535 169.209 244.346 169.126 247.373 C 168.919 254.961 169.058 262.558 169.058 271.13 C 160.894 266.458 153.436 262.255 146.079 257.884 C 145.304 257.424 144.733 255.977 144.72 254.98 C 144.612 246.849 144.659 238.716 144.659 229.685 C 146.453 230.456 147.681 230.984 149.266 231.69 Z"
        />
    </svg>
</template>

<style scoped>
svg {
    fill: white;
    stroke: white;
}
</style>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'LogoSvgComponent',
    props: ['height', 'width'],

    mounted() {
        this.svg_style = `"height:${this.height}; width:${this.width};"`;
        this.$forceUpdate();
    },

    data: function () {
        return {
            svg_style: '' as string
        };
    }
});
</script>
