<template>
    <div :id="id">
        <div
            :class="
                !dialog ? 'map-container-reduced' : 'map-container-expanded'
            "
            @click="handleDocumentClick"
        >
            <span @click.stop>
                <GmapMap
                    :center="center"
                    :zoom="10"
                    :style="
                        'width: 100%; height:' + (dialog ? '100%' : '300px')
                    "
                    ref="gmap"
                    :options="mapOptions"
                    class="map"
                >
                    <!-- <GmapPolygon
                v-for="(path, index) in polygonPaths"
                :path="path"
                :key="index"
                :options="{
                    fillColor: colors[index],
                    fillOpacity: 0.5,
                    strokeColor: colors[index],
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                }"
            ></GmapPolygon> -->
                    <!-- <GmapMarker
                v-for="(marker, index) in polygonPaths"
                :key="index"
                :position="marker[0]"
            ></GmapMarker> -->
                </GmapMap>
                <div :class="!isMobile ? 'hover-div' : 'hover-div-mobile'">
                    <div class="legend-item">
                        <span class="color-box red-box"></span
                        ><span class="legend-text">High Occurrence</span>
                    </div>
                    <div class="legend-item">
                        <span class="color-box orange-box"></span
                        ><span class="legend-text">Medium Occurrence</span>
                    </div>
                    <div class="legend-item">
                        <span class="color-box blue-box"></span
                        ><span class="legend-text">Low Occurrence</span>
                    </div>
                </div>
                <div class="expand-icon" v-if="!isMobile">
                    <v-btn
                        icon
                        @click="expandMaps"
                        class="mt-1"
                        style="color: white"
                    >
                        <v-icon v-if="dialog" medium color="">
                            mdi-arrow-collapse
                        </v-icon>
                        <v-icon v-else size="22" color="">
                            mdi-arrow-expand
                        </v-icon>
                    </v-btn>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import * as decoder from './polyline_utils/decoder';
import createCustomOverlayClass from './polyline_utils/custom_google_overlay';
import gmaps_styling from './polyline_utils/gmaps_style';

export default {
    name: 'GMapPolygon',
    props: ['polygonEvents', 'isMobile', 'id'],
    mounted() {
        this.$refs.gmap.$mapPromise.then(() => {
            if (typeof google === 'undefined') {
                console.error('Google Maps API has not been loaded yet!');
            } else {
                var global_bounds = this.computePolygons();
                var adjustedBounds = this.shrinkBounds(global_bounds);

                // The Google Maps API has been loaded and is ready to use.
                // eslint-disable-next-line
                /* eslint-disable */
                const ne = new google.maps.LatLng(
                    adjustedBounds.ne[0],
                    adjustedBounds.ne[1]
                );
                const sw = new google.maps.LatLng(
                    adjustedBounds.sw[0],
                    adjustedBounds.sw[1]
                ); // Example: Los Angeles
                this.setBounds = new google.maps.LatLngBounds(sw, ne);

                this.$refs.gmap.$mapObject.fitBounds(this.setBounds);

                // Add polygons to map.
                this.addPolygons();
            }
        });
    },
    data: function () {
        return {
            legend: [
                { max: 2, color: '#3281B6' },
                { max: 5, color: '#D49500' },
                { max: Infinity, color: '#FD7C7C' }
            ],
            tooltip: {
                crash_county: 'Crashes:',
                inspection_county: 'Inspections:'
            },
            setBounds: [],
            polygon_type: '',
            dialog: false,
            polygon_events: this.polygonEvents,
            polygonPaths: [],
            center: { lat: 10, lng: 10 },
            mapOptions: {
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                draggable: true,
                styles: gmaps_styling
            }
        };
    },
    methods: {
        handleDocumentClick() {
            this.expandMaps();
        },
        expandMaps() {
            this.dialog = !this.dialog;
            setTimeout(() => {
                this.$refs.gmap.$mapObject.fitBounds(this.setBounds);
                const container = document.getElementById(this.id);
                if (container) {
                    container.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    });
                }
            }, 100);
        },
        computePolygons() {
            let globalBounds = {
                minLat: Infinity,
                maxLat: -Infinity,
                minLng: Infinity,
                maxLng: -Infinity
            };
            this.polygon_events.forEach((event) => {
                for (let county in event.data.coordinates) {
                    let all_county_coordinates =
                        event.data.coordinates[county].coordinates;
                    for (let i = 0; i < all_county_coordinates.length; i++) {
                        let county_coordinates = all_county_coordinates[i];

                        if (typeof county_coordinates[0] === 'string') {
                            const latLngFloats = county_coordinates.map(
                                (str) => {
                                    const [lat, lng] = str
                                        .replace(/[()]/g, '')
                                        .split(', ');
                                    return [parseFloat(lat), parseFloat(lng)];
                                }
                            );

                            county_coordinates = latLngFloats;
                        }

                        let countyBounds =
                            decoder.compute_bounds_from_list(
                                county_coordinates
                            );

                        // Update global bounds
                        globalBounds.minLat = Math.min(
                            globalBounds.minLat,
                            countyBounds.sw[0]
                        );
                        globalBounds.maxLat = Math.max(
                            globalBounds.maxLat,
                            countyBounds.ne[0]
                        );
                        globalBounds.minLng = Math.min(
                            globalBounds.minLng,
                            countyBounds.sw[1]
                        );
                        globalBounds.maxLng = Math.max(
                            globalBounds.maxLng,
                            countyBounds.ne[1]
                        );

                        // Add polygon
                        var [path, center] = decoder.formatGooglePolygonArray(
                            county_coordinates,
                            google
                        );

                        // Color polygon
                        let color_to_append = '#3281B6';
                        for (let threshold of this.legend) {
                            if (
                                event.data.coordinates[county].count <=
                                threshold.max
                            ) {
                                color_to_append = threshold.color;
                                break;
                            }
                        }

                        // Push to polygons array
                        this.polygonPaths.push({
                            path: path,
                            center: center,
                            name: county,
                            count: event.data.coordinates[county].count,
                            color: color_to_append
                        });
                        this.polygon_type = event.data.region;
                    }
                }
            });

            return {
                sw: [globalBounds.minLat, globalBounds.minLng],
                ne: [globalBounds.maxLat, globalBounds.maxLng]
            };
        },

        shrinkBounds(bounds, shrinkFactor = 0.1) {
            let latDiff = bounds.ne[0] - bounds.sw[0];
            let lngDiff = bounds.ne[1] - bounds.sw[1];

            return {
                ne: [
                    bounds.ne[0] - latDiff * shrinkFactor,
                    bounds.ne[1] - lngDiff * shrinkFactor
                ],
                sw: [
                    bounds.sw[0] + latDiff * shrinkFactor,
                    bounds.sw[1] + lngDiff * shrinkFactor
                ]
            };
        },

        formatCountyString(location) {
            let parts = location.split(',');
            if (parts.length !== 2) {
                // The string format is not as expected
                return location;
            }

            let county = parts[0].trim();
            let state = parts[1].trim();

            // Capitalize the first letter of the county
            county = county.charAt(0).toUpperCase() + county.slice(1);

            // Reassemble the string
            return `${county} County, ${state}`;
        },

        createInfoWindow(county_name, occurrences) {
            var content = `
            <div style="background-color: rgba(15,15,15,0.75); padding: 10px; padding-bottom:1px;">
                <h3>${this.formatCountyString(county_name)}</h3>
                <p style="margin-top:5px">${
                    this.tooltip[this.polygon_type]
                } ${occurrences}</p>
            </div>
            `;
            return content;
        },

        addPolygons() {
            // Create an InfoWindow
            // const infoWindow = new google.maps.InfoWindow();

            // Loop through your polygons
            this.polygonPaths.forEach((path, index) => {
                // Convert path to LatLng objects
                // const googlePath = path.map(p => new google.maps.LatLng(p["lat"], p["lng"]));

                // Create a polygon
                const polygon = new google.maps.Polygon({
                    paths: path.path,
                    fillColor: path.color,
                    fillOpacity: 0.5,
                    strokeColor: path.color,
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                });

                // Add polygon to map
                polygon.setMap(this.$refs.gmap.$mapObject);

                // Polygon popups
                var content = this.createInfoWindow(path.name, path.count);
                const CustomOverlay = createCustomOverlayClass(google);
                var customOverlay = new CustomOverlay(
                    this.$refs.gmap.$mapObject,
                    path.center,
                    content
                );
                customOverlay.setMap(null);

                // Add mouseover listener for the polygon
                polygon.addListener('mouseover', () => {
                    console.log('mouseover');
                    // infoWindow.setContent('Content for polygon ' + index); // Set the content
                    // infoWindow.setPosition(center); // Set the position of the InfoWindow
                    // infoWindow.open(this.$refs.gmap.$mapObject);
                    customOverlay.setMap(this.$refs.gmap.$mapObject);
                });

                // Add mouseout listener to close the InfoWindow
                polygon.addListener('mouseout', () => {
                    // infoWindow.close();
                    customOverlay.setMap(null);
                });
            });
        }
    }
};
</script>

<style scoped>
/* Change to responsive units */
.map-container-reduced {
    position: relative;
    width: 100%;
    height: 300px;
}
.map-container-expanded {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 111;
    border: 80px solid rgba(255, 255, 255, 0.5);
    outline: 10px solid rgba(255, 255, 255, 0.5); /* Adjust alpha channel for transparency */
    background: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.expand-icon {
    position: absolute;
    top: 0px;
    right: 4px;
    background-color: transparent;
    z-index: 10;
}

.map {
    width: 100%;
    height: 100%;
}

.hover-div {
    position: absolute;
    top: 10px; /* Adjust as necessary */
    left: 1%; /* Adjust as necessary */
    width: 15%; /* Set to the width of your floating div */
    height: 20%; /* Set to the height of your floating div */
    background-color: transparent; /* Or any color you wish */
    z-index: 10; /* Make sure this is above the map's z-index */
    /* More styling for your div */
}
.hover-div-mobile {
    position: absolute;
    top: 10px;
    left: 60%;
    width: 39%;
    height: 20%;
    background-color: transparent;
    z-index: 10;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Space between legend items */
}

.color-box {
    width: 20px; /* Width of the color box */
    height: 20px; /* Height of the color box */
    margin-right: 10px; /* Space between the color box and text */
}

.red-box {
    background-color: rgba(253, 124, 124, 0.5);
    border: 3px solid rgba(253, 124, 124, 0.8);
}

.blue-box {
    background-color: rgba(50, 129, 182, 0.5);
    border: 3px solid rgba(50, 129, 182, 1);
}

.orange-box {
    background-color: rgba(212, 149, 0, 0.5);
    border: 3px solid rgba(212, 149, 0, 0.9);
}

.legend-text {
    /* Additional styling for the text if needed */
    font-size: 12px;
}

.v-btn:before {
    background-color: transparent !important;
}
</style>
