var render = function render(){var _vm=this,_c=_vm._self._c;return _c('apexchart',{attrs:{"type":"area","height":"300px","series":_vm.series,"options":{
        ..._vm.graphOptions,
        title: {
            ..._vm.graphOptions.title,
            text: _vm.name
        }
    }}})
}
var staticRenderFns = []

export { render, staticRenderFns }