<template>
    <div>
        <div class="d-flex ml-n3 mb-2 align-center justify-space-between">
            <div style="color: #545454">
                <v-btn icon @click="emitBack">
                    <v-icon size="27" color="#545454">
                        mdi-arrow-left-thin
                    </v-icon>
                </v-btn>
                {{ current_email }}
            </div>
        </div>
        <v-text-field
            prepend-inner-icon="mdi-lock-outline"
            type="password"
            label="Password"
            outlined
            dark
            :error-messages="errors"
            v-model="password"
            @input="emitPassword"
            @keyup.enter="emitLogin"
            autofocus
        ></v-text-field>
        <div
            v-if="showForgetPassword"
            class="d-flex justify-end mt-n6 mb-2"
            style="font-size: 0.8rem"
        >
            <a @click="emitForgetPassword" style="z-index: 10"
                >Forgot Password?</a
            >
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'PasswordField',
    props: ['current_email', 'errors', 'showForgetPassword'],

    components: {},

    methods: {
        emitPassword() {
            this.$emit('passwordUpdate', this.password);
        },

        emitLogin(e: any) {
            //sign in if enter is pressed
            console.log(e);
            this.$emit('triggerLogin');
        },

        emitBack() {
            this.$emit('backUpdate');
        },

        emitForgetPassword() {
            this.$emit('forgetPassword');
        }
    },

    data: function () {
        return {
            password: ''
        };
    }
});
</script>

<style>
.link {
    font-size: 13px;
    color: grey;
}

.forgot-password {
    float: right;
    cursor: pointer;
}

.v-input__append-inner {
    margin-top: 12px !important;
}
</style>
