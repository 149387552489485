<template>
    <apexchart
        type="area"
        height="300px"
        :series="series"
        :options="{
            ...graphOptions,
            title: {
                ...graphOptions.title,
                text: name
            }
        }"
    >
    </apexchart>
</template>

<script lang="ts">
import { formatDollars } from './formatUtils';
export default {
    name: 'HistPriceGraph',
    props: ['series', 'name'],
    methods:{
        formatDollars
    },
    data() {
        return {
            graphOptions: {
                chart: {
                    height: '300px',
                    width: '100%',
                    redrawOnParentResize: true,
                    selection: {
                        enabled: false
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: true
                        }
                    },
                    zoom: {
                        enabled: true
                    }
                },
                theme: {
                    palette: 'palette2',
                    mode: 'dark'
                },
                fill: {
                    colors: ['#3181b6'],
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        shadeIntensity: 0.5,
                        opacityFrom: 0.7,
                        opacityTo: 0.1,
                        stops: [0, 100],
                        type: 'vertical'
                    }
                },
                stroke: {
                    curve: 'monotoneCubic'
                },
                yaxis: {
                    decimalsInFloat: 2,
                    labels: {
                        style: {
                            colors: '#FFFFFF'
                        },
                        formatter: function (val: string) {
                            return formatDollars(val, 2, true);
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors: '#FFFFFF'
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    y: {
                        formatter: function (value: string) {
                            return formatDollars(value, 2, true);
                        },
                        title: {
                            formatter: function () {
                                return 'Price: ';
                            }
                        }
                    },
                    x: {
                        show: true,
                        formatter: function (value: string) {
                            return new Date(value).toLocaleDateString();
                        },
                        title: {
                            formatter: function () {
                                return 'Date';
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                title: {
                    offsetX: 4,
                    align: 'left',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'normal',
                        fontFamily: 'Roboto, sans-serif',
                        color: '#FFFFFF'
                    }
                }
            }
        };
    }
};
</script>

<style>
.apexcharts-zoom-icon.apexcharts-selected {
    display: none;
}

.apexcharts-svg {
    background: transparent !important;
}
</style>
