<template>
    <div :id="id" :class="dialog ? 'container-2' : ''">
        <div
            :class="
                !dialog
                    ? type !== 'GmapPricer'
                        ? ''
                        : 'map-container-reduced'
                    : 'map-container-expanded'
            "
            @click="handleDocumentClick"
        >
            <span @click.stop style="position: relative">
                <span
                    v-if="dialog"
                    class="gmap-heading"
                >
                    <span>Lane Map</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="expandMaps"
                        class="mt-1"
                        style="color: white; padding-bottom: 6px"
                    >
                        <v-icon medium color="">
                            mdi-arrow-collapse-all
                        </v-icon>
                    </v-btn>
                </span>
                <GmapMap
                    :center="center"
                    :zoom="100"
                    :style="
                        'width: 100%; margin-top:' +
                        (dialog
                            ? '0px'
                            : type != 'GmapPricer'
                            ? '0px'
                            : '10px') +
                        ';height:' +
                        (dialog
                            ? '100%'
                            : type == 'GmapPricer'
                            ? '250px'
                            : '300px')
                    "
                    ref="gmap"
                    :options="mapOptions"
                    @mousemove="handleMapMouseMove"
                    @click="handleMapClick"
                >
                    <span
                        v-for="(polylinePath, index) in polylinePaths"
                        :key="index"
                    >
                        <GmapPolyline
                            :path="polylinePath"
                            v-if="
                                type == 'GmapPricer' && !dialog
                                    ? displayIndex == index
                                    : true
                            "
                            :options="{
                                strokeColor: polylineColors[index] || '#D49500',
                                strokeOpacity: 1.0,
                                strokeWeight: isMobile ? 5 : 4
                            }"
                            @click="handlePolylineClick($event, index)"
                            @mouseover="handlePolylineMouseOver($event, index)"
                            @mouseout="handlePolylineMouseOut($event)"
                        ></GmapPolyline>
                    </span>
                    <span
                        v-for="(polylinePath, index) in polylinePaths"
                        :key="'startMarker_' + index"
                    >
                        <GmapMarker
                            v-if="
                                type == 'GmapPricer' && !dialog
                                    ? displayIndex == index
                                    : true
                            "
                            :position="polylinePath[0]"
                            :options="{
                                icon: {
                                    path: 'M -5,0 A 5,5 0 1,1 5,0 A 5,5 0 1,1 -5,0 Z',
                                    fillColor: polylineColors[index],
                                    fillOpacity: 1,
                                    strokeWeight: 3
                                }
                            }"
                            @click="showEndMarkerInfo(index, 'start')"
                            @mouseover="
                                handleMarkerMouseOver($event, index, 'start')
                            "
                        >
                        </GmapMarker>
                    </span>
                    <span
                        v-for="(polylinePath, index) in polylinePaths"
                        :key="'endMarker_' + index"
                    >
                        <GmapMarker
                            v-if="
                                type == 'GmapPricer' && !dialog
                                    ? displayIndex == index
                                    : true
                            "
                            :position="polylinePath[polylinePath.length - 1]"
                            :options="{
                                icon: {
                                    path: 'M -5,0 A 5,5 0 1,1 5,0 A 5,5 0 1,1 -5,0 Z',
                                    fillColor: polylineColors[index],
                                    fillOpacity: 1,
                                    strokeWeight: 3
                                }
                            }"
                            @click="showEndMarkerInfo(index, 'end')"
                            @mouseover="
                                handleMarkerMouseOver($event, index, 'end')
                            "
                        >
                        </GmapMarker>
                    </span>
                    <span
                        v-for="(markerInfo, ind) in transitMarkers"
                        :key="'stopMarker_' + ind"
                    >
                        <GmapMarker
                            v-for="(marker, index) in markerInfo.markers"
                            :key="'stopMarker_' + index"
                            :position="marker"
                            :options="{
                                icon: {
                                    path: markers_only
                                        ? 'M -6,0 A 6,6 0 1,1 6,0 A 6,6 0 1,1 -6,0 Z'
                                        : 'M -5,0 A 5,5 0 1,1 5,0 A 5,5 0 1,1 -5,0 Z',
                                    fillColor: polylineColors[ind],
                                    fillOpacity: 1,
                                    strokeWeight: 2.5
                                }
                            }"
                            @click="showMarkerInfo(index, marker)"
                            @mouseover="
                                handleMarkerMouseOver(
                                    $event,
                                    index,
                                    'middle_' + ind
                                )
                            "
                        >
                        </GmapMarker>
                    </span>
                    <span v-if="marker_data">
                        <GmapMarker
                            v-for="(marker, index) in marker_data.markers"
                            :key="'stopMarker_' + index"
                            :position="marker"
                            :options="{
                                icon: {
                                    path: markers_only
                                        ? 'M -6,0 A 6,6 0 1,1 6,0 A 6,6 0 1,1 -6,0 Z'
                                        : 'M -5,0 A 5,5 0 1,1 5,0 A 5,5 0 1,1 -5,0 Z',
                                    fillColor: markers_only
                                        ? '#D49500'
                                        : polylineColors[index],
                                    fillOpacity: 1,
                                    strokeWeight: 2.5
                                }
                            }"
                            @click="showMarkerInfo(index, marker)"
                            @mouseover="
                                handleMarkerMouseOver($event, index, 'middle')
                            "
                        >
                        </GmapMarker>
                    </span>
                </GmapMap>
                <div
                    class="expand-icon"
                    :style="
                        'top:' +
                        (type != 'GmapPricer' || dialog ? '0px' : '-45px')
                    "
                    v-if="!isMobile && !dialog"
                >
                    <v-btn
                        icon
                        @click="expandMaps"
                        class="mt-1"
                        style="color: #8f8f8f"
                    >
                        <v-icon size="22" color="">
                            mdi-arrow-expand-all
                        </v-icon>
                    </v-btn>
                </div>
                <div
                    class="hover-div"
                    :style="dialog ? 'top: 26px;' : 'top: 4px;'"
                    v-if="!markers_only && type != 'GmapPricer'"
                >
                    <v-menu v-model="showDistanceList" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon style="color: white">
                                <v-icon>mdi-map-legend</v-icon>
                            </v-btn>
                        </template>
                        <v-list
                            style="
                                padding: 0px;
                                margin: 0px;
                                max-height: 200px;
                                background-color: rgba(15, 15, 15, 0.75);
                            "
                        >
                            <v-list-item
                                class="list-class"
                                v-for="(item, index) in distanceArray"
                                :key="index"
                            >
                                <span
                                    class="color-box"
                                    :style="{
                                        backgroundColor:
                                            polylineColors[index] ||
                                            'rgba(253, 124, 124, 0.5)',
                                        border:
                                            '3px solid ' +
                                            (polylineColors[index] ||
                                                'rgba(253, 124, 124, 0.8)')
                                    }"
                                ></span>
                                <v-list-item-content
                                    @click="focusOnPolyline(index)"
                                    class="textDisplay center-content"
                                >
                                    <span>
                                        {{ item }}
                                    </span></v-list-item-content
                                >
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import * as decoder from './polyline_utils/decoder';
import createCustomOverlayClass from './polyline_utils/custom_google_overlay';
import gmaps_styling from './polyline_utils/gmaps_style';
import _ from 'lodash';

export default {
    name: 'GMapPolyline',
    props: {
        mapEvents: {
            type: Array,
            default: () => []
        },
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        markerData: {
            type: Object,
            default: () => {}
        },
        transit_markers: {
            type: Array,
            default: () => []
        },
        displayIndex: {
            type: Number,
            default: 0
        }
    },
    watch: {
        displayIndex() {
            if (this.type == 'GmapPricer' && !this.dialog) {
                this.focusOnPolyline(this.displayIndex);
            }
        }
    },
    mounted() {
        if (this.mapEvents.length == 0 && !_.isEmpty(this.marker_data)) {
            this.markers_only = true;
        }

        var [coordinates, polylines, start_city, end_city, distanceArray] =
            decoder.coordinates_and_polylines_from_events(this.map_events);
        this.start_city = start_city;
        this.end_city = end_city;
        this.distance = distanceArray;
        if (this.marker_data?.markers) {
            coordinates = [
                ...coordinates,
                ...this.marker_data.markers.map((x) => [x.lat, x.lng])
            ];
        }
        var global_bounds = decoder.compute_bounds_from_list(coordinates);
        this.$refs.gmap.$mapPromise.then(() => {
            if (typeof google === 'undefined') {
                console.error('Google Maps API has not been loaded yet!');
            } else {
                // The Google Maps API has been loaded and is ready to use.
                // eslint-disable-next-line
                /* eslint-disable */

                // console.log(new google.maps.SymbolPath.CIRCLE);
                // const ne = new google.maps.LatLng(
                //     global_bounds.ne[0],
                //     global_bounds.ne[1]
                // ); // Example: New York
                // const sw = new google.maps.LatLng(
                //     global_bounds.sw[0],
                //     global_bounds.sw[1]
                // ); // Example: Los Angeles
                var zoomadjustment = this.markers_only ? 0.1 : 0;
                var adjustedBounds = decoder.getAdjustedPoints(
                    google,
                    global_bounds,
                    zoomadjustment
                );
                const ne = adjustedBounds['adjustedNE'];
                const sw = adjustedBounds['adjustedSW'];

                this.setBounds = new google.maps.LatLngBounds(sw, ne);
                this.$refs.gmap.$mapObject.fitBounds(this.setBounds);
                this.showDistance = true;

                for (let index = 0; index < polylines.length; index++) {
                    this.distanceArray.push(
                        `${start_city[index]} to ${end_city[index]}`
                    );
                    this.decodePolyline(polylines[index]);
                    if (
                        index == polylines.length - 1 &&
                        this.type == 'GmapPricer'
                    ) {
                        this.focusOnPolyline(this.displayIndex);
                    }
                }
            }
        });
    },
    data: function () {
        return {
            transitMarkers: this.transit_markers,
            icon: '',
            dialog: false,
            showOverlay: false,
            showDistanceList: false,
            customOverlay: null,
            hoverPosition: {},
            overlayTitle: '',
            overlayContent: '',
            polylinePaths: [],
            start_city: [],
            end_city: [],
            distance: [],
            showDistance: false,
            setBounds: [],
            polylineColors: [
                '#D49500',
                '#ff7f50',
                '#ff8c00',
                '#ff4500',
                '#cc5500',
                '#c46210',
                '#e86100',
                '#ff4f00',
                '#ff4500',
                '#ffbf00',
                '#ffa500'
            ],
            center: { lat: 10, lng: 10 },
            map_events: this.mapEvents,
            marker_data: this.markerData,
            mapOptions: {
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                draggable: true,
                styles: gmaps_styling
            },
            infoWindows: [],
            show: false,
            distanceArray: [],
            markers_only: false
        };
    },
    methods: {
        handleDocumentClick(event) {
            this.expandMaps();
        },
        decodePolyline(encodedPolyline) {
            var polylinePath = decoder.decodePolyline(encodedPolyline);
            this.polylinePaths.push(polylinePath);
        },
        expandMaps() {
            this.dialog = !this.dialog;
            if (this.type == 'GmapPricer') {
                this.$emit('updateDialogValue', this.dialog);
            }
            setTimeout(() => {
                if (this.type != 'GmapPricer' || this.dialog == true) {
                    this.$refs.gmap.$mapObject.fitBounds(this.setBounds);
                } else {
                    this.focusOnPolyline(this.displayIndex);
                }
                const container = document.getElementById(this.id);
                if (container) {
                    container.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    });
                }
            }, 100);
        },
        toggleDistanceList() {
            this.showDistanceList = !this.showDistanceList;
        },
        focusOnPolyline(index) {
            const polylinePath = this.polylinePaths[index];

            // Check if polylinePath is not empty and contains valid points
            if (polylinePath && polylinePath.length > 0) {
                const bounds = new google.maps.LatLngBounds();
                polylinePath.forEach((point) => bounds.extend(point));
                this.$refs.gmap.$mapObject.fitBounds(bounds);
            } else {
                console.error('Invalid polylinePath:', polylinePath);
            }
        },
        showEndMarkerInfo(index, type, eventType = 'click') {
            let position,
                title,
                content = '',
                hoverTag;
            if (eventType === 'hover') {
                hoverTag = '-hover';
            }
            if (type === 'start') {
                position = this.polylinePaths[index][0];
                title = this.start_city[index];
                if (this.marker_data?.origin) {
                    const { city_name, ...rest } = this.marker_data.origin;
                    content = this.getMarkerContent(rest);
                }
            } else if (type === 'end') {
                position =
                    this.polylinePaths[index][
                        this.polylinePaths[index].length - 1
                    ];
                title = this.end_city[index];
                if (this.marker_data?.destination) {
                    const { city_name, ...rest } = this.marker_data.destination;
                    content = this.getMarkerContent(rest);
                }
            }
            this.createCustomOverlay(
                position,
                title,
                content,
                `${type}-marker-info${hoverTag}`
            );
        },
        showMarkerInfo(index, marker, eventType = 'click') {
            const overlayClass =
                `marker-info-${index}` +
                (eventType === 'hover' ? '-hover' : '');
            let activeOverlay = document.querySelector(`.${overlayClass}`);
            if (activeOverlay !== null) {
                activeOverlay.remove();
                return;
            }
            let title = '';
            let props = marker;
            if (marker?.carrier_name) {
                const { carrier_name, ...rest } = marker;
                title = carrier_name;
                props = rest;
            } else if (marker?.nearest_city) {
                const { nearest_city, ...rest } = marker;
                title = nearest_city;
                props = rest;
            }
            const content = this.getMarkerContent(props);
            this.createCustomOverlay(marker, title, content, overlayClass);
        },
        getMarkerContent(marker) {
            let result = '';
            for (const key in marker) {
                if (key === 'lat' || key === 'lng') continue;
                if (result !== '') {
                    result = result.concat('<br/>');
                }
                const keyLabel = this.capitalizeWords(key.replaceAll('_', ' '));
                result = result.concat(`${keyLabel}: ${marker[key]}`);
            }
            return result;
        },
        capitalizeWords(str) {
            return str.replace(/\w\S*/g, function (txt) {
                return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
            });
        },
        handleMarkerMouseOver(event, index, type) {
            let activePolyInfo = document.querySelector('.polyline-info-hover');
            if (activePolyInfo !== null) {
                activePolyInfo.remove();
            }
            let activeMarkerClass;
            if (type === 'start') {
                this.showEndMarkerInfo(index, 'start', 'hover');
                activeMarkerClass = 'start-marker-info';
            } else if (type === 'end') {
                this.showEndMarkerInfo(index, 'end', 'hover');
                activeMarkerClass = 'end-marker-info';
            } else {
                let markerInfo = null;
                if (type != 'middle') {
                    let ind = type.split('_')[1];
                    markerInfo = this.transitMarkers[ind].markers[index];
                } else {
                    markerInfo = this.marker_data?.markers[index];
                }
                this.showMarkerInfo(index, markerInfo, 'hover');
                activeMarkerClass = `marker-info-${index}`;
            }
            event.domEvent.target.addEventListener(
                'mouseout',
                () => {
                    let active = document.querySelector(
                        `.${activeMarkerClass}-hover`
                    );
                    if (active !== null) {
                        active.remove();
                    }
                },
                { once: true }
            );
        },
        handleMapMouseMove(event) {
            this.hoverPosition = event.latLng;
        },
        handleMapClick(event) {
            let activeOverlays =
                document.getElementsByClassName('customOverlay');
            if (activeOverlays.length > 0) {
                for (let i = activeOverlays.length - 1; i >= 0; i--) {
                    activeOverlays[i].remove(); // Reverse iteration to prevent skipping elements
                }
                this.showOverlay = false;
            }
        },
        handlePolylineClick(event, index) {
            this.hoverPosition = event.latLng;
            this.handlePolylineMouseOver(event, index, 'click');
        },
        handlePolylineMouseOver(event, index, eventType = 'hover') {
            if (
                event.domEvent.target !== null &&
                event.domEvent.target.nodeName === 'IMG'
            ) {
                return; // This is to ignore cases when cursor is over a marker
            }

            const overlayClass = `polyline-info`;
            let active = document.querySelector('.' + overlayClass);
            // Display custom overlay on polyline mouseover
            if (this.hoverPosition.lat) {
                const polylinePath = this.polylinePaths[index];
                const position = {
                    lat: this.hoverPosition.lat(),
                    lng: this.hoverPosition.lng()
                };

                this.overlayTitle = this.distanceArray[index];
                this.overlayContent = `${this.distance[index]} miles`;
                if (
                    active !== null
                ) {
                    active.remove();
                    this.showOverlay = true;
                }

                this.createCustomOverlay(
                    position,
                    this.overlayTitle,
                    this.overlayContent,
                    overlayClass
                );
            }
        },

        handlePolylineMouseOut(event) {
            if (event.domEvent.target.nodeName === 'IMG') {
                return; // This just exits quicker when cursor is over a marker
            }
            setTimeout(() => {
                    let active = document.querySelector('.polyline-info');
                    if (active !== null) {
                        active.remove();
                    }
                }, 1000);
        },

        createCustomOverlay(position, title, content, customClass) {
            // Create a new instance of the custom overlay class
            const CustomOverlayClass = createCustomOverlayClass(google);
            this.customOverlay = new CustomOverlayClass(
                this.$refs.gmap.$mapObject,
                position,
                `
                    <div
                        style="
                            background-color: rgba(15,15,15,0.75);
                            padding: 10px;
                            padding-bottom:1px;
                            pointer-events: none; // This is for mouseout to work properly
                        "
                        class="custom-overlay"
                    >
                        <h3>${title}</h3>
                        <p style="margin-top:5px; max-width: 300px;">${content}</p>
                    </div>
                `,
                customClass
            );

            // Hide the overlay initially
            this.customOverlay.setMap(null);

            // Show the overlay
            this.customOverlay.setMap(this.$refs.gmap.$mapObject);

            // You can save the custom overlay instance in a data property if needed
            // this.customOverlayInstance = customOverlay;
        }
    }
};
</script>
<style scoped>
.gmap-heading {
    padding: 0px 0px 0px 10px;
    color: white;
    /* border-left: 2px solid #8f8f8f; */
    font-weight: 400;
    font-size: 16px;
    background-color: #000;
    width: 100%;
    display: flex;
    height: 30px;
    align-items: center;
}
.map-container-reduced {
    position: relative;
    width: 100%;
    height: 250px;
}

.map-container-reduced-chat {
    position: relative;
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
}

.map-container-expanded {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 111;
    border: 80px solid rgba(0, 0, 0, 0.8);
    /* outline: 10px solid rgba(255, 255, 255, 0.5); */
    background: transparent;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.map {
    width: 100%;
    height: 100%;
}
.custom-overlay {
    position: absolute;
    z-index: 1000; /* Ensure it's above the map */
}

.custom-overlay-content {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    position: absolute;
    max-width: 200px; /* Adjust as needed */
}
.hover-div {
    position: absolute;
    left: 4px;
    background-color: transparent; /* Or any color you wish */
    z-index: 10; /* Make sure this is above the map's z-index */
    /* More styling for your div */
}
.expand-icon {
    position: absolute;
    top: 2px;
    right: 5px;
    background-color: transparent;
    z-index: 10;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Space between legend items */
    cursor: pointer;
}

.color-box {
    display: inline-block;
    width: 10px; /* Width of the color box */
    height: 10px; /* Height of the color box */
    margin-right: 10px; /* Space between the color box and text */
}

.center-content {
    display: flex;
    align-items: center;
}
.list-class {
    display: flex;
    align-items: center;
    color: white;
    background-color: rgba(15, 15, 15, 0.75);
}
.textDisplay {
    display: block;
    cursor: pointer;
    overflow: auto;
    color: white;
}
.v-btn:before {
    background-color: transparent !important;
}
</style>
