var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center",staticStyle:{"background":"rgb(21, 22, 25)","color":"white"}},[_c('div',{staticStyle:{"width":"100%","align-self":"center","padding-bottom":"10px"},style:(_vm.$vuetify.breakpoint.mobile ? { width: '100%' } : {})},[(_vm.inputEmail)?_c('div',[_c('div',{staticStyle:{"font-size":"35px","padding-bottom":"4%"}},[_vm._v(" Forgot Password? ")]),_c('div',{staticStyle:{"font-size":"15px","padding-bottom":"5%"}},[_vm._v(" Enter the email you signed up with. We'll send a verification code to your email for reseting your password. ")]),(_vm.content_type == 'email')?_c('v-text-field',{attrs:{"label":"Email","type":"email","name":"username","autocomplete":"username","outlined":"","dark":"","prepend-inner-icon":"mdi-email-outline","error-messages":_vm.emailErrors,"required":"","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}):_vm._e(),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"width":"100%"},attrs:{"color":"#348EFF","dark":""},on:{"click":_vm.verifyAndProgressResetPassword}},[(_vm.content_type == 'email')?_c('div',[_vm._v("Send Code")]):_vm._e()])],1):_c('div',[_c('div',{staticStyle:{"font-size":"35px","padding-bottom":"4%"}},[_vm._v(" Reset Password ")]),_c('v-form',{ref:"resetPasswordForm",attrs:{"value":_vm.formValid,"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.checkAndSubmit.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Password *","outlined":"","dark":"","validate-on-blur":"","dense":_vm.$vuetify.breakpoint.mobile,"rules":_vm.hadFocus['pwd']
                            ? [
                                  _vm.rules.required,
                                  _vm.rules.min,
                                  _vm.rules.max,
                                  _vm.rules.pwdMatch
                              ]
                            : [],"type":_vm.showPwd ? 'text' : 'password'},on:{"focus":function($event){_vm.hadFocus['pwd'] = true},"blur":_vm.triggerValidation},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"tabindex":"-1","icon":""},on:{"click":function($event){_vm.showPwd = !_vm.showPwd}}},[_vm._v(_vm._s(_vm.showPwd ? 'mdi-eye' : 'mdi-eye-off'))])]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"label":"Confirm Password *","outlined":"","dark":"","validate-on-blur":"","dense":_vm.$vuetify.breakpoint.mobile,"rules":_vm.hadFocus['conf']
                            ? [
                                  _vm.rules.required,
                                  _vm.rules.min,
                                  _vm.rules.max,
                                  _vm.rules.pwdMatch
                              ]
                            : [],"type":_vm.showConfPwd ? 'text' : 'password'},on:{"focus":function($event){_vm.hadFocus['conf'] = true},"blur":_vm.triggerValidation},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"tabindex":"-1","icon":""},on:{"click":function($event){_vm.showConfPwd = !_vm.showConfPwd}}},[_vm._v(_vm._s(_vm.showConfPwd ? 'mdi-eye' : 'mdi-eye-off'))])]},proxy:true}]),model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}}),_c('v-text-field',{attrs:{"label":"Verification Code *","outlined":"","dark":"","validate-on-blur":"","dense":_vm.$vuetify.breakpoint.mobile,"rules":_vm.hadFocus['token']
                            ? [_vm.rules.required, _vm.rules.sixDigits]
                            : [],"type":'text'},on:{"focus":function($event){_vm.hadFocus['token'] = true},"blur":_vm.triggerValidation},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('v-btn',{staticClass:"text-capitalize",staticStyle:{"width":"100%"},attrs:{"color":"#348EFF","dark":"","type":"submit","disabled":!_vm.formValid}},[_c('div',[_vm._v("Reset Password")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }