import { render, staticRenderFns } from "./dotNumber.vue?vue&type=template&id=8a6320e4&scoped=true"
import script from "./dotNumber.vue?vue&type=script&lang=ts"
export * from "./dotNumber.vue?vue&type=script&lang=ts"
import style0 from "./dotNumber.vue?vue&type=style&index=0&id=8a6320e4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a6320e4",
  null
  
)

export default component.exports