<template>
    <div id="app" style="background-color: black">
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'App',

    components: {},

    data: () => ({
        //
    })
});
</script>

<style>
/* width */
html ::-webkit-scrollbar {
    width: 0px;
}

/* Track */
html ::-webkit-scrollbar-track {
    background: #272727;
}

/* Handle */
html ::-webkit-scrollbar-thumb {
    background: #3f3f3f;
}

/* Handle on hover */
html ::-webkit-scrollbar-thumb:hover {
    background: #4c4c4c;
}

html,
body {
    height: 100%;
}
</style>
