<template>
    <div
        class="d-flex justify-center"
        style="background: rgb(21, 22, 25); color: white"
    >
        <div
            style="width: 100%; align-self: center; padding-bottom: 10px"
            :style="$vuetify.breakpoint.mobile ? { width: '100%' } : {}"
        >
            <div v-if="inputEmail">
                <div style="font-size: 35px; padding-bottom: 4%">
                    Forgot Password?
                </div>

                <div style="font-size: 15px; padding-bottom: 5%">
                    Enter the email you signed up with. We'll send a
                    verification code to your email for reseting your password.
                </div>

                <v-text-field
                    v-if="content_type == 'email'"
                    label="Email"
                    type="email"
                    name="username"
                    autocomplete="username"
                    outlined
                    dark
                    prepend-inner-icon="mdi-email-outline"
                    v-model="userEmail"
                    :error-messages="emailErrors"
                    required
                    autofocus
                ></v-text-field>
                <v-btn
                    color="#348EFF"
                    dark
                    class="text-capitalize"
                    style="width: 100%"
                    @click="verifyAndProgressResetPassword"
                >
                    <div v-if="content_type == 'email'">Send Code</div>
                </v-btn>
            </div>
            <div v-else>
                <div style="font-size: 35px; padding-bottom: 4%">
                    Reset Password
                </div>
                <v-form
                    ref="resetPasswordForm"
                    :value="formValid"
                    @submit.prevent="checkAndSubmit"
                    lazy-validation
                >
                    <v-text-field
                        v-model="newPassword"
                        label="Password *"
                        outlined
                        dark
                        validate-on-blur
                        :dense="$vuetify.breakpoint.mobile"
                        :rules="
                            hadFocus['pwd']
                                ? [
                                      rules.required,
                                      rules.min,
                                      rules.max,
                                      rules.pwdMatch
                                  ]
                                : []
                        "
                        :type="showPwd ? 'text' : 'password'"
                        @focus="hadFocus['pwd'] = true"
                        @blur="triggerValidation"
                    >
                        <template #append>
                            <v-icon
                                tabindex="-1"
                                icon
                                @click="showPwd = !showPwd"
                                >{{
                                    showPwd ? 'mdi-eye' : 'mdi-eye-off'
                                }}</v-icon
                            >
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-model="confirmNewPassword"
                        label="Confirm Password *"
                        outlined
                        dark
                        validate-on-blur
                        :dense="$vuetify.breakpoint.mobile"
                        :rules="
                            hadFocus['conf']
                                ? [
                                      rules.required,
                                      rules.min,
                                      rules.max,
                                      rules.pwdMatch
                                  ]
                                : []
                        "
                        :type="showConfPwd ? 'text' : 'password'"
                        @focus="hadFocus['conf'] = true"
                        @blur="triggerValidation"
                    >
                        <template #append>
                            <v-icon
                                tabindex="-1"
                                icon
                                @click="showConfPwd = !showConfPwd"
                                >{{
                                    showConfPwd ? 'mdi-eye' : 'mdi-eye-off'
                                }}</v-icon
                            >
                        </template>
                    </v-text-field>
                    <v-text-field
                        v-model="token"
                        label="Verification Code *"
                        outlined
                        dark
                        validate-on-blur
                        :dense="$vuetify.breakpoint.mobile"
                        :rules="
                            hadFocus['token']
                                ? [rules.required, rules.sixDigits]
                                : []
                        "
                        :type="'text'"
                        @focus="hadFocus['token'] = true"
                        @blur="triggerValidation"
                    >
                    </v-text-field>

                    <v-btn
                        color="#348EFF"
                        dark
                        type="submit"
                        :disabled="!formValid"
                        class="text-capitalize"
                        style="width: 100%"
                    >
                        <div>Reset Password</div>
                    </v-btn>
                </v-form>
            </div>
        </div>
    </div>
</template>
<script>
import * as auth from '../authentication/amplify-auth';

export default {
    name: 'ResetPassword',

    components: {},

    methods: {
        triggerValidation() {
            this.formValid = this.$refs.resetPasswordForm.validate();
        },

        checkAndSubmit() {
            this.formValid = this.$refs.resetPasswordForm.validate();
            if (this.formValid) {
                this.submitForm();
            }
        },

        async verifyAndProgressResetPassword() {
            if (!this.validate_emails()) {
                return;
            }
            await auth
                .forgotPassword(this.userEmail)
                .then(() => {
                    this.$emit('showLoading', false);
                    this.inputEmail = false;
                    this.$emit(
                        'snackbar',
                        true,
                        `Verification code sent successfully`,

                        'green'
                    );
                })
                .catch((error) => {
                    this.$emit('showLoading', false);
                    if (error.code === 'UserNotFoundException') {
                        this.$emit(
                            'snackbar',
                            true,
                            `There is no account associated with this email. If you believe this is an error, please contact us at ${this.contactEmail}`,

                            'red darken-3'
                        );
                    } else {
                        this.$emit(
                            'snackbar',
                            true,
                            `Password reset failed. Please try again and if issue persists, contact us at ${this.contactEmail}`,

                            'red darken-3'
                        );
                    }
                });

            this.$emit('showLoading', false);
            this.inputEmail = false;
        },

        validate_emails() {
            const regex = /^\w+([.+_-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let result =
                !this.userEmail ||
                regex.test(this.userEmail) ||
                'E-mail must be valid';

            if (result == 'E-mail must be valid') {
                this.emailErrors.push(result);
                return false;
            }
            if (this.userEmail == '') {
                this.emailErrors.push('E-mail field cannot be empty');
                return false;
            }

            return result;
        },

        async submitForm() {
            this.$emit('showLoading', true);
            await auth
                .forgotPasswordSubmit(
                    this.userEmail,
                    this.token,
                    this.newPassword
                )
                .then(() => {
                    console.log('RETURN FROM METHOD');
                    this.$emit('showLoading', false);
                    this.$emit('resetPasswordComplete');
                    this.$emit(
                        'snackbar',
                        true,
                        'Password Reset Successfully',
                        'green'
                    );
                })
                .catch((error) => {
                    this.$emit('showLoading', false);
                    if (error.code == 'UserNotFoundException') {
                        console.log('made it into the error catch...');
                        this.$emit(
                            'snackbar',
                            true,
                            `There is no account associated with this email. If you believe this is an error, please contact us at ${this.contactEmail}`,

                            'red darken-3'
                        );
                    } else {
                        this.$emit(
                            'snackbar',
                            true,
                            `Password reset failed. If you believe this is an error, please contact us at ${this.contactEmail}`,

                            'red darken-3'
                        );
                    }
                });
        }
    },

    data: function () {
        return {
            // Reset password form fields
            formValid: false,
            newPassword: '',
            confirmNewPassword: '',
            showPwd: false,
            showConfPwd: false,
            hadFocus: {
                token: false,
                pwd: false,
                conf: false
            },
            rules: {
                required: (v) => {
                    return !!v || 'This field cannot be empty';
                },
                min: (v) => v.length >= 8 || 'Min 8 characters',
                max: (v) => v.length <= 20 || 'Max 20 characters',
                pwdMatch: () =>
                    this.confirmNewPassword === '' ||
                    this.newPassword === this.confirmNewPassword ||
                    `The passwords you entered don't match`,
                sixDigits: (v) => v.length === 6 || 'Must be 6 characters long'
            },
            confirmError: '',
            contactEmail: 'contact@truce.io',
            content_type: 'email',
            inputEmail: true,
            emailErrors: [],
            userEmail: '',
            token: ''
        };
    }
};
</script>
