<template>
    <v-dialog v-model="contactFormDialog" persistent max-width="600px">
        <v-form ref="contactForm" v-model="contactFormValid">
            <v-card class="pa-6" dark>
                <v-card-title>
                    <span class="text-h5">Contact Us</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field
                                    label="Name*"
                                    :rules="[
                                        rules.required,
                                        rules.alphanumeric
                                    ]"
                                    v-model="name"
                                    dark
                                    outlined></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Company"
                                    :rules="[rules.alphanumeric]"
                                    v-model="company"
                                    dark
                                    outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Email*"
                                    :rules="[rules.required, rules.email]"
                                    v-model="email"
                                    dark
                                    outlined></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    name="input-7-1"
                                    label="Comments"
                                    :rules="[rules.counter, rules.alphanumeric]"
                                    outlined
                                    v-model="comment"
                                    counter
                                    :maxlength="commentMaxLength"
                                    dark></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="resetForm()">
                        Cancel
                    </v-btn>
                    <v-btn
                        dark
                        color="green"
                        text
                        :disabled="!contactFormValid"
                        @click="sendEmail()">
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'ContactUsDialog',
    props: ['openForm'],
    watch: {
        openForm(val) {
            this.contactFormDialog = val;
        }
    },
    methods: {
        resetForm() {
            this.contactFormDialog = false;
            this.$refs.contactForm.reset();
            this.$emit('formState', false);
        },
        validateForm() {
            return this.$refs.contactForm.validate();
        },
        sendEmail() {
            const contactEndpoint =
                'https://gkiuzzt1h8.execute-api.us-east-1.amazonaws.com/contactTest/contact';

            // only send email if form validation passes
            if (this.validateForm()) {
                const body = {
                    name: this.name,
                    company: this.company,
                    email: this.email,
                    comment: this.comment
                };

                axios
                    .post(contactEndpoint, body)
                    .then(() => {
                        console.log('DONE SENDING EMAIL');
                    })
                    .catch((error) => {
                        console.log(error.response);
                    });

                this.resetForm();
            }
        }
    },
    data: function () {
        return {
            contactFormDialog: false,
            name: '',
            company: '',
            email: '',
            comment: '',
            contactFormValid: false,
            commentMaxLength: 250,
            rules: {
                required: (value: any) => !!value || 'Required.',
                counter: (value: string) =>
                    (value && value.length <= 250) || 'Max 250 characters',
                email: (value: string) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
                alphanumeric: (value: string) => {
                    const pattern = /^[a-zA-Z0-9\s]*$/;
                    return (
                        pattern.test(value) ||
                        'Invalid characters. Only letters and numbers allowed.'
                    );
                }
            }
        } as any;
    }
});
</script>
