<template>
    <v-footer
        app
        dark
        absolute
        class="justify-end text-caption text-capitalize"
        style="background-color: transparent">
        <v-container
            class="full-height hidden-sm-and-down"
            style="
                width: 55%;
                margin-top: 40px;

                min-width: fit-content;
            "
            :style="
                $vuetify.breakpoint.mdAndDown ? { 'margin-left': '20px' } : {}
            ">
            <v-row class="pt-16">
                <v-col cols="5">
                    <v-row class="pt-5 pb-3">
                        <div
                            class="footer-logo-text-wrapper d-flex align-center">
                            <logo-svg-component
                                class="mr-3 mt-1"
                                width="4%"
                                height="4%"></logo-svg-component>
                            <span class="footer-logo-text">FreightGPT</span>
                        </div>
                    </v-row>
                    <v-row>
                        {{
                            '&copy; ' +
                            year +
                            ' Truce Tech Inc. All Rights Reserved.'
                        }}
                    </v-row>
                </v-col>
                <v-col cols="7">
                    <v-row justify="end">
                        <v-col cols="3">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >Try Demo</v-list-item-title
                                    >
                                    <v-list-item-subtitle class="pt-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="navigateToPage('dry_van_ad','landing_page')"
                                            >Dry Van Rates</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('reefer_ad','landing_page')
                                            "
                                            >Reefer Rates</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('carrier_search_ad','landing_page')
                                            "
                                            >Carrier Search</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('dot_lookup_ad','landing_page')
                                            "
                                            >DOT Lookup</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('mc_lookup_ad','landing_page')
                                            "
                                            >MC Lookup</a
                                        ></v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="3">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >Resources</v-list-item-title
                                    >
                                    <v-list-item-subtitle class="py-5"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="navigateToPage('dotnumber')"
                                            >What is a DOT number?</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('truckingquotes')
                                            "
                                            >Freight Rates</a
                                        ></v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="3">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title
                                        >Company</v-list-item-title
                                    >
                                    <v-list-item-subtitle class="py-5"
                                        ><a
                                            class="footer-item"
                                            @click="openContactUsForm = true"
                                            >Contact Us</a
                                        ></v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="3">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Legal</v-list-item-title>
                                    <v-list-item-subtitle class="py-5"
                                        ><a
                                            class="footer-item"
                                            @click="openPrivacyDialog"
                                            >Privacy Policy</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle
                                        ><a
                                            class="footer-item"
                                            @click="openTOSDialog"
                                            >Terms of Use</a
                                        ></v-list-item-subtitle
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="full-height hidden-md-and-up">
            <v-row class="pt-8 pt-xl-16 pt-lg-16 pt-md-16 pt-sm-16" dense>
                <v-list-item class="pl-1">
                    <v-list-item-content>
                        <v-list-item-title>Try Demo</v-list-item-title>
                        <v-list-item-subtitle class="pt-3"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="navigateToPage('dry_van_ad','landing_page')"
                                            >Dry Van Rates</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-3"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('reefer_ad','landing_page')
                                            "
                                            >Reefer Rates</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-3"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('carrier_search_ad','landing_page')
                                            "
                                            >Carrier Search</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-3"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('dot_lookup_ad','landing_page')
                                            "
                                            >DOT Lookup</a
                                        ></v-list-item-subtitle
                                    >
                                    <v-list-item-subtitle class="pt-3"
                                        ><a
                                            class="footer-item"
                                            style="text-decoration: none"
                                            @click="
                                                navigateToPage('mc_lookup_ad','landing_page')
                                            "
                                            >MC Lookup</a
                                        ></v-list-item-subtitle
                                    >
                    </v-list-item-content>
                </v-list-item>
            </v-row>
            <v-row dense>
                <v-list-item class="pl-1">
                    <v-list-item-content>
                        <v-list-item-title>Resources</v-list-item-title>
                        <v-list-item-subtitle class="py-3"
                            ><a
                                class="footer-item"
                                style="text-decoration: none"
                                @click="navigateToPage('dotnumber')"
                                >What is a DOT number?</a
                            ></v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                            ><a
                                class="footer-item"
                                style="text-decoration: none"
                                @click="navigateToPage('truckingquotes')"
                                >Freight Rates</a
                            ></v-list-item-subtitle
                        >
                    </v-list-item-content>
                </v-list-item>
            </v-row>
            <v-row dense>
                <v-list-item class="pl-1">
                    <v-list-item-content>
                        <v-list-item-title>Legal</v-list-item-title>
                        <v-list-item-subtitle class="py-3"
                            ><a class="footer-item" @click="openPrivacyDialog"
                                >Privacy Policy</a
                            ></v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                            ><a class="footer-item" @click="openTOSDialog"
                                >Terms of Use</a
                            ></v-list-item-subtitle
                        >
                    </v-list-item-content>
                </v-list-item>
            </v-row>
            <v-row class="pb-2" style="margin-left: 2px">
                Have questions?
                <a class="pl-1" @click="openContactUsForm = true">Contact Us</a>
                <v-spacer></v-spacer>
            </v-row>
            <v-row align="center" class="pb-2" style="margin-left: 2px">
                <div>
                    {{
                        '&copy; ' +
                        year +
                        ' Truce Tech Inc. All Rights Reserved.'
                    }}
                </div>
            </v-row>
        </v-container>
        <v-dialog
            v-model="policyDialog"
            :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '50%'">
            <v-card class="dialog-background">
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="policyDialog = false">
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="markdown-content">
                    <markdown-renderer
                        :markdown-content="markdownContent"></markdown-renderer>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ContactUsDialog
            :openForm="openContactUsForm"
            @formState="handleFormEmission"></ContactUsDialog>
    </v-footer>
</template>
<script lang="ts">
import Vue from 'vue';
import LogoSvgComponent from '../components/LogoSVG.vue';
import { privacy_markdown, tos_markdown } from '../policies';
import MarkdownRenderer from './MarkdownRenderer.vue';
import ContactUsDialog from '../components/ContactUs.vue';

export default Vue.extend({
    name: 'FooterBar',
    components: {
        LogoSvgComponent,
        MarkdownRenderer,
        ContactUsDialog
    },
    methods: {
        openPrivacyDialog() {
            this.markdownContent = privacy_markdown;
            this.policyDialog = true;
        },

        openTOSDialog() {
            this.markdownContent = tos_markdown;
            this.policyDialog = true;
        },

        handleFormEmission(value: boolean) {
            this.openContactUsForm = value;
        },
        navigateToPage(page: string, origin?: string) {
            const routeOptions: any = { name: page };
            if (origin) {
                routeOptions.query = { origin: origin };
            }
            this.$router
                .push(routeOptions)
                .catch(() => {});
        }
    },
    data: function () {
        return {
            markdownContent: '',
            policyDialog: false,
            year: new Date().getFullYear(),
            openContactUsForm: false
        };
    }
});
</script>
<style scoped>
.markdown-content {
    max-height: 80%;
    overflow-y: auto;
    color: white !important;
}

.dialog-background {
    background-color: rgb(21, 22, 25) !important; /* Transparent dark grey */
    color: white;
}

.footer-item {
    color: #9e9e9e !important;
}

.footer-item:hover {
    color: white !important;
}

.footer-logo-text-wrapper {
    padding-left: 0.5%;
    padding-top: 1%;
    display: flex;
    align-items: center;
}

.footer-logo-text {
    font-size: 25px !important;
}

/* For mobile devices */
@media screen and (max-width: 500px) {
    .footer-logo-text {
        font-size: 20px !important;
    }
}
</style>
