import { Auth, Hub } from 'aws-amplify';

export async function signUp(formFields: any) {
    const { user } = await Auth.signUp({
        ...formFields,
        autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true
        }
    });
    return user;
}

export async function resendSignUp(username: string) {
    const result = await Auth.resendSignUp(username);
    return result;
}

export async function confirmSignUp(username: string, code: string) {
    const result = await Auth.confirmSignUp(username, code);
    return result;
}

export function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
        const { event } = payload;
        if (event === 'autoSignIn') {
            // const user = payload.data;
            // assign user
        } else if (event === 'autoSignIn_failure') {
            // redirect to sign in page
        }
    });
}

export async function signIn(username: string, password: string) {
    const user = await Auth.signIn(username, password);
    return user;
}

export async function currentAuthenticatedUser(email: string) {
    try {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        if (user.attributes['email'] == email) {
            return user;
        }
        return null;
    } catch (err) {
        return null;
    }
}

export async function getCurrentUser() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    } catch (error) {
        console.error('Error getting user');
    }
}

export async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.error('error signing out');
    }
}

// Send confirmation code to user's email or phone number
export async function forgotPassword(email: string) {
    const data = await Auth.forgotPassword(email);
    return data;
}

// Collect confirmation code and new password
export async function forgotPasswordSubmit(
    email: string,
    code: string,
    newPassword: string
) {
    const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
    return data;
}

export async function currentSSOSignIn(provider: String) {
    try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken();
        const identities = idToken.payload.identities;
        const email = idToken.payload.email;
        for (let i = 0; i < identities.length; i++) {
            if (identities[i].providerName == provider) {
                return email;
            }
        }

    } catch (error) {
        return null;
    }

    return null;
}

export function clearCognitoLocalStorage() {
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key: string | null = localStorage.key(i);
        if (key && key.startsWith("CognitoIdentityServiceProvider")) {
            localStorage.removeItem(key);
        }
    }
}
