function createCustomOverlayClass(google) {
    return class CustomOverlay extends google.maps.OverlayView {
        constructor(map, position, content, customClass = '') {
            super();
            this.map = map;
            this.position = position;
            this.content = content;
            this.div = null;
            this.customClass = customClass;
            this.setMap(map);
        }

        onAdd() {
            var div = document.createElement('div');
            div.id = 'customOverlay';
            div.classList.add('customOverlay');
            if (this.customClass) div.classList.add(this.customClass);
            div.style.position = 'absolute';
            div.style.pointerEvents = 'none';
            div.innerHTML = this.content;

            this.div = div;
            var panes = this.getPanes();
            panes.floatPane.appendChild(div);
        }

        draw() {
            var overlayProjection = this.getProjection();
            var position = overlayProjection.fromLatLngToDivPixel(
                this.position
            );

            const mapBounds = this.map.getBounds();
            const mapCenter = mapBounds.getCenter(); // This is to get the center coordinates of the mapbounds

            // Check if the marker is on the extreme right or left of the map
            if (this.position.lng > mapCenter.lng()) {
                position.x -= this.div.offsetWidth; // Marker is on the right side
            } else {
                position.x += 10; // Marker is on the left side
            }

            // Check if the marker is on the extreme top or bottom of the map
            if (this.position.lat < mapCenter.lat()) {
                position.y -= this.div.offsetHeight; // Marker is on the top side
            } else {
                position.y += 10; // Marker is on the bottom side
            }

            var div = this.div;
            div.style.left = position.x + 'px';
            div.style.top = position.y + 'px';
        }

        onRemove() {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        }
    };
}

export default createCustomOverlayClass;
