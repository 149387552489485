var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-1",attrs:{"id":_vm.id}},[_c('div',{staticClass:"data-info-heading"},[(_vm.displayInfo.length > 1)?_c('v-icon',{staticStyle:{"cursor":"pointer","font-size":"30px"},attrs:{"color":"white"},on:{"click":function($event){return _vm.changeDisplayInfo('left')}}},[_vm._v(" mdi-chevron-left ")]):_vm._e(),_c('span',{staticStyle:{"padding":"0px 10px"}},[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].origin_city : '')+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-arrow-right-thin ")]),_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].destination_city : '')+" ")],1),(_vm.displayInfo.length > 1)?_c('v-icon',{staticStyle:{"cursor":"pointer","font-size":"30px"},attrs:{"color":"white"},on:{"click":function($event){return _vm.changeDisplayInfo('right')}}},[_vm._v(" mdi-chevron-right ")]):_vm._e()],1),_c('div',{class:!_vm.isMobile ? 'container-2' : ''},[_c('div',{staticClass:"data-info-container",style:({ width: _vm.isMobile ? '100% !important' : '', ..._vm.dynamicTextSize})},[_c('div',{staticClass:"data-info",style:({
                    'padding-left': _vm.displayInfo.length <= 1 ? '' : '25px'
                })},[_c('span',{staticClass:"data-input"},[_c('div',{staticClass:"data-input-heading"},[_vm._v("5-Day Avg Rate w Fuel")]),_c('div',{staticClass:"price-value"},[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.formatDollars(_vm.displayInfo[_vm.displayIndex].all_in_rate, _vm.fixed_point=2, _vm.showCents=true) : '$')+" "),_c('div',{staticClass:"info-tooltip"},[_c('v-icon',{attrs:{"small":"","color":"rgb(143, 143, 143)"}},[_vm._v("mdi-help-circle-outline")]),_c('span',{staticClass:"info-tooltip-text"},[_vm._v("This price is calculated based on current market conditions and historical data trends")])],1)])]),_c('span',{staticStyle:{"padding":"15px"}}),_c('span',{staticClass:"data-input"},[_c('div',{staticClass:"data-input-heading"},[_vm._v("Equip. Type")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].equipment_type : '')+" ")])])]),_c('div',{staticClass:"data-info",style:({
                    'padding-left': _vm.displayInfo.length <= 1 ? '' : '25px'
                })},[_c('span',{staticClass:"data-input"},[_c('div',{staticClass:"data-input-heading"},[_vm._v("Distance")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].total_distance : '')+" miles ")])]),_c('span',{staticStyle:{"padding":"15px"}}),_c('span',{staticClass:"data-input"},[_c('div',{staticClass:"data-input-heading"},[_vm._v("Diesel Cost")]),_c('div',[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.formatDollars(_vm.displayInfo[_vm.displayIndex].fuel_cost, _vm.fixed_point=2, _vm.showCents=true) : '$')+" ")])])]),_c('div',{staticClass:"data-info reduced-apex-chart",style:({
                    'padding-left': _vm.displayInfo.length <= 1 ? '' : '25px'
                })},[_c('span',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"trend-data data-input-heading"},[_c('span',{staticStyle:{"padding-left":"10px","border-left":"2px solid #8f8f8f"},style:(_vm.dynamicTextSize)},[_vm._v("Trend (30d)")]),(!_vm.isMobile)?_c('span',{staticClass:"trend-expand-icon"},[_c('v-btn',{staticClass:"mt-1",staticStyle:{"color":"#8f8f8f"},attrs:{"icon":""},on:{"click":_vm.expandTrend}},[_c('v-icon',{attrs:{"size":"22","color":""}},[_vm._v(" mdi-arrow-expand-all ")])],1)],1):_vm._e()]),_c('div',{staticStyle:{"height":"80px"}},[_c('div',{attrs:{"id":_vm.id + 'chart'}})])])])]),_c('div',{class:[
                _vm.isMobile ? 'mobile-map-container' : 'desk-map-container'
            ]},[(!_vm.dialog)?_c('span',{staticStyle:{"padding":"0px 10px","color":"#8f8f8f","border-left":"2px solid #8f8f8f","font-weight":"400"},style:(_vm.dynamicTextSize)},[_vm._v("Lane Map")]):_vm._e(),_c('span',[_c('GMapPolyline',{attrs:{"mapEvents":_vm.map_events,"isMobile":_vm.isMobile,"id":_vm.id + 'gmap',"displayIndex":_vm.displayIndex,"type":"GmapPricer"},on:{"updateDialogValue":_vm.updateDialogValue}})],1)]),_c('v-dialog',{attrs:{"width":"55%"},model:{value:(_vm.showMapDialog),callback:function ($$v) {_vm.showMapDialog=$$v},expression:"showMapDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-6"},[_c('span',[_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].origin_city : '')+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-arrow-right-thin ")]),_vm._v(" "+_vm._s(_vm.displayInfo[_vm.displayIndex] ? _vm.displayInfo[_vm.displayIndex].destination_city : '')+" ")],1),_c('v-spacer'),_c('v-btn-toggle',{staticClass:"toggle-group",attrs:{"mandatory":""},on:{"change":_vm.updateFilteredSeries},model:{value:(_vm.selectedPeriod),callback:function ($$v) {_vm.selectedPeriod=$$v},expression:"selectedPeriod"}},[_c('v-btn',{attrs:{"value":"7"}},[_vm._v("7d")]),_c('v-btn',{attrs:{"value":"15"}},[_vm._v("15d")]),_c('v-btn',{attrs:{"value":"30"}},[_vm._v("30d")])],1),_c('v-btn',{staticClass:"mt-1 ml-3",attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('HistPriceGraph',{attrs:{"series":_vm.filteredHistPriceSeries}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }