<template>
    <div class="d-flex justify-center" style="color: white">
        <div
            style="align-self: center"
            :style="$vuetify.breakpoint.mobile ? { width: '100%' } : {}"
            >
            <v-form
                ref="signupForm"
                :value="formValid"
                @submit.prevent="checkAndSubmit"
                lazy-validation>
                <div
                    class="text-field-container"
                    :class="{ 'is-visible': isVisible }">
                <v-text-field
                    v-model="signupEmail"
                    label="Email *"
                    outlined
                    dark
                    autofocus
                    :disabled="redirectGoogleSSO || getAdditionalInfo.state"
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="[rules.required, rules.email]"
                    @focus="hadFocus['email'] = true"></v-text-field>
                <v-text-field
                    v-model="signupName"
                    label="Full Name *"
                    :disabled="getAdditionalInfo.state"
                    ref="nameField"
                    name="fullname"
                    id="fullname"
                    type="text"
                    outlined
                    dark
                    :dense="$vuetify.breakpoint.mobile"
                    :rules="[rules.required]"
                    @focus="hadFocus['name'] = true"></v-text-field>
                <v-row>
                    <v-col class="pb-0">
                        <v-text-field
                            v-model="signupCompany"
                            label="Company"
                            ref="companyField"
                            outlined
                            dark
                            :dense="$vuetify.breakpoint.mobile"
                        ></v-text-field>
                    </v-col>
                    <v-col class="pb-0">
                        <v-select
                            v-model="signupRole"
                            :items="roleItems"
                            label="Role"
                            outlined
                            :dense="$vuetify.breakpoint.mobile"
                            :menu-props="{ contentClass: 'roleSelect' }"
                            dark>
                            <template #item="{ item, on, attrs }">
                                <v-list-item
                                    v-bind="attrs"
                                    dark
                                    @click="handleRoleClick(item)"
                                    v-on="on">
                                    <v-list-item-content class="roleSelect">
                                        <v-list-item-title>
                                            {{ item }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pt-0" cols="12">
                        <v-text-field
                            v-if="signupRole === 'Carrier'"
                            v-model="mcNumber"
                            label="Number *"
                            type="number"
                            outlined
                            dark
                            :rules="[
                                rules.carrierSixDigits,
                                rules.carrierRequired
                            ]">
                            <template v-slot:prepend-inner>
                                <span style="padding-top: 3px">MC-</span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!-- TODO (P0): Add character limit rule -->
                <v-textarea
                    v-model="referralSource"
                    label="How did you hear about us?"
                    ref="referralSourceField"
                    name="referralSource"
                    id="referralSource"
                    class="mb-2"
                    outlined
                    dark
                    :dense="$vuetify.breakpoint.mobile"
                    rows="1"
                    @focus="hadFocus['referralSource'] = true"
                    counter="2048"
                ></v-textarea>
                </div>

                <v-btn
                    color="#348EFF"
                    dark
                    type="submit"
                    :disabled="!isFormPopulated"
                    class="text-capitalize"
                    style="width: 100%">
                    <div>
                        {{
                            this.redirectGoogleSSO || this.getAdditionalInfo.state
                                ? 'Complete Sign Up'
                                : 'Sign Up'
                        }}
                    </div>
                </v-btn>
                <!-- <div
                    class="d-flex justify-end mb-2"
                    v-if="redirectGoogleSSO"
                >
                    <button
                        class="no-hover-effect d-flex align-center justify-end mt-2 mb-2"
                        style="font-size: 1rem; color: gray; text-transform: none; background: transparent; box-shadow: none; cursor: pointer; border: none;"
                    >
                        Skip
                        <i class="mdi mdi-arrow-right-thin ml-1"></i>
                    </button>
                </div> -->
            </v-form>
            <v-dialog v-model="dialog" width="28rem">
                <v-card dark :color="dialogColor">
                    <v-card-title class="headline pb-0"
                        >{{ dialogTitle }} <v-spacer></v-spacer>
                        <!-- This pushes the close button to the right -->
                        <v-btn icon @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                            <!-- mdi-close is the Material Design icon for close -->
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="text-s1 pt-2 pb-4">
                        {{ dialogMessage }}
                        <span class="pl-1 link">
                            <a style="color: white" @click="redirectToSignIn"
                                >Sign In</a
                            >
                        </span>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import * as auth from '../authentication/amplify-auth';
import { Auth } from 'aws-amplify';
import * as analytics from '../analytics/pinpoint';

export default {
    name: 'SignUpFlow',
    props: {
        redirectGoogleSSO: {
            type: Boolean,
            default: false
        },
        getAdditionalInfo: {
            type: Object,
            default: () => ({})
        }
    },

    components: {},
    methods: {
        triggerValidation() {
            this.formValid = this.$refs.signupForm.validate();
        },

        checkAndSubmit() {
            this.formValid = this.$refs.signupForm.validate();

            // if (!this.redirectGoogleSSO && this.getAdditionalInfo.state){
            //     this.$gtag.event('sign_up_otp', {
            //         send_to: 'AW-16494424913/FOv5CJKs1s4ZENHmk7k9',
            //         event_category: 'engagement',
            //         event_label: 'new_user_confirmed',
            //         value: '1'
            //     });
            // }

            if (this.formValid) {
                this.submitSignupForm();
            }
        },

        sendMsgToQueue(val){
            if('url' in this.$store.state){
                val.url = this.$store.state['url']
            }
            val.user_status = 'CONFIRMED'
            val.identity_provider = ''
            this.$emit('updateSignupFormData',val)
        },

        async submitSignupForm() {
            this.$emit('showLoading', true);
            // this.$emit('setEmail', this.signupEmail.trim());
            // this.$emit('setPassword', this.signupPassword);
            let formFields = {
                username: this.signupEmail.trim(), // Required - the username to sign up
                password: this.getAdditionalInfo.password, // Required - the user's password
                attributes: {
                    name: this.signupName.trim(),
                    email: this.signupEmail.trim(),
                    ...(this.signupCompany && {
                        'custom:company': this.signupCompany.trim()
                    }),
                    ...(this.referralSource && {
                        'custom:referralSource': this.referralSource.trim()
                    }),
                    ...(this.signupRole && {
                        'custom:customer_type': this.signupRole,
                        'custom:customer_type_int': this.roleItems
                            .indexOf(this.signupRole)
                            .toString()
                    }),
                    ...(this.mcNumber !== null && {
                        'custom:mc_number': this.mcNumber
                    }),
                    ...{
                        'custom:priorSignIn': '1'
                    }
                }
            };

            if (this.redirectGoogleSSO) {
                const customAttributes = {};
                for (const key in formFields.attributes) {
                    if (key.startsWith('custom:')) {
                        customAttributes[key] = formFields.attributes[key];
                    }
                }

                const user = await Auth.currentAuthenticatedUser();

                Auth.updateUserAttributes(user, customAttributes).then(() => {
                    let event = {
                        ...formFields,
                        user_status:'EXTERNAL_PROVIDER',
                        identity_provider:'GOOGLE',
                    }
                    if('url' in this.$store.state){
                        event.url = this.$store.state['url']
                    }
                    this.$emit('sendMessageToQueue',event)
                    // Once SSO user signup is confirmed, send conversion event
                    const sourcePage = ('sourcePage' in this.$store.state) ? this.$store.state['sourcePage'] : null;
                    if (sourcePage != null) {
                        analytics.sendEvent('sso_signup_' + sourcePage);
                    }
                    // const storedUrl = ('url' in this.$store.state) ? this.$store.state['url'] : null;
                    // if (storedUrl && storedUrl.includes('about')) {
                    //     this.$gtag.event('adpageSignup', {
                    //         send_to: 'AW-16494424913/kAvvCLaSrbgZENHmk7k9',
                    //         value: 1.0,
                    //         currency: 'USD'
                    //     });
                    // }
                    analytics.sendEvent(
                        'sso_signup_verified',
                        user.attributes.sub,
                        user.attributes.name,
                        user.attributes.email
                    );
                    this.$gtag.event('sign_up_confirmed', {
                        send_to: 'AW-16494424913/kAvvCLaSrbgZENHmk7k9',
                        event_category: 'engagement',
                        event_label: 'new_user_confirmed',
                        value: '1'
                    });
                    this.$router.replace({
                        name: 'chat'
                    });
                });
                return;
            }
            try {
                await auth.signUp(formFields).then(() => {
                    this.sendMsgToQueue(formFields)
                    analytics.sendEvent(
                        'signup',
                        'NA',
                        formFields.attributes.name,
                        formFields.attributes.email
                    );
                });
                this.$emit('showLoading', false);
                // this.$emit('setAdditionalInfo',false);
                // analytics.sendEvent('confirmation_form_view');
                this.$emit('showConfirm', true);
            } catch (error) {
                this.$emit('showLoading', false);
                if(error.message == 'An account with the given email already exists.'){
                    this.$emit('setAdditionalInfo',false,error)
                }
                this.showDialog(
                    `${this.signupEmail}: ${error.message}`,
                    'We encountered an issue.',
                    '#37474F'
                );
            }
        },
        showDialog(message, title = 'Notice', color = 'white') {
            this.dialogMessage = message;
            this.dialogTitle = title;
            this.dialogColor = color;
            this.dialog = true;
        },
        closeDialog() {
            this.dialogMessage = null;
            this.dialogTitle = null;
            this.dialogColor = null;
            this.dialog = false;
        },
        handleRoleClick(item) {
            if (item === this.signupRole) {
                this.signupRole = '';
            } else {
                this.signupRole = item;
            }

            if (item !== 'Carrier') {
                this.mcNumber = null;
            }
            this.triggerValidation();
        },

        redirectToSignIn() {
            this.$router.push({
                name: 'login'
            });
        }
    },

    computed: {
        isFormPopulated() {
            // If Google sign up, can already continue
            if (this.redirectGoogleSSO || this.getAdditionalInfo.state) {
                return true;
            }

            // If regular sign up, email, name, password, confirm password
            if (
                this.signupEmail != '' &&
                this.signupPassword != '' &&
                this.signupName != '' &&
                this.confirmPassword != ''
            ) {
                return true;
            }

            return false;
        }
    },

    created() {},

    mounted() {
        this.isVisible = true;

        if (this.redirectGoogleSSO) {
            analytics.sendEvent('sso_add_info_view');
        } else {
            analytics.sendEvent('add_info_view');
        }
        if ('email' in this.$store.state && this.redirectGoogleSSO) {
            this.signupEmail = this.$store.state['email'];
        }

        if ('name' in this.$store.state && this.redirectGoogleSSO) {
            this.signupName = this.$store.state['name'];
        }
        if(this.getAdditionalInfo.email){
            this.signupEmail = this.getAdditionalInfo.email
        }
        if(this.getAdditionalInfo.name){
            this.signupName = this.getAdditionalInfo.name
        }
        this.$nextTick(() => {
            this.$refs.companyField.focus();
        });
    },

    watch: {
        showConfirm(val) {
            if (val === true) {
                console.log(val);
            }
        }
    },

    data: function () {
        return {
            // Signup form fields
            formValid: false,
            signupName: '',
            signupEmail: '',
            signupPassword: '',
            confirmPassword: '',
            signupCompany: '',
            signupRole: '',
            signupEnabled: false,
            roleItems: ['Shipper', 'Broker', 'Carrier', 'Other'],
            showPwd: false,
            referralSource: '',
            hadFocus: {
                name: false,
                email: false,
                pwd: false,
                conf: false,
                referralSource:false
            },
            dialog: false,
            dialogMessage: '',
            dialogTitle: '',
            dialogColor: 'white',
            rules: {
                required: (v) => {
                    return !!v || 'This field cannot be empty';
                },
                min: (v) => v.length >= 8 || 'Min 8 characters',
                max: (v) => v.length <= 20 || 'Max 20 characters',
                pwdMatch: () =>
                    this.confirmPassword === '' ||
                    this.signupPassword === this.confirmPassword ||
                    `The passwords you entered don't match`,
                email: (v) => {
                    // Validates that the input string follows the email format: local part with
                    // alphanumeric and special characters, an '@' symbol, and a valid domain.
                    const regex = /^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
                    if (regex.test(v)) {
                        return true;
                    } else {
                        return 'E-mail is not valid';
                    }
                },
                sixDigits: (v) =>
                    (v !== null &&
                        v !== undefined &&
                        v.toString().length === 6) ||
                    'Must be 6 characters long',
                carrierSixDigits: (v) => {
                    if (this.signupRole === 'Carrier') {
                        return (
                            (v !== null &&
                                v !== undefined &&
                                v.toString().length === 6) ||
                            'Must be 6 characters long'
                        );
                    }
                    return true; // if role is not 'Carrier', validation passes
                },
                carrierRequired: (v) => {
                    if (this.signupRole === 'Carrier') {
                        return !!v || 'This field is required for Carriers';
                    }
                    return true; // if role is not 'Carrier', validation passes
                }
            },

            confirmError: '',
            contactEmail: 'contact@truce.io',
            mcNumber: null,
            isVisible: false
        };
    }
};
</script>
<style scoped>
.roleSelect .v-list {
    background: rgb(32, 31, 31) !important;
}
.roleSelect .v-list-item__title {
    color: rgb(240, 240, 240) !important;
}

.no-hover-effect {
    background: transparent !important;
}
.no-hover-effect:hover {
    background: transparent !important;
}

.text-field-container {
    transform: translateX(60%);
    transition: transform 0.3s ease-in-out;
}

.text-field-container.is-visible {
    transform: translateX(0);
}
</style>
