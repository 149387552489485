<template>
    <div>
        <v-navigation-drawer
            app
            clipped
            floating
            ref="signupDrawer"
            :disable-resize-watcher="true"
            :mobile-breakpoint="1000"
            :overlay-opacity="1"
            overlay-color="rgba(0,0,0,.75)"
            class="pt-4"
            mini-variant-width="0px"
            color="#151619"
            style="color: #ffffff !important; overflow: visible; z-index: 10"
            stateless
            right
            v-model="showDrawer"
            :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
            temporary>
            <button class="hover-button" @click="closeDrawer">
                <div
                    style="
                        position: absolute;
                        top: 4px;
                        color: white;
                        font-family: 'Times New Roman', Times, serif;
                        font-size: 32px !important;
                        line-height: 24px;
                        font-weight: 400;
                        opacity: 0.75;
                    "
                    :style="
                        $vuetify.breakpoint.mobile
                            ? { left: '8px' }
                            : { left: '-26px' }
                    "
                    class="tf-v1-close tf-v1-close-icon">
                    ×
                </div>
            </button>
            <div
                v-if="showLogin"
                style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                "
                key="1">
                <div
                    style="width: 60%; justify-content: space-evenly"
                    class="d-flex flex-column px-3">
                    <div style="font-size: 35px; color: white">Sign In</div>
                    <EmailField
                        :initial_email="email"
                        :errors="emailErrors"
                        v-if="content_type == 'email'"
                        @emailUpdate="handleEmailEmission"
                        @toPassword="handleNextEmission"
                        class="flex-grow-0 mt-4" />
                    <transition :name="transition_password">
                        <PasswordField
                            id="passwordField"
                            v-if="content_type == 'password'"
                            :current_email="email"
                            :errors="loginErrors"
                            :showForgetPassword="true"
                            @passwordUpdate="handlePasswordEmission"
                            @backUpdate="handleBackEmission"
                            @triggerLogin="handleNextEmission"
                            @forgetPassword="handleForgetPasswordEmission" />
                    </transition>
                    <v-btn
                        color="#348EFF"
                        dark
                        class="text-capitalize"
                        style="width: 100%"
                        @click="verify_and_progress">
                        <div v-if="content_type == 'email'">Continue</div>
                        <div v-if="content_type == 'password'">Sign In</div>
                    </v-btn>
                    <div
                        class="text-center d-flex align-center justify-center pt-4 pb-2 w-100">
                        <hr
                            class="flex-grow-1 my-auto"
                            style="border-color: #545454" />
                        <span class="mx-2" style="color: #545454">or</span>
                        <hr
                            class="flex-grow-1 my-auto"
                            style="border-color: #545454" />
                    </div>
                    <div class="py-3">
                        <GoogleSSOButton @showLoading="showLoading" />
                    </div>
                </div>
                <div
                    style="width: 100%"
                    class="text-center d-flex align-center justify-center">
                    <span class="pl-1 link">
                        Don't have an account?
                        <a @click="openSignUpForm">Sign Up</a>
                    </span>
                </div>
            </div>
            <div
                v-else-if="showResetPassword"
                style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                "
                key="3">
                <div
                    style="width: 60%; justify-content: space-evenly"
                    class="d-flex flex-column px-3">
                    <ResetPassword
                        @resetPasswordComplete="handleEmitResetPasswordComplete"
                        @showLoading="showLoading"
                        @snackbar="handleEmittedSnack"></ResetPassword>
                </div>
                <div></div>
            </div>
            <div
                v-else
                style="
                    max-height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                "
                key="2">
                <div style="width: 60%">
                    <SignUp
                        @ready="setRefInstance"
                        @showLoading="showLoading" />
                </div>
                <div style="width: 100%" class="text-center px-10 pt-2 pb-4">
                    <span class="mx-2 link"
                        >Already have an account?
                        <a @click="showLogin = true">Log In</a>
                    </span>
                </div>
            </div>
        </v-navigation-drawer>

        <v-app-bar
            app
            dense
            dark
            fixed
            elevation="0"
            style="height: fit-content; background-color: transparent;"
            :style="$vuetify.breakpoint.mobile ? { backgroundColor: 'rgba(7, 9, 14, 0.8)' } : {}"
            class="font-weight-medium py-4 px-2 px-xl-12 px-lg-12 px-md-12 px-sm-4">
            <v-row align="center" justify="space-between" style="flex-wrap: nowrap;">
                <div
                    class="logo-text-wrapper d-flex align-center"
                    @click="navigatePage('landing')">
                    <LogoSvgComponent
                        style="flex-shrink: 0;"
                        class="mr-3 mt-1 logo-pointer"
                        width="1.8rem"
                        height="1.8rem"></LogoSvgComponent>
                    <span
                        class="logo-text logo-pointer"
                        style="font-size: 25px"
                        >FreightGPT</span
                    >
                </div>
                <div class="login-button-wrapper mr-4 mt-2" v-if="show_login_button">
                    <v-btn
                        class="text-capitalize login-button"
                        text
                        @click.stop="
                            showDrawer = !showDrawer;
                            showLogin = true;
                            showResetPassword = false;
                        "
                        >Login</v-btn
                    >
                </div>
                <SignUpButton v-else/>
            </v-row>
        </v-app-bar>
        <v-snackbar v-model="snackbar" :color="snackColor" top timeout="2500">
            <div class="d-flex justify-space-between align-center pa-0 mr-n2">
                {{ snackText }}
                <v-btn icon dark @click="snackbar = false">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
        <v-overlay
            v-model="isLoading"
            class="loadingOverlay"
            style="z-index: 11">
            <v-progress-circular indeterminate />
        </v-overlay>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import LogoSvgComponent from '../components/LogoSVG.vue';
import EmailField from '../authentication/components/EmailField.vue';
import PasswordField from '../authentication/components/PasswordField.vue';
import SignUp from '../components/SignUp.vue';
import * as auth from '../authentication/amplify-auth';
import * as analytics from '../analytics/pinpoint';
import { UserInterface } from '../types/userObject';
import ResetPassword from './ResetPassword.vue';
import GoogleSSOButton from '../containers/landing/GoogleSSOButton.vue';
import SignUpButton from '../components/SignUpButton.vue';

export default Vue.extend({
    name: 'AppBar',
    components: {
        LogoSvgComponent,
        EmailField,
        PasswordField,
        SignUp,
        ResetPassword,
        GoogleSSOButton,
        SignUpButton
    },
    props: {
        show_login_button: {
            type: Boolean,
            default: true
        }
    },
    beforeMount() {
        auth.currentSSOSignIn("Google").then((response) => {
            if (response != null) {
                this.googleSessionActive = true;
                if (response != null) {
                    this.currentSSOEmail = response;
                }
            }
        })
        if (this.$route.name === 'login') {
            this.showDrawer = true;
        }

        if (this.$route.name === 'signup') {
            this.showLogin = false;
            this.showDrawer = true;
        }
    },
    watch: {
        showDrawer(newVal) {
            if (newVal === true) {
                setTimeout(() => {
                    this.enableDrawerCloseDirective = true;
                }, 100);
            } else {
                setTimeout(() => {
                    this.enableDrawerCloseDirective = false;
                }, 100);
            }
        }
    },
    created() {
        // Add script for typeform when component created
        let script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        document.body.appendChild(script);
    },
    methods: {
        navigatePage(pageName: string) {
            // Keeping empty catch to avoid redundant navigation error
            this.$router
                .push({
                    name: pageName
                })
                .catch(() => {});
        },

        setRefInstance(component: any) {
            this.signUpFormInstance = component;
        },

        handleForgetPasswordEmission() {
            this.showLogin = false;
            this.showResetPassword = true;
        },

        handleEmitResetPasswordComplete() {
            this.showResetPassword = false;
            this.showLogin = true;
        },

        handleEmittedSnack(
            snackbar: boolean,
            snackText: string,
            snackColor: string
        ) {
            this.snackbar = snackbar;
            this.snackText = snackText;
            this.snackColor = snackColor;
        },

        closeDrawer() {
            this.showDrawer = false;
        },

        openWaitlistForm() {
            const waitlistButton = this.$refs.waitlistBtn as any;
            waitlistButton.$el.click();
            // this.closeDrawer();
        },

        waitlistFormOpened() {
            this.closeDrawer();
            setTimeout(() => {
                let overlay = document.querySelector('.tf-v1-slider');
                if (overlay) {
                    overlay.addEventListener('click', () => {
                        overlay?.remove();
                    });
                }
            }, 200);
        },

        handleEmailEmission(email: string) {
            this.email = email;
            this.emailErrors = [];
        },

        handleBackEmission() {
            this.transition_password = '';
            this.content_type = 'email';
        },

        handlePasswordEmission(password: string) {
            this.password = password;
            this.loginErrors = [];
        },

        handleNextEmission() {
            this.verify_and_progress();
        },

        navigate(user: UserInterface) {
            analytics.sendEvent('login', user.sub, user.name, user.email);

            // Navigate to chat
            this.$router.push({
                name: 'chat',
                user_info: user
            } as any);
        },

        async verify_and_progress() {
            let user: any;
            if (this.content_type == 'email') {
                if (!this.validate_emails()) {
                    return;
                }

                if (this.googleSessionActive && this.currentSSOEmail === this.email){
                    console.log(`Active google session for email ${this.currentSSOEmail} - clearing local storage.`)
                    auth.clearCognitoLocalStorage();
                    this.googleSessionActive = false;
                    this.currentSSOEmail = '';
                }

                user = await auth.currentAuthenticatedUser(this.email);
                if (user) {
                    this.navigate(user.attributes);
                } else {
                    console.log('SignIn failed or error occurred.');
                }
                this.content_type = 'password';
                this.loginErrors = [];
            } else if (this.content_type == 'password') {
                this.isLoading = true;
                try {
                    user = await auth.signIn(this.email, this.password);
                    this.navigate(user.attributes);
                } catch (error: any) {
                    if (error.code === 'UserNotConfirmedException') {
                        auth.resendSignUp(this.email);
                        this.signUpFormInstance.showConfirm = true;
                    } else {
                        console.log('SignIn failed or error occurred.');
                        this.loginFailed();
                    }
                }
            }
        },

        loginFailed() {
            this.isLoading = false;
            this.loginErrors.push('Username or password is incorrect');
        },

        validate_emails() {
            const regex = /^\w+([.+_-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            let result =
                !this.email || regex.test(this.email) || 'E-mail must be valid';

            if (result == 'E-mail must be valid') {
                this.emailErrors.push(result);
                return false;
            }
            if (this.email == '') {
                this.emailErrors.push('E-mail field cannot be empty');
                return false;
            }

            return result;
        },

        showLoading(val: boolean) {
            this.isLoading = val;
        },

        openSignUpForm() {
            this.$router.push({
                name: 'signup',
                params: { signUpInitialRedirection: 'true' }
            });
        }
    },
    data: function () {
        return {
            showDrawer: false,
            showLogin: true,
            showResetPassword: false,
            isLoading: false,
            signUpFormInstance: null as any,
            snackbar: false,
            snackText: '',
            snackColor: '',
            email: '',
            password: '',
            transition_password: 'slide-right',
            transition_email: '',
            year: new Date().getFullYear(),
            isDev: false,
            enableDrawerCloseDirective: false,
            content_type: 'email',
            loginErrors: [] as string[],
            emailErrors: [] as string[],
            googleSessionActive: false,
            currentSSOEmail: ''
        };
    }
});
</script>
<style scoped>
.logo-text-wrapper {
    padding-left: 0.5%;
    display: flex;
    align-items: center;
}

/* Login and Signup form transition animation */
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all 0.4s ease-in-out !important;
}
.slide-left-enter {
    transform: translateX(100%) !important;
}
.slide-left-leave-to {
    transform: translateX(-100%) !important;
}

/* For mobile devices */
@media screen and (max-width: 500px) {
    .logo-text {
        font-size: 28px !important;
    }

    .buttons {
        margin-left: 12px !important;
    }
}

.link {
    font-size: 15px;
    color: grey;
}

.v-text-field--outlined.v-input--is-focused fieldset {
    border-width: 0.8px !important;
}

.hover-button div {
    cursor: pointer;
    transition: opacity 0.3s ease;
}
.hover-button:hover div {
    opacity: 1 !important;
}

.slide-right-enter-active {
    transition: transform 0.3s;
}
.slide-right-enter {
    transform: translateX(100%);
}

.markdown-content {
    max-height: 80%;
    overflow-y: auto;
    color: white !important;
}

.login-button-wrapper {
    max-width: fit-content;
    position: relative;
    background: #0366f9;
    border-radius: 7%;
}
.logo-pointer {
    cursor: pointer;
}
.login-button {
    background: #07090e;
    color: whitesmoke;
    margin: 1.5px;
}
</style>
