import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

export function googleSignIn() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
}

export function hostedSignIn() {
    Auth.federatedSignIn();
}
